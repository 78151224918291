/* eslint-disable jsx-a11y/no-autofocus */
import { useContext, useEffect, useRef, useState } from 'react';
import { SvgSelector } from '../../../../helper/svg-selector';
import AnimationSlide from '../../../Elements/AnimationSlide';
import Calendar from '../../../Calendar';
import MembersMenu from '../../../Elements/MenuMembers';
import Avatar from '../../../Elements/Avatar';
import { DataContext } from '../../../../context/DataContext';
import { deadlineCal } from '../../../../helper/functions';
import TodoMoreMenu from './TodoMoreMenu';

let timer;
function TodoCheckListItem({ item, focusedNode, addNewNode, order }) {
  const { treeData, addToQueue, unAssignUserToTask, userAccess } =
    useContext(DataContext);
  const fullAccess = userAccess === 'WRITE';
  const inputRef = useRef();
  const todoItem = treeData[item];
  const [openMenu, setOpenMenu] = useState();
  const [info, setInfo] = useState([]);
  const [value, setValue] = useState(todoItem?.title[0]?.text);
  const [checked, setChecked] = useState(todoItem.status === 'DONE');
  const [assignments, setAssignments] = useState(todoItem?.assignments || []);
  const parentPercent = 0;

  const openMenuHandler = (user) => {
    setInfo(user);
    setOpenMenu('memberMenu');
  };

  const checkHandler = () => {
    updateServer({ status: checked ? 'NO_STATUS' : 'DONE' });
    treeData[item].status = checked ? 'NO_STATUS' : 'DONE';
    setChecked(!checked);
  };
  const deleteMember = (member) => {
    let tAssignments = assignments;
    tAssignments = tAssignments.filter((u) => u.id !== member.id);
    setAssignments(tAssignments);
    unAssignUserToTask(todoItem, member);
    setOpenMenu(null);
  };

  const updateServer = (params) => {
    treeData[todoItem.id] = { ...todoItem, ...params };
    addToQueue('upsert', {
      id: todoItem.id,
      ...params,
    });
  };

  const addNodeHandler = (val, odr) => {
    updateServer({ title: [{ text: val, type: 'text', style: {} }] });
    addNewNode(odr);
  };
  const debounceAction = (val) => {
    setValue(val);
    clearTimeout(timer);
    timer = setTimeout(
      () => updateServer({ title: [{ text: val, type: 'text', style: {} }] }),
      500,
    );
  };

  useEffect(() => {
    if (focusedNode === todoItem.id) inputRef?.current.focus();
    setValue(todoItem?.title[0]?.text || '');
    setAssignments(todoItem?.assignments || []);
    setChecked(todoItem.status === 'DONE');
  }, [focusedNode, item]);

  return (
    <div className="check-list-container">
      <div className="check-list-card-container" style={{ width: '100%' }}>
        <div className="check-list-card-name">
          <div
            onClick={checkHandler}
            contentEditable={false}
            className={`check-list-card-check cursor-pointer
          ${checked ? 'check-list-card-selected' : ''}
          ${checked && parentPercent === 100 ? 'check-list-done' : ''}`}
          >
            {checked &&
              SvgSelector('checkMark', 'check-list-card-selected-icon')}
          </div>
          <input
            ref={inputRef}
            type="text"
            disabled={!fullAccess}
            value={value}
            onChange={(e) => debounceAction(e.target.value)}
            onKeyDown={(e) =>
              e.code === 'Enter' ? addNodeHandler(e.target.value, order) : null
            }
            style={{
              backgroundColor: 'transparent',
              textDecorationLine: checked ? 'line-through' : 'none',
            }}
            className="font-18-reg nodes-list-task-name"
          />
        </div>
        <div
          className="check-list-card-date cursor-pointer"
          onClick={() => setOpenMenu('calendar2')}
        >
          <AnimationSlide
            show={openMenu === 'memberMenu'}
            openMenu={setOpenMenu}
            state="topToDown"
          >
            <div className="check-list-todo-member-menu">
              <MembersMenu info={info} deleteMember={deleteMember} />
            </div>
          </AnimationSlide>
          <AnimationSlide
            show={openMenu === 'calendar2'}
            state="topToDown"
            openMenu={setOpenMenu}
          >
            <div className="check-list-title-calendar">
              <Calendar node={item} />
            </div>
          </AnimationSlide>
          {todoItem?.due_time && (
            <div
              className="check-list-title-date cursor-pointer"
              onClick={() => setOpenMenu('calendar1')}
            >
              <AnimationSlide
                show={openMenu === 'calendar1'}
                state="topToDown"
                openMenu={setOpenMenu}
              >
                <div className="check-list-title-calendar">
                  <Calendar toggle={() => openMenu(-1)} node={item.id} />
                </div>
              </AnimationSlide>
              <span className="font-14-med check-list-title-date-text">
                {deadlineCal(todoItem.due_time)[0]}
              </span>
              <span className="font-14-med check-list-title-date-text">
                {/* {deadlineCal(thisBlock.due_time)[0]} */}
              </span>
              {SvgSelector('chevronDown', 'check-list-title-date-icon')}
            </div>
          )}
        </div>
        <div className="check-box-card-feature">
          <div className="check-list-title-member-container cursor-pointer">
            {assignments?.slice(0, 3).map((user) => (
              <div onClick={() => openMenuHandler(user)}>
                <Avatar size={23} name={user.email} />
              </div>
            ))}
            {assignments?.length > 3 && (
              <div className="check-list-title-member-more cursor-pointer font-12-med">
                +{assignments.length - 3}
              </div>
            )}
          </div>
        </div>
        <div
          onClick={() => setOpenMenu('moreMenu2')}
          className="cursor-pointer check-list-card-more hover"
        >
          {SvgSelector('more', 'check-list-card-more-icon')}
          <AnimationSlide
            openMenu={setOpenMenu}
            show={openMenu === 'moreMenu2'}
            state="topToDown"
          >
            <div className="check-list-card-more-menu">
              <TodoMoreMenu block={todoItem} />
            </div>
          </AnimationSlide>
        </div>
      </div>
    </div>
  );
}

export default TodoCheckListItem;
