// import { avatarColors } from '../../../constants/arrays';
import { useContext, useState } from 'react';
import { SvgSelector } from '../../../../helper/svg-selector';
import CheckListMoreMenu from './CheckListMoreMenu';
import AnimationSlide from '../../../Elements/AnimationSlide';
import Calendar from '../../../Calendar';
import MembersMenu from '../../../Elements/MenuMembers';
import Avatar from '../../../Elements/Avatar';
import { DataContext } from '../../../../context/DataContext';
import { deadlineCal } from '../../../../helper/functions';

function TitleCardCheckList({ props, children }) {
  const { treeData } = useContext(DataContext);
  const [openMenu, setOpenMenu] = useState(null);
  const [assignments, setAssignments] = useState(
    treeData[props.block.id]?.assignments || [],
  );
  const [info, setInfo] = useState([]);
  const { unAssignUserToTask } = useContext(DataContext);

  const percent = () => {
    const allChildren = treeData[props.block.id]?.order?.length;
    let counter = 0;
    treeData[props.block.id]?.order?.forEach((i) => {
      if (treeData[i].status === 'DONE') counter += 1;
    });
    return (counter / allChildren) * 100;
  };
  const checkAll = () => {
    // const tTreeData = treeData;
    // tTreeData[props.block.id]?.order?.forEach((i) => {
    //   tTreeData[i].status = 'DONE';
    //   console.log('check', tTreeData[i].status);
    //   addToQueue('upsert', { id: i, status: 'DONE' });
    // });
    // dispatch({ type: 'setTree', payload: tTreeData });
  };
  const thisBlock = treeData[props.block.id];
  const deleteMember = (member) => {
    let tAssignments = assignments;
    tAssignments = tAssignments.filter((u) => u.id !== member.id);
    setAssignments(tAssignments);
    unAssignUserToTask(props.block, member);
    setOpenMenu(null);
  };

  const openMenuHandler = (user) => {
    setInfo(user);
    setOpenMenu('memberMenu');
  };

  if (!thisBlock) return null;
  return (
    <div className="check-list-title-wrapper">
      <div className="check-list-title-container">
        <div className="check-list-title-name-container">
          <div
            className="cursor-pointer check-list-title-name"
            contentEditable={false}
            onClick={checkAll}
          >
            {SvgSelector('brokenCheck')}
          </div>
          {children}
        </div>
        {thisBlock.due_time && (
          <div
            className="check-list-title-date-container cursor-pointer"
            onClick={() => setOpenMenu('calendar1')}
          >
            <AnimationSlide
              show={openMenu === 'calendar1'}
              state="topToDown"
              openMenu={setOpenMenu}
            >
              <div className="check-list-title-calendar">
                <Calendar toggle={() => openMenu(-1)} node={props.block.id} />
              </div>
            </AnimationSlide>
            <span className="font-14-med check-list-title-date-text">
              {deadlineCal(thisBlock.due_time)[0]}
            </span>
            {SvgSelector('chevronDown', 'check-list-title-date-icon')}
          </div>
        )}
        <div className="check-list-title-member-container cursor-pointer">
          {assignments?.slice(0, 3).map((user) => (
            <div onClick={() => openMenuHandler(user)}>
              <Avatar size={23} name={user.email} />
            </div>
          ))}
          {assignments?.length > 3 && (
            <div className="check-list-title-member-more cursor-pointer font-12-med">
              +{assignments.length - 3}
            </div>
          )}
        </div>
        <div className="check-box-title-feature" contentEditable={false}>
          <div
            onClick={() => setOpenMenu('moreMenu1')}
            className="cursor-pointer check-list-title-more hover"
          >
            {SvgSelector('more', 'check-list-title-more-icon')}
            <AnimationSlide
              show={openMenu === 'memberMenu'}
              openMenu={setOpenMenu}
              state="topToDown"
            >
              <div className="check-list-title-member-menu">
                <MembersMenu
                  // openModal={showSharingMenu}
                  // moveTo={moveToProject}
                  info={info}
                  deleteMember={deleteMember}
                />
              </div>
            </AnimationSlide>
            <AnimationSlide
              openMenu={setOpenMenu}
              show={openMenu === 'moreMenu1'}
              state="topToDown"
            >
              <div className="check-list-title-more-menu">
                <CheckListMoreMenu
                  assignments={assignments}
                  deleteMember={deleteMember}
                  setAssignments={setAssignments}
                  block={thisBlock}
                  props={props}
                />
              </div>
            </AnimationSlide>
          </div>
        </div>
      </div>
      <div className="check-list-progress-container">
        <div
          className="check-list-display-progress"
          style={{
            backgroundColor:
              percent() === 100 ? 'var(--green)' : 'var(--blue_strong)',
            width: `${percent()}%`,
          }}
        />
      </div>
    </div>
  );
}

export default TitleCardCheckList;
