import { useEffect, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import Button from './Button';
import TextInput from './TextInput';
import Avatar from './Avatar';
import AnimationSlide from './AnimationSlide';
import dataServices from '../../services/dataServices';

function InviteModal({
  openModal,
  notify,
  layerToShare,
  sendLink,
  // RefreshLayer,
  shareBoard,
}) {
  const [email, setEmail] = useState();
  const [isLoading, setLoading] = useState(false);
  const [opened, open] = useState(false);
  const [privilege, setPrivilege] = useState('READ');
  const [info, setInfo] = useState();

  const onConfirm = () => {
    if (isLoading || (!info && !email)) return;
    setLoading(true);
    if (sendLink) {
      dataServices.sendEmail(layerToShare, { email: info.email }).then(() => {
        notify({
          isLoading: false,
          text: 'Invitation Sent',
          actionText: 'Okay',
          action: null,
        });
        openModal(false);
        setLoading(false);
      });
    } else {
      const data = {
        email: info.email,
        privilege,
      };
      if (shareBoard) {
        shareBoard({ setLoading, data, info });
        return;
      }
      dataServices.inviteMember(layerToShare, data).then(() => {
        setLoading(false);
        openModal(false);
        notify({
          isLoading: false,
          text: 'Invitation sent',
          actionText: 'Okay',
          action: null,
        });
        // setTimeout(() => RefreshLayer(), 700);
      });
    }
  };
  useEffect(() => {
    if (!email?.length > 0) return;

    dataServices
      .getLayerUsers({
        email__icontains: email?.toLowerCase(),
      })
      .then((res) => {
        if (!res?.errors?.length > 0) {
          setInfo(res.data[0]);
        }
      });
  }, [email]);

  return (
    <div
      className="modal-container"
      aria-hidden="true"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          openModal(false);
        }
      }}
    >
      <div className="modal-wrapper">
        <div className="modal-header">
          <div className="font-16-bold">Send invite</div>
          <button
            type="button"
            className="cursor-pointer center"
            onClick={() => openModal(false)}
          >
            {SvgSelector('close')}
          </button>
        </div>
        <div className="font-14-reg modal-text">
          We’ll send an email to your new address with instructions on
          completing the change.
        </div>

        <div className="modal-invite-search">
          <TextInput
            title="Invite to"
            value={email}
            input="email"
            placeholder="Enter an email"
            handleChange={(value) => setEmail(value)}
          />
        </div>
        {email?.length > 0 && (
          <div className="modal-email-suggestion">
            <button
              type="button"
              className="flex-row"
              onClick={() => (info?.email ? setEmail(info?.email) : null)}
            >
              <Avatar size={40} name={info?.email || ''} />
              <div className="modal-invite-member-name">
                <div className="font-14-bold modal-invite-email">
                  {info?.email || email}
                </div>
                <div className="font-small-reg modal-invite-username">
                  {`${info?.first_name || ''} ${info?.last_name || ''}`}
                </div>
              </div>
            </button>
            <div className="modal-invite-access">
              <div
                className="flex-row cursor-pointer"
                onClick={() => open((old) => !old)}
              >
                <div className="font-14-reg modal-invite-access-text">
                  {privilege === 'READ' ? 'Can view' : 'Can edit'}
                </div>
                {!sendLink && SvgSelector('chevronDown', 'chevron-modal')}
              </div>

              {!sendLink && (
                <AnimationSlide show={opened} openMenu={open} state="topToDown">
                  <div className="modal-dropdown">
                    <button
                      type="button"
                      onClick={() => {
                        open(false);
                        setPrivilege('READ');
                      }}
                      className="flex-row cursor-pointer modal-invite-access-view"
                    >
                      {SvgSelector('eye', 'svg-modal')}
                      <div className="font-14-reg"> Can view</div>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        open(false);
                        setPrivilege('WRITE');
                      }}
                      className="flex-row cursor-pointer modal-invite-access-edit"
                    >
                      {SvgSelector('pencil', 'svg-modal')}
                      <div className="font-14-reg"> Can edit</div>
                    </button>
                  </div>
                </AnimationSlide>
              )}
            </div>
          </div>
        )}
        <div className="modal-buttons">
          <Button
            labelClass="font-16-bold"
            label="Cancel"
            textColor="black"
            color="gray_06"
            width="47%"
            onClick={() => openModal(false)}
            height={36}
          />
          <Button
            labelClass="font-16-bold"
            label="Send"
            textColor="white"
            color="blue_strong"
            isLoading={isLoading}
            width="47%"
            onClick={onConfirm}
            height={36}
          />
        </div>
      </div>
    </div>
  );
}
export default InviteModal;
