/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unstable-nested-components */
import { useContext, useEffect, useRef, useState } from 'react';
import '@blocknote/core/fonts/inter.css';
import {
  useCreateBlockNote,
  FormattingToolbarController,
  FormattingToolbar,
  SuggestionMenuController,
  SideMenuController,
  SideMenu,
  DragHandleMenu,
} from '@blocknote/react';
import { BlockNoteView } from '@blocknote/mantine';
import '@blocknote/mantine/style.css';
import '../../styles/blockStyle.css';
import { BlockNoteSchema, defaultBlockSpecs } from '@blocknote/core';

import TaskNoteCheckList from './noteElements/checkList/TaskNoteCheckList';
import useTaskNoteSync from '../../hooks/useTaskNoteSync';
import Toolbar from './noteElements/Toolbar';
import ToolbarEditor from './noteElements/ToolbarEditor';
import TaskNoteNode from './noteElements/TaskNoteNode';
import { SvgSelector } from '../../helper/svg-selector';
import { DataContext } from '../../context/DataContext';

let timer;
function TaskNote({ data, selectedNode, defaultId }) {
  const { userAccess } = useContext(DataContext);
  const fullAccess = userAccess === 'WRITE';
  const prevContent = useRef([]);
  const editorRef = useRef();
  const [changes, setChanges] = useState([]);
  const [initialContent, setInitialContent] = useState(data);

  const schema = BlockNoteSchema.create({
    blockSpecs: {
      ...defaultBlockSpecs,
      checkList: TaskNoteCheckList,
      node: TaskNoteNode,
    },
  });

  const editor = useCreateBlockNote({
    schema,
    initialContent,
  });

  const showToolbar = () => {
    return (
      editor.getSelection()?.blocks[0].type &&
      editor.getSelection()?.blocks[0].type !== 'node' &&
      editor.getSelection()?.blocks[0].type !== 'checkList'
    );
  };

  useEffect(() => {
    prevContent.current = JSON.parse(JSON.stringify(editor.document));
  }, []);

  useEffect(() => {
    setInitialContent(data);
  }, [data]);

  useTaskNoteSync(prevContent, editor, selectedNode, changes, defaultId);

  const debounceAction = (doc) => {
    clearTimeout(timer);
    timer = setTimeout(() => setChanges(doc), 500);
  };

  const makeNodeHandler = (props) => {
    editor.updateBlock(props.block, {
      type: 'node',
      props: { type: { title: props.block.content } },
    });
  };

  return (
    <div id="123" style={{ minHeight: 900 }}>
      <BlockNoteView
        data-theming-css-demo
        sideMenu={false}
        ref={editorRef}
        editable={fullAccess}
        onChange={() => debounceAction(editor.document)}
        editor={editor}
        theme="light"
        slashMenu={false}
        formattingToolbar={false}
      >
        <SideMenuController
          sideMenu={(props) => (
            <SideMenu
              {...props}
              dragHandleMenu={(thisProps) => (
                <DragHandleMenu {...thisProps}>
                  <div
                    {...thisProps}
                    onClick={() => editor.removeBlocks([thisProps.block])}
                    className="side-menu-item hover"
                  >
                    {SvgSelector('delete', 'side-menu-icon')}

                    <div className="font-14-reg">Delete</div>
                  </div>
                  {thisProps.block.type !== 'node' &&
                    thisProps.block.type !== 'checkList' && (
                      <div
                        {...thisProps}
                        onClick={() => makeNodeHandler(thisProps)}
                        className="side-menu-item hover"
                      >
                        {SvgSelector('node', 'side-menu-icon')}

                        <div className="font-14-reg">Make it a node</div>
                      </div>
                    )}
                </DragHandleMenu>
              )}
            />
          )}
        />
        <SuggestionMenuController
          triggerCharacter="/"
          suggestionMenuComponent={(props) =>
            ToolbarEditor({
              ...props,
            })
          }
        />
        <FormattingToolbarController
          formattingToolbar={() => {
            if (showToolbar())
              return (
                <FormattingToolbar>
                  <Toolbar />
                </FormattingToolbar>
              );
            return null;
          }}
        />
      </BlockNoteView>
    </div>
  );
}
export default TaskNote;
