import { floatMenuStatus } from '../../constants/arrays';
import '../../styles/status.scss';
import { colors } from '../../constants';
import { SvgSelector } from '../../helper/svg-selector';

function StatusMenu({ handleClickItem, status }) {
  return (
    <div>
      <div className="popup-menu-columns">
        <div className="font-14-med popup-menu-status-name">Select status</div>
        {Object.values(floatMenuStatus).map((i) => (
          <button
            type="button"
            key={i.id}
            className="status-items hover"
            onClick={() => handleClickItem(i)}
          >
            <div
              className="float-menu-openMenu-status"
              style={{
                backgroundColor: colors[i.color],
              }}
            >
              {SvgSelector(i.label)}
            </div>
            <div className="font-14-reg">{i.name}</div>
            <div className="float-menu-status-check">
              {status === i.value && SvgSelector('checkMark')}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

export default StatusMenu;
