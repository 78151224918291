import { useContext, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import { DataContext } from '../../context/DataContext';
import { layerProps } from '../../constants/objects';
import TaskBoardCard from '../Dashboard/TaskBoardCard';
import dataServices from '../../services/dataServices';
import TaskViewFilter from './TaskViewFilter';
import {
  filterAssignment,
  filterDeadLine,
  filterStatus,
  filterTag,
} from '../../helper/TaskViewFilterUtils';

export default function TaskView({
  nodes,
  mainNode,
  inputFocused,
  setInputFocus,
  showNotify,
  selectNode,
}) {
  return (
    <div>
      <TaskViewFilter />
      <div className="outLine-container">
        <TaskViewNodes
          nodes={nodes}
          mainNode={mainNode}
          inputFocused={inputFocused}
          setInputFocus={setInputFocus}
          showNotify={showNotify}
          selectNode={selectNode}
        />
      </div>
    </div>
  );
}
function TaskViewNodes({
  nodes,
  mainNode,
  inputFocused,
  setInputFocus,
  showNotify,
  selectNode,
  childCounter,
}) {
  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {nodes?.map((node) => {
          return (
            <Child
              key={node}
              node={node}
              mainNode={mainNode}
              inputFocused={inputFocused}
              setInputFocus={setInputFocus}
              showNotify={showNotify}
              selectNode={selectNode}
              childCounter={childCounter}
            />
          );
        })}
      </div>
    </DndProvider>
  );
}

function Child({
  node,
  mainNode,
  inputFocused,
  setInputFocus,
  showNotify,
  selectNode,
  childCounter,
}) {
  const { treeData, dispatch, setRefresh, addToQueue, userAccess, taskFilter } =
    useContext(DataContext);
  const [shownChildren, showChildren] = useState(true);

  const filterResult =
    filterStatus(taskFilter, treeData, node) ||
    filterTag(taskFilter, treeData, node) ||
    filterAssignment(taskFilter, treeData, node) ||
    filterDeadLine(taskFilter, treeData, node);

  const checkHasFilter =
    taskFilter.status.length !== 0 ||
    taskFilter.tag.length !== 0 ||
    Object.values(taskFilter.assignments).length !== 0 ||
    taskFilter.due_date;

  const reOrder = (src, des) => {
    let nodeParentId = treeData[treeData[des].parent].id;
    while (nodeParentId !== mainNode) {
      if (nodeParentId === src) {
        showNotify({
          isLoading: false,
          text: 'This operation is not allowed!',
          actionText: 'Okay',
          action: null,
        });
        return;
      }
      nodeParentId = treeData[treeData[nodeParentId].parent].id;
    }
    const tempData = treeData;
    const srcParent = tempData[tempData[src].parent];
    const desParent = tempData[tempData[des].parent];
    const idx = desParent.order.indexOf(node);
    srcParent.order = srcParent?.order?.filter((i) => i !== src) || [];
    desParent.order = [
      ...desParent.order.slice(0, idx + 1),
      src,
      ...desParent.order.slice(idx + 1),
    ];
    const firstPreviousId = desParent.order.indexOf(src);
    const previousId = desParent.order[firstPreviousId - 1];
    const prevId = firstPreviousId === 0 ? null : previousId;
    addToQueue('move', {
      id: src,
      parent: prevId ? null : desParent.id,
      previous: prevId,
    });
    tempData[src].parent = desParent.id;
    dispatch({ type: 'setTree', payload: tempData });
    setRefresh((old) => old + 1);
  };

  const dragRef = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop(droppedItem, monitor) {
      if (!dragRef.current) {
        return;
      }
      if (droppedItem.node === node) return;
      reOrder(droppedItem.node, node);
      monitor.isOver({ shallow: true });
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isDragging: !!monitor.isDragging,
    }),
  });
  const [, preview, drag] = useDrag({
    item: { node },
    type: 'card',
    canDrag: userAccess === 'WRITE' && !checkHasFilter,
  });
  drag(drop(dragRef));

  if (!treeData[node]) return null;
  return (
    <div ref={preview}>
      <Node
        node={node}
        isOver={isOver}
        mainNode={mainNode}
        inputFocused={inputFocused}
        setInputFocus={setInputFocus}
        showChildren={showChildren}
        shownChildren={shownChildren}
        selectNode={selectNode}
        dragRef={dragRef}
        childCounter={childCounter}
        filterResult={filterResult}
      />
      {shownChildren && treeData[node]?.order && (
        <TaskViewNodes
          nodes={treeData[node]?.order}
          mainNode={mainNode}
          inputFocused={inputFocused}
          setInputFocus={setInputFocus}
          showNotify={showNotify}
          selectNode={selectNode}
          childCounter={childCounter ? childCounter + 1 : 1}
        />
      )}
    </div>
  );
}
let arrayTreeData = [];
function Node({
  node,
  MainNode,
  shownChildren,
  showChildren,
  inputFocused,
  setInputFocus,
  selectNode,
  isOver,
  dragRef,
  childCounter,
  filterResult,
}) {
  const {
    treeData,
    userAccess,
    addToQueue,
    setCurrNode,
    dispatch,
    setRefresh,
    boardId,
  } = useContext(DataContext);
  const inputRef = useRef(null);
  arrayTreeData = [];
  const [focusedItem, focusItem] = useState(inputFocused);

  useEffect(() => {
    if (focusedItem) if (inputFocused === node) inputRef?.current?.focus();
  }, [focusItem]);

  const addChild = (event, sibling = false) => {
    let nodeColor = 'BLUE';
    if (sibling && treeData[node]?.parent === MainNode) nodeColor = 'PINK';
    const tempData = treeData;
    const nodeParent = tempData[node].parent;
    const newChildID = uuidv4();
    const idx = tempData[nodeParent]?.order?.indexOf(node);
    const newChild = {
      ...layerProps,
      id: newChildID,
      title: [
        {
          text: '',
        },
      ],
      layer_type: 'TASK',
      parent: sibling ? nodeParent : node,
      color: nodeColor,
      expansion: tempData[node].expansion,
      idx: sibling ? idx + 1 : tempData[node].order.length,
      board_id: boardId,
    };
    const parentOrder = tempData[nodeParent].order;
    if (sibling) parentOrder.splice(idx + 1, 0, newChildID);

    tempData[sibling ? nodeParent : node].order = tempData[
      sibling ? nodeParent : node
    ].order
      ? sibling
        ? parentOrder
        : [...tempData[node].order, newChildID]
      : [newChildID];

    tempData[newChildID] = newChild;
    if (sibling) tempData[newChildID].index = idx + 1;
    addToQueue('create', tempData[newChildID]);
    setCurrNode(newChildID);
    dispatch({ type: 'setTree', payload: tempData });
    setInputFocus(newChildID);
    setRefresh((old) => old + 1);
  };

  const delChild = () => {
    const tempData = treeData;
    const idx = treeData[node]?.order?.indexOf(node);
    const deletedNode = { ...treeData[node], idx };
    const nodeParent = tempData[node]?.parent;
    tempData[nodeParent].order = tempData[nodeParent].order.filter(
      (i) => i !== node,
    );
    delete tempData[node];
    addToQueue('delete', deletedNode);
    dispatch({ type: 'setTree', payload: tempData });
    setInputFocus(-1);
    setRefresh((old) => old + 1);
  };

  const onKeyDown = (e) => {
    if (userAccess !== 'WRITE') return;
    if (e.key === 'Tab') {
      e.preventDefault();
      addChild(e, false);
    }
  };

  Object.entries(treeData).map(([key, value]) => {
    arrayTreeData[key] = value;
    return null;
  });

  const updateTaskList = (taskId, params) => {
    treeData[taskId] = { ...treeData[taskId], ...params };
  };

  const changeStatus = (taskId, taskStatus) => {
    updateTaskList(taskId, { status: taskStatus });
    const statusParams = {
      id: taskId,
      status: taskStatus,
    };
    dataServices.updateLayers(taskId, statusParams);
  };

  const updateTask = (taskId, params) => {
    updateTaskList(taskId, params);
    dataServices.updateLayers(taskId, params);
  };

  const changeChildrenId = (newNode) => {
    const tempData = treeData;
    const layers = [{ ...newNode, parent_id: newNode.parent }];
    tempData[newNode.id] = newNode;
    const changeId = (n) => {
      const newN = n;
      if (n.order?.length > 0) {
        n.order?.map((i, num) => {
          const childNewID = uuidv4();

          const index = n?.order.indexOf(i);
          newN.order[index] = childNewID;
          tempData[childNewID] = JSON.parse(JSON.stringify(tempData[i]));
          tempData[childNewID].id = childNewID;
          tempData[childNewID].parent = n.id;

          tempData[childNewID].previous_id =
            index === 0 ? null : tempData[newNode.id].order[num - 1];
          tempData[childNewID].previous = tempData[newNode.id].previous_id;
          layers.push({
            ...tempData[childNewID],
            parent_id: tempData[childNewID].parent,
          });
          return changeId(tempData[childNewID]);
        });
      }
    };
    changeId(newNode);
    return { tempData, layers };
  };

  const duplicateNode = (from, to) => {
    let tempData = treeData;
    let layers = [];
    let copyNode = {};
    const newChildID = uuidv4();
    copyNode = JSON.parse(JSON.stringify(tempData[from]));
    copyNode.id = newChildID;
    copyNode.parent = to;
    copyNode.previous_id = from;
    copyNode.previous = from;
    ({ tempData, layers } = changeChildrenId(copyNode));
    const layersToSend = {
      pk: newChildID,
      layers,
    };
    tempData[to].order = tempData[to].order || [];
    const idx = tempData[to].order.indexOf(node);
    if (idx >= 0) tempData[to].order.splice(idx + 1, 0, newChildID);
    else tempData[to].order = [...tempData[to].order, newChildID];
    setCurrNode(newChildID);
    dispatch({ type: 'setTree', payload: tempData });
    addToQueue('copy', {
      node: from,
      newParent: to,
      data: layersToSend,
      idx: idx >= 0 ? idx + 1 : tempData[to].order.length - 1,
    });
    setInputFocus(newChildID);
  };

  const duplicate = () => {
    duplicateNode(node, treeData[node].parent);
  };

  return (
    <div className="task-board-container task-view-container">
      <div
        className={`flex-row outline-view-node ${
          isOver ? 'outline-view-node-is-hovered' : ''
        }`}
      >
        <TaskBoardCard
          treeData={arrayTreeData}
          type="treeTask"
          data={node}
          setOpenTaskChild={showChildren}
          openTaskChild={shownChildren}
          changeStatus={changeStatus}
          updateTaskList={updateTaskList}
          addChild={addChild}
          onKeyDown={onKeyDown}
          deleteTask={delChild}
          updateTask={updateTask}
          selectNode={selectNode}
          duplicateTask={duplicate}
          dragRef={dragRef}
          childCounter={childCounter}
          boardId={boardId}
          filterResult={filterResult}
        />
      </div>
    </div>
  );
}
