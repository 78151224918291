import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DataContext } from '../../../context/DataContext';
import NodesCard from './NodesCard';
import '../../../styles/nodesNote.scss';

export default function TaskNoteNodeMain({ props }) {
  const { treeData, addToQueue } = useContext(DataContext);
  const nodeOrder = treeData[props.block.id]?.order;
  const [focusedNode, setFocusedNode] = useState();
  const addNewNode = (order) => {
    const newUUID = uuidv4();
    const newData = {
      id: newUUID,
      assignments: [],
      layer_type: 'BLOCK',
      block_type: 'NODE',
      title: [],
      ...(nodeOrder.length && { previous: nodeOrder[order] }),
      parent: props.block.id,
      board_id: treeData[props.block.id].board_id,
    };
    setFocusedNode(newUUID);
    nodeOrder.splice(order + 1, 0, newUUID);
    treeData[newUUID] = newData;
    treeData[props.block.id].order = nodeOrder;
    // dispatch({ type: 'setTree', payload: treeData });
    addToQueue('upsert', newData);
  };

  return (
    <div className="nodes-list-container">
      <div className="font-h3">Nodes</div>
      {nodeOrder?.map((i, index) => (
        <NodesCard
          data={treeData[i]}
          addNewNode={addNewNode}
          props={props}
          order={index}
          focusedNode={focusedNode}
        />
      ))}
    </div>
  );
}
