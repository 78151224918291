import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const WebsocketContext = createContext();
//                                              ready, value, send
const WS_URL = process.env.REACT_APP_WS_URL;
let awakeTimer = null;
const awakeInterval = 30000; // 30sec
const webSocket = new WebSocket(WS_URL);
export const SocketContext = createContext();

export function WebsocketProvider({ children }) {
  const [isReady, setIsReady] = useState(false);
  const [isError, setError] = useState(false);
  const [socket, setSocket] = useState(webSocket);

  const waitForOpenConnection = () =>
    new Promise((resolve, reject) => {
      const maxNumberOfAttempts = 10;
      const intervalTime = 2000; // ms

      let currentAttempt = 0;
      const interval = setInterval(() => {
        if (currentAttempt > maxNumberOfAttempts - 1) {
          clearInterval(interval);
          reject(new Error('Maximum number of attempts exceeded'));
        } else if (socket.readyState === socket.OPEN) {
          clearInterval(interval);
          resolve();
        }
        currentAttempt += 1;
      }, intervalTime);
    });

  const awakeWs = () => {
    awakeTimer = setInterval(() => {
      send(
        JSON.stringify({
          action: 'check_conn',
          request_id: new Date().getTime(),
        }),
      );
    }, awakeInterval);
  };

  const send = useCallback(
    async (obj) => {
      if (socket.readyState !== socket.OPEN) {
        try {
          await waitForOpenConnection();
          socket.send(obj);
        } catch (err) {
          console.error(err);
        }
      } else {
        socket.send(obj);
      }
    },
    [socket, isReady],
  );

  useEffect(() => {
    awakeWs();
    return () => clearInterval(awakeTimer);
  }, [socket]);

  useEffect(() => {
    socket.onopen = () => {
      console.log('OPENED!', socket);
      setError(false);
      setIsReady(true);
    };
    socket.onclose = () => {
      console.log('CLOSED!');
      setError(true);
      setIsReady(false);
      setTimeout(() => {
        console.log('connecting....', socket);
        setSocket(new WebSocket(WS_URL));
      }, 1000);
    };

    socket.onerror = function (err) {
      console.error(
        'socket encountered error: ',
        err.message,
        'Closing socket',
      );
      socket.close();
    };
  }, [socket]);

  const contextValue = useMemo(
    () => ({
      socket,
      isReady,
      send,
      isError,
    }),
    [socket, isReady, send, isError],
  );

  return (
    <WebsocketContext.Provider value={contextValue}>
      {children}
    </WebsocketContext.Provider>
  );
}
