/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid';
import { useContext, useEffect } from 'react';
import { blockTypesReversed } from '../constants/objects';
import { DataContext } from '../context/DataContext';

export default function useTaskNoteSync(
  prevContent,
  editor,
  selectedNode,
  changes,
  defaultId,
) {
  const { treeData, dispatch, addToQueue, blockParent } =
    useContext(DataContext);

  const changeHandler = () => {
    const tt = [...prevContent.current];
    const equal =
      JSON.stringify(prevContent.current) === JSON.stringify(editor.document);
    if (equal) return;

    const added2Blocks = editor.document.filter(
      (e1) => !tt.find((e2) => JSON.stringify(e1) === JSON.stringify(e2)),
    );
    const removedBlocks = prevContent.current.filter(
      ({ id: id1 }) => !editor.document.some(({ id: id2 }) => id1 === id2),
    );

    const addNodeBlock = (node, content) => {
      const newUUID = uuidv4();
      const nodeChild = {
        id: newUUID,
        layer_type: 'BLOCK',
        block_type: 'NODE',
        title: content || [],
        parent: node.id,
        order: [],
        assignments: [],
        previous_id: null,
      };
      treeData[nodeChild.id] = nodeChild;
      treeData[node.id].order = [nodeChild.id];
      dispatch({ type: 'setTree', payload: treeData });
      addToQueue('upsert', nodeChild);
    };
    added2Blocks.forEach((mBLock) => {
      const blockIdx = editor.document.findIndex((i) => i.id === mBLock.id);
      const parentId = mBLock.props?.type?.parent || selectedNode;
      const prevId = blockIdx > 0 ? editor.document[blockIdx - 1].id : null;

      const newObj = {
        ...mBLock.props?.type,
        id: mBLock.id,
        layer_type: 'BLOCK',
        block_type:
          mBLock.content?.length === 0 && mBLock.type !== 'todoList'
            ? 'TEXT'
            : blockTypesReversed[
                mBLock.props.level
                  ? mBLock.type + mBLock.props.level
                  : mBLock.type
              ],
        title: mBLock.content || [],
        parent: parentId,
        order: mBLock.props?.type?.blockOrder || [],
        previous: prevId,
        assignments: [],
        board_id: treeData[blockParent]?.board_id,
      };

      addToQueue('upsert', newObj);
      treeData[mBLock.id] = treeData[mBLock.id] || {};
      treeData[mBLock.id] = { ...treeData[mBLock.id], ...newObj };
      treeData[blockParent].has_note = true;
      if (mBLock.type === 'node')
        addNodeBlock(newObj, mBLock.props?.type?.title);
      dispatch({ type: 'setTree', payload: treeData });
    });

    const rBlocks = [];
    removedBlocks.forEach((rBlock) => {
      if (rBlock.id !== defaultId) rBlocks.push(rBlock.id);
    });
    if (rBlocks.length > 0) {
      addToQueue('multiDelete', {
        ids: rBlocks,
      });
    }
    let empty = true;
    editor.document?.map((b) => {
      if (b.type === 'node' || b.type === 'checkList') empty = false;
      else if (
        b.content?.length > 0 &&
        b.content &&
        (b.content[0]?.text?.length > 0 || b.content[0]?.link?.length > 0)
      )
        empty = false;
      return empty;
    });
    if (empty) treeData[blockParent].has_note = false;

    prevContent.current = JSON.parse(JSON.stringify(editor.document));
  };
  useEffect(() => {
    changeHandler();
  }, [changes]);
}
