import { useEffect } from 'react';
import { SvgSelector } from '../../helper/svg-selector';

function Toast({ toastInfo, setToastInfo }) {
  const showButton = toastInfo.hasButton ?? true;
  const handleClick = () => {
    setToastInfo({
      ...toastInfo,
      text: null,
      timer: null,
      hasButton: true,
    });
    if (toastInfo.action) toastInfo.action(toastInfo.extra?.item);
  };

  useEffect(() => {
    if (toastInfo.isLoading || toastInfo.persist) return undefined;
    const timer = setTimeout(
      () => {
        setToastInfo({ ...toastInfo, text: null });
      },
      toastInfo.timer || (toastInfo.action ? 5000 : 3000),
    );
    return () => clearTimeout(timer);
  }, [toastInfo?.isLoading]);

  return (
    <div className={toastInfo.isLoading ? 'toastContainer' : ''}>
      <div className="toastWrapper cursor-pointer toast-wrapper">
        <div className="toastText">{toastInfo.text}</div>

        {showButton && (
          <button
            type="button"
            className="cancelContainer btnHover"
            onClick={toastInfo.isLoading ? null : handleClick}
          >
            <div className="cancel">
              {toastInfo.isLoading ? (
                <div className="loading-spinner">
                  {SvgSelector('spinner', 'loading-svg')}
                </div>
              ) : (
                toastInfo.actionText
              )}
            </div>
          </button>
        )}
      </div>
    </div>
  );
}

export default Toast;
