import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Avatar from './Avatar';
import { setTextLimit } from '../../helper/functions';
import { SvgSelector } from '../../helper/svg-selector';
import Button from './Button';
import Menu from './Menu';

function Buttons({ setState, newCommentHandler }) {
  return (
    <div className="comments-main-topic-buttons-wrapper">
      <Button
        labelClass="font-12-bold"
        label="Cancel"
        textColor="black"
        color="gray_05"
        onClick={() => setState('view')}
        width="47%"
        height={30}
      />
      <Button
        labelClass="font-12-bold"
        label="OK"
        textColor="white"
        color="blue_strong"
        onClick={newCommentHandler}
        width="47%"
        height={30}
      />
    </div>
  );
}

function Likes({ item, likeComment, parentId, userAccess }) {
  const { likes_count: count, liked_by_you: liked } = item;
  const likeHandler = () =>
    userAccess === 'WRITE' ? likeComment(item.id, !liked, parentId) : null;
  return (
    <div
      className={`${
        userAccess === 'WRITE'
          ? 'comments-like-container'
          : 'comments-like-readonly'
      }`}
    >
      <div className="comments-like">
        {SvgSelector(liked ? 'liked' : 'like')}
      </div>
      <div
        className="comments-liked"
        onClick={item.deleted ? null : likeHandler}
      >
        {SvgSelector('liked')}
      </div>
      {count > 0 && (
        <div className="font-14-reg comments-like-counts">{count}</div>
      )}
    </div>
  );
}

export default function CommentSection({
  item,
  parentId,
  deleteComment,
  likeComment,
  editComment,
  replyComment,
  userAccess,
}) {
  const editorRef = useRef(null);
  const user = JSON.parse(localStorage.getItem('user'));
  const userID = user?.id.replaceAll('-', '');
  const [state, setState] = useState('view');
  const [shownSubmenu, showSubmenu] = useState(false);
  const [text, setText] = useState(item.text);
  const isReply = parentId;
  const fontReg12 = isReply ? 'font-10-reg' : 'font-12-reg';
  const fontReg14 = isReply ? 'font-12-reg' : 'font-14-reg';
  const editClickHandler = () => {
    showSubmenu(false);
    setState('edit');
  };

  const newCommentHandler = () => {
    if (state === 'edit') editComment(item.id, text, parentId);
    else if (state === 'reply') replyComment(item.id, text, parentId);
    setState('view');
  };

  useEffect(() => {
    if (state !== 'view') editorRef?.current.focus();
    if (state === 'edit') setText(item.text);
    else setText('');
  }, [state]);
  return (
    <div
      key={item.id}
      className={`comments-comment-section ${isReply ? 'comment-reply' : ''}`}
      onMouseLeave={() => showSubmenu(false)}
    >
      <div className="comments-user-wrapper">
        <div className="comments-avatar-container">
          <Avatar
            size={isReply ? 16 : 24}
            font={fontReg12}
            isRounded
            name={item.owner}
          />
        </div>
        <div className="comments-comment-data">
          <div>
            <div className={fontReg14}>{setTextLimit(item.owner, 12)}</div>
            {state === 'edit' && (
              <Likes
                item={item}
                likeComment={likeComment}
                parentId={parentId}
                userAccess={userAccess}
              />
            )}
          </div>
          <div
            className={`comments-comment-time font-12-reg ${
              isReply ? 'line-h-16' : ''
            }`}
          >
            {moment(item.modified_at).fromNow()}
          </div>
          <div className="comments-comment-more">
            {item.deleted || userAccess !== 'WRITE' ? (
              <div />
            ) : (
              <>
                <div onClick={() => setState('reply')}>
                  {SvgSelector('msgTyping')}
                </div>
                {userID === item.owner_id.replaceAll('-', '') && (
                  <div onClick={() => showSubmenu(true)}>
                    {SvgSelector('more', 'more-icon')}
                  </div>
                )}
                {shownSubmenu && (
                  <div className="comments-comment-submenu">
                    <Menu openMenu={showSubmenu}>
                      <div
                        className="font-12-reg hover comments-comment-submenu-item"
                        onClick={editClickHandler}
                      >
                        Edit
                      </div>
                      <div
                        className="font-12-reg hover comments-comment-submenu-item"
                        onClick={() => deleteComment(item.id, parentId)}
                      >
                        Delete
                      </div>
                    </Menu>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {state !== 'edit' && (
        <div
          className={`${
            isReply
              ? 'comments-comment-details-reply'
              : 'comments-comment-details'
          }`}
        >
          <div
            className={`font-12-reg item-mb-10 ${
              item.deleted ? 'comment-text-deleted' : ''
            }`}
          >
            {item.text}
          </div>
          <Likes
            item={item}
            likeComment={likeComment}
            parentId={parentId}
            userAccess={userAccess}
          />
        </div>
      )}
      {state !== 'view' && (
        <>
          <textarea
            ref={editorRef}
            className="comments-main-topic-textarea font-14-reg"
            placeholder={state === 'reply' ? item.owner : ''}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <Buttons setState={setState} newCommentHandler={newCommentHandler} />
        </>
      )}
    </div>
  );
}
