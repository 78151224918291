import { useContext, useEffect, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import Avatar from '../Elements/Avatar';
import { setTextLimit } from '../../helper/functions';
import { DataContext } from '../../context/DataContext';
import './styles/boardStyle.css';
import dataServices from '../../services/dataServices';

export default function MembersList({ boardId, inviteMember }) {
  const { shownAssignmentIcon, showAssignmentIcon } = useContext(DataContext);
  const [items, setItems] = useState([]);
  const [deletingMember, setDeletingMember] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));

  const getBoardMembers = () => {
    dataServices.getMemberships(boardId).then((res) => {
      setItems(res.data);
      setDeletingMember(null);
    });
  };

  useEffect(() => {
    getBoardMembers();
  }, []);

  const deleteMember = (info) => {
    if (deletingMember) return;
    setDeletingMember(info);
    const data = {
      email: info.email,
      privilege: 'NO_ACCESS',
    };
    dataServices.inviteMember(boardId, data).then(() => getBoardMembers());
  };

  return (
    <div className="sharing-card-menu">
      <div className="sharing-card-menu-header">
        <div className="flex-row cursor-pointer" onClick={inviteMember}>
          {SvgSelector('plus', 'blue-icon ')}
          <div className="font-14-med mh-5 member-list-add">Add a member</div>
        </div>
        <div
          className="sharing-card-view-wrapper"
          onClick={() => showAssignmentIcon((old) => !old)}
        >
          <div className="font-14-med mh-5 sharing-card-view-text">
            {shownAssignmentIcon ? 'View' : 'Hidden'}
          </div>
          {shownAssignmentIcon
            ? SvgSelector('eyeColorize', 'gray-1-icon')
            : SvgSelector('eyeCloseColorize', 'gray-1-icon')}
        </div>
      </div>
      {items?.map((i) => {
        if (i.privilege === 0) return null;
        return (
          <div
            key={i.id}
            className="modal-member-in-items flex-row hover member-list-items"
          >
            <div className="flex-row member-list-avatar">
              <Avatar size={24} name={i.email} />
              <div className="modal-move-in-item-text font-14-reg">
                {!i.first_name && !i.last_name
                  ? setTextLimit(i.email, 30)
                  : setTextLimit(`${i.first_name}\u00A0${i.last_name}`, 30)}
              </div>
            </div>
            <div
              className="member-list-delete"
              onClick={() => {
                deleteMember(i);
              }}
            >
              {i.id !== user.id ? (
                deletingMember === i ? (
                  <div className="loading-spinner loading-wrapper">
                    {SvgSelector('spinner', 'loading-page-spinner')}
                  </div>
                ) : (
                  SvgSelector('deleteColorize', 'sharing-member-delete-icon')
                )
              ) : (
                <div />
              )}
            </div>
          </div>
        );
      })}
      {!items?.length > 0 && (
        <div className="member-loading-container ">
          {SvgSelector('spinner', 'loading-page-spinner')}
        </div>
      )}
    </div>
  );
}
