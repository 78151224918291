/* eslint-disable import/prefer-default-export */
export const colors = {
  black: 'var(--black)',
  white: 'var(--white)',
  gray_04: 'var(--gray_04)',
  gray_05: 'var(--gray_05)',
  gray_06: 'var(--gray_06)',
  lavender: 'var(--lavender)',
  violet: 'var(--lavender)',
  gray: 'var(--gray_01)',
  light_gray: 'var(--gray_03)',
  lavender_light: 'var(--lavender_light)',
  purple: 'var(--purple)',
  purple_light: 'var(--purple_light)',
  blue_strong: 'var(--blue_strong)',
  blue_strong_light: 'var(--blue_light)',
  blue: 'var(--blue)',
  blue_light: 'var(--blue_light-2)',
  green: 'var(--green)',
  green_light: 'var(--green_light)',
  lime: 'var(--lime)',
  lime_light: 'var(--lime_light)',
  red: 'var(--red)',
  red_light: 'var(--red_light)',
  pink: 'var(--pink)',
  pink_light: 'var(--pink_light)',
  pink_strong: 'var(--pink_strong)',
  pink_strong_light: 'var(--pink_strong_light)',
  coral: 'var(--coral)',
  coral_light: 'var(--coral_light)',
  orange: 'var(--orange)',
  orange_light: 'var(--orange_light)',
  yellow: 'var(--yellow)',
  yellow_light: 'var(--yellow_light)',
};
export const nodeColors = {
  RED: 'var(--red_light)',
  CORAL: 'var(--coral_light)',
  ORANGE: 'var(--orange_light)',
  YELLOW: 'var(--yellow_light)',
  GREEN: 'var(--green_light)',
  BLUE: 'var(--blue_light-2)',
  PURPLE: 'var(--purple_light)',
  VIOLET: 'var(--lavender_light)',
  PINK: 'var(--pink_light)',
  GRAY: 'var(--gray_04)',
  LIGHT_GRAY: 'var(--gray_06)',
};
export const theme = {
  default: {
    '--light_white': '#ffffff',
    '--white': '#ffffff',
    '--black': '#000000',
    '--dark-30p': 'rgba(0, 0, 0, 0.3)',
    '--dark-50p': 'rgba(50, 50, 50, 0.85)',
    '--dark-200p': 'rgba(200, 200, 200, 0.3)',
    '--dark': '#3a3a3c',
    '--dark_05': '#636366',
    '--connectors': '#575555',
    '--connectors-levels': '#8E8E93',
    '--gray_0': '#aeaeb2',
    '--gray_01': '#8e8e93',
    '--gray_02': '#3f3f47',
    '--gray_03': '#c7c7cc',
    '--gray_04': '#d1d1d6',
    '--gray_05': '#e5e5ea',
    '--gray_06': '#f2f2f7',
    '--gray_07': '#888',
    '--gray_08': '#ebebeb',
    '--gray_09': '#999',
    '--lavender': '#af52de',
    '--lavender_light': '#F3DCFF',
    '--purple': '#5856d6',
    '--purple_light': '#CDCCF3',
    '--blue_strong': '#007aff',
    '--blue': '#18a0fb',
    '--blue_light': '#b7e1fd',
    '--blue_light-2': '#ceeffe',
    '--blue_light-3': '#eff9ff',
    '--green': '#34c759',
    '--green_light': '#bdf6cc',
    '--yellowgreen': 'yellowgreen',
    '--lime': '#70e852',
    '--lime_light': '#D4F8CB',
    '--red': '#ff3b30',
    '--red_light': '#FFC4C1',
    '--pink': '#ff2dd1',
    '--pink_light': '#FFC0F1',
    '--pink_strong': '#ff2d55',
    '--pink_strong_light': '#FFB5C1',
    '--coral': '#ff6746',
    '--coral_light': '#FFD1C7',
    '--orange': '#ff9500',
    '--orange_light': '#FFDFB3',
    '--yellow': '#ffcc00',
    '--yellow_light': '#ffeca0',
    '--yellow_light-2': '#fff7bf',
    '--button-dashboard': 'rgba(244, 234, 232, 1)',
  },
  dark: {
    '--light_white': 'rgb(21, 22, 23)',
    '--white': '#404040',
    '--black': '#ffffff',
    '--dark-30p': 'rgba(0, 0, 0, 0.393)',
    '--dark-50p': 'rgba(154, 151, 151, 0.85)',
    '--dark-200p': 'rgba(87, 86, 86, 0.3)',
    '--dark': '#a2a2a7',
    '--dark_05': '#636366',
    '--connectors': '#bbbaba',
    '--connectors-levels': '#bbbaba',
    '--gray_0': '#aeaeb2',
    '--gray_01': '#acacbf',
    '--gray_02': '#bbbbc5',
    '--gray_03': '#9b9ba1',
    '--gray_04': '#a5a5a9',
    '--gray_05': '#8c8c8e',
    '--gray_06': '#83838c',
    '--gray_07': '#2f2f2f',
    '--gray_08': '#6a6a6a',
    '--gray_09': '#999',
    '--lavender': '#af52de',
    '--lavender_light': '#F3DCFF',
    '--purple': '#5856d6',
    '--purple_light': '#CDCCF3',
    '--blue_strong': '#d3e8ff',
    '--blue': '#18a0fb',
    '--blue_light': '#b7e1fd',
    '--blue_light-2': '#798b94',
    '--blue_light-3': '#586065',
    '--green': '#34c759',
    '--green_light': '#496a52',
    '--yellowgreen': 'yellowgreen',
    '--lime': '#346e26',
    '--lime_light': '#D4F8CB',
    '--red': '#ff3b30',
    '--red_light': '#694e4d',
    '--pink': '#ff2dd1',
    '--pink_light': '#ad9ab6',
    '--pink_strong': '#ff2d55',
    '--pink_strong_light': '#FFC0CC',
    '--coral': '#742e1f',
    '--coral_light': '#FFD1C7',
    '--orange': '#784600',
    '--orange_light': '#FFDFB3',
    '--yellow': '#806600',
    '--yellow_light': '#8b8056',
    '--yellow_light-2': '#504701',
    '--button-dashboard': 'rgb(114, 107, 106)',
  },
};
