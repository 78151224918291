import { useState } from 'react';
import { colors } from '../constants';
import { floatMenuStatus } from '../constants/arrays';
import { SvgSelector } from '../helper/svg-selector';

export default function StatusMenu({ state, setState }) {
  const [status, setStatus] = useState(state);

  const statusChangeHandler = (s) => {
    setStatus(s.value);
    setState(s);
  };

  return (
    <div className="status-menu-container">
      <div className="status-menu-header">
        <div className="font-14-med">Select status</div>
        <div className="font-14-med" style={{ color: 'var(--gray_01)' }}>
          Clear
        </div>
      </div>
      {Object.values(floatMenuStatus).map((i) => (
        <button
          type="button"
          key={i.id}
          className="statusItems hover"
          onClick={() => statusChangeHandler(i)}
        >
          <div
            className="float-menu-openMenu-status"
            style={{
              backgroundColor: colors[i.color],
            }}
          >
            {SvgSelector(i.label)}
          </div>
          <div className="status-menu-text">{i.name}</div>
          <div className="float-menu-status-check">
            {status === i.value ? (
              <div className="status-menu-placeholder">
                {SvgSelector('checkMark')}
              </div>
            ) : (
              <div className="status-menu-placeholder" />
            )}
          </div>
        </button>
      ))}
    </div>
  );
}
