import { colorPanel } from '../../constants/arrays';
import { nodeColors } from '../../constants/colors';
import '../../styles/filterTag.scss';

function FilterTag({ onSelectItem }) {
  return (
    <div className="filter-tag-container box-shadow">
      <div className="font-14-med filter-tag-name">Add tag</div>
      <div className="filter-tag-separate" />
      <div className="filter-tag-item-container">
        {colorPanel.map((i) => (
          <div
            style={{ backgroundColor: nodeColors[[i.setColor]] }}
            className="font-14-med filter-tag-item-name cursor-pointer"
            onClick={() => onSelectItem(i)}
          >
            {i.color}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilterTag;
