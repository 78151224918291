import { toast } from 'react-toastify';
import Correct from '../assets/png/check-mark.png';
import Wrong from '../assets/png/uncheck-mark.png';

const textLimit = 30;

export const notify = (msg, err) => {
  toast(msg, {
    icon: <img src={err ? Wrong : Correct} alt="Address copied" />,
  });
};

export const removeItemOnce = (arr, value) => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const setTextLimit = (text, limit = textLimit) => {
  if (text.length > limit) {
    return `${text.substring(0, limit)}...`;
  }
  return text;
};

export const showUserName = (obj) => {
  if (!obj.first_name && !obj.last_name) return obj.email;
  return `${obj.first_name}\u00A0${obj.last_name}`;
};

export const objById = (obj) =>
  obj.reduce((ac, ele) => {
    return ele.id ? { ...ac, [ele?.id]: ele } : ac;
  }, {});

export const objSubtraction = (obj1, obj2) => {
  if (!obj2?.length > 0) return obj1;
  const newObj = objById(obj2);
  return obj1.filter((item) => !newObj[item.id]);
};

export function timeSince(date) {
  if (!date) return null;
  const seconds = Math.floor((new Date(date) - new Date()) / 1000);
  if (seconds < 0) return null;
  const interval = seconds / 86400;

  if (interval > 1) {
    return Math.ceil(interval);
  }
  return 1;
}

export const getAncestors = (tree, node) => {
  let currentNode = tree[node];
  if (!currentNode) return null;
  let labels = [
    {
      node: currentNode.id,
      title: currentNode.title,
      emoji: currentNode.emoji,
    },
  ];
  while (tree[currentNode.parent]) {
    currentNode = tree[currentNode.parent];
    labels = [
      ...labels,
      {
        key: currentNode.id,
        node: currentNode.id,
        title: currentNode.title,
        emoji: currentNode.emoji,
      },
    ];
  }
  return labels.reverse();
};

export const deepClone = (array) => {
  return JSON.parse(JSON.stringify(array));
};

export const deadlineCal = (d) => {
  const today = new Date();
  const tomorrow = new Date();
  const yesterday = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  yesterday.setDate(yesterday.getDate() - 1);
  if (today.toDateString() === new Date(d).toDateString())
    return ['Today', 'alert'];
  if (tomorrow.toDateString() === new Date(d).toDateString())
    return ['Tomorrow', 'alert'];
  if (yesterday.toDateString() === new Date(d).toDateString())
    return ['Yesterday', 'alert'];
  return [`${new Date(d).toDateString()}`, 'default'];
};
