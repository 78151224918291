import { useContext, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DataContext } from '../../context/DataContext';
import './styles/boardStyle.css';

export default function DraggableNode({
  inputFocused,
  isFolder,
  node,
  isProject,
  filterNode,
  treeData,
  status,
  children,
  mainNode,
  showNotify,
  togoMode,
}) {
  const {
    dispatch,
    addToQueue,
    setRefresh,
    userAccess,
    isDraggingNode,
    setIsDraggingNode,
  } = useContext(DataContext);

  const draggableNode = useRef(null);
  const [isOverItem, setOverItem] = useState(null);
  const statusBorder = () => {
    if (togoMode) return '2px solid transparent';
    if (status === 'DELEGATE') return '2px solid var(--blue_strong)';
    if (status === 'TOGO') return '2px solid var(--black)';
    return '2px solid transparent';
  };

  const isSafeMove = (des, src) => {
    let nodeParentId = src;
    while (nodeParentId !== mainNode) {
      if (nodeParentId === des) {
        showNotify({
          isLoading: false,
          text: 'This operation is not allowed!',
          actionText: 'Okay',
          action: null,
        });
        return false;
      }
      nodeParentId = treeData[treeData[nodeParentId].parent].id;
    }
    return true;
  };

  let p1T;
  let p1x;
  let p2;

  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop(droppedItem, monitor) {
      if (!draggableNode.current) {
        return;
      }
      const dragItem = droppedItem.node;
      const hoverItem = node;
      const oldParent = treeData[dragItem].parent;
      const oldParentOrder = treeData[oldParent].order;

      const hoverItemParentType =
        treeData[treeData[hoverItem].parent].layer_type;
      const dragItemParentType = treeData[treeData[dragItem].parent].layer_type;
      if (
        (dragItemParentType === 'BOARD' && hoverItemParentType === 'TASK') ||
        dragItem === hoverItem
      )
        return;

      if (!isSafeMove(dragItem, hoverItem)) return;
      monitor.isOver({ shallow: true });
      monitor.isOver({ shallow: true });
      p1T = draggableNode.current.getBoundingClientRect().y;
      p1x = draggableNode.current.clientHeight / 3;
      p2 = monitor.getClientOffset();
      const center = p1T + p1x < p2.y && p1T + 2 * p1x > p2.y;
      const bottom = p1T + 2 * p1x < p2.y;
      const top = p1T + p1x > p2.y;

      /** CENTER */
      if (center) {
        const tempData = treeData;
        const dragItemParent = tempData[dragItem].parent;
        const hoverItemOrder = tempData[hoverItem].order || [];
        const newParentOldOrder = [...tempData[hoverItem].order];
        if (treeData[dragItem].parent === hoverItem) {
          showNotify({
            isLoading: false,
            text: 'This operation is not allowed!',
            actionText: 'Okay',
            action: null,
          });
          return;
        }
        tempData[dragItemParent].order = tempData[dragItemParent].order.filter(
          (x) => x !== dragItem,
        );
        tempData[dragItem].parent = hoverItem;
        hoverItemOrder.push(dragItem);
        addToQueue('move', {
          id: dragItem,
          parent: newParentOldOrder.length > 0 ? null : hoverItem,
          previous: newParentOldOrder[newParentOldOrder.length - 1] || null,
          oldParent: {
            id: oldParent,
            oldOrder: oldParentOrder,
            newOrder: tempData[dragItemParent].order,
          },
          newParent: {
            id: hoverItem,
            oldOrder: newParentOldOrder,
            newOrder: hoverItemOrder,
          },
        });
        dispatch({ type: 'setTree', payload: tempData });
        setRefresh((old) => old + 1);
      }
      /** TOP */
      if (top) {
        const tempData = treeData;
        const hoverItemParent = tempData[hoverItem].parent;
        const dragItemParent = tempData[dragItem].parent;
        const newParentOldOrder = [...tempData[hoverItemParent].order];

        tempData[dragItemParent].order = tempData[dragItemParent].order.filter(
          (x) => x !== dragItem,
        );

        let hoverItemPrevious = null;
        const hoverIndex = tempData[hoverItemParent].order?.findIndex(
          (x) => x === hoverItem,
        );
        if (hoverItemPrevious === dragItem) return;

        if (hoverIndex > 0)
          hoverItemPrevious = tempData[hoverItemParent].order[hoverIndex - 1];

        tempData[hoverItemParent].order.splice(hoverIndex, 0, dragItem);

        tempData[dragItem].parent = hoverItemParent;
        dispatch({ type: 'setTree', payload: tempData });
        addToQueue('move', {
          id: dragItem,
          parent: hoverItemPrevious ? null : hoverItemParent,
          previous: hoverItemPrevious || null,
          oldParent: {
            id: oldParent,
            oldOrder: oldParentOrder,
            newOrder: tempData[dragItemParent].order,
          },
          newParent: {
            id: hoverItemParent,
            oldOrder: newParentOldOrder,
            newOrder: tempData[hoverItemParent].order,
          },
        });

        setRefresh((old) => old + 1);
      }
      /** BOTTOM */
      if (bottom) {
        const tempData = treeData;
        const hoverItemParent = tempData[hoverItem].parent;
        const dragItemParent = tempData[dragItem].parent;
        const newParentOldOrder = [...tempData[hoverItemParent].order];

        tempData[dragItemParent].order = tempData[dragItemParent].order.filter(
          (x) => x !== dragItem,
        );

        let hoverItemPrevious = null;
        const hoverIndex = tempData[hoverItemParent].order?.findIndex(
          (x) => x === hoverItem,
        );
        if (hoverItemPrevious === dragItem) return;

        if (hoverIndex > 0)
          hoverItemPrevious = tempData[hoverItemParent].order[hoverIndex - 1];

        tempData[hoverItemParent].order.splice(hoverIndex + 1, 0, dragItem);

        tempData[dragItem].parent = hoverItemParent;
        dispatch({ type: 'setTree', payload: tempData });
        addToQueue('move', {
          id: dragItem,
          parent: hoverItem ? null : tempData[hoverItem].parent,
          previous: hoverItem || null,
          oldParent: {
            id: oldParent,
            oldOrder: oldParentOrder,
            newOrder: tempData[dragItemParent].order,
          },
          newParent: {
            id: hoverItemParent,
            oldOrder: newParentOldOrder,
            newOrder: tempData[hoverItemParent].order,
          },
        });

        setRefresh((old) => old + 1);
      }
    },
    hover(hoveredItem, monitor) {
      const dragIndex = hoveredItem.node;
      const hoverIndex = node;
      const hoverItemParentType =
        treeData[treeData[hoverIndex].parent].layer_type;
      const dragItemParentType =
        treeData[treeData[dragIndex].parent].layer_type;
      if (
        (dragItemParentType === 'BOARD' && hoverItemParentType === 'TASK') ||
        dragIndex === hoverIndex
      ) {
        setOverItem(null);
        return;
      }
      monitor.isOver({ shallow: true });
      p1T = draggableNode.current.getBoundingClientRect().y;
      p1x = draggableNode.current.clientHeight / 3;
      p2 = monitor.getClientOffset();
      const center = p1T + p1x < p2.y && p1T + 2 * p1x > p2.y;
      const bottom = p1T + 2 * p1x < p2.y;
      const top = p1T + p1x > p2.y;
      setOverItem(
        center
          ? 'nodeHoverCenter'
          : top
            ? 'nodeHoverTop'
            : bottom
              ? 'nodeHoverBottom'
              : null,
      );
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isDragging: !!monitor.isDragging,
    }),
  });

  const [, drag] = useDrag({
    item: () => {
      setIsDraggingNode(true);
      return { node };
    },
    end: () => {
      setIsDraggingNode(false);
    },
    type: 'card',
    canDrag: userAccess === 'WRITE',
  });

  drag(drop(draggableNode));

  return (
    <div
      ref={draggableNode}
      className={`nodes draggableContainer ${isOver ? isOverItem : ''} ${isOver ? 'node-drag-on-it' : ''}`}
      style={{
        border:
          inputFocused === node && !isFolder
            ? '2x solid #007AFF'
            : statusBorder(),
        height: isProject ? '100%' : undefined,
        opacity: isDraggingNode
          ? isOver
            ? 1
            : 0.5
          : togoMode || filterNode()
            ? 1
            : 0.2,
      }}
    >
      {children}
    </div>
  );
}
