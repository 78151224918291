import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/uploadingCard.scss';

function UploadingCard({ uploadingFile, data }) {
  return (
    <div className="upload-card-container">
      <div className="upload-card-loading-container">
        {SvgSelector(
          'smallLoading',
          `${uploadingFile ? 'small-loading-icon' : ''}`,
        )}
      </div>
      <div className="font-14-med">{data?.title[0]?.text.name}</div>
      <div className={`${uploadingFile ? 'upload-progress-load' : ''}`} />
    </div>
  );
}

export default UploadingCard;
