import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AnimationSlide from '../../Elements/AnimationSlide';
import { nodesColorPanel, nodesMoreMenu } from '../../../constants/arrays';
import { colors } from '../../../constants';
import Calendar from '../../Calendar';
import StatusMenu from '../../StatusMenu';
import MenuSelectMember from '../../Elements/MenuSelectMember';
import { DataContext } from '../../../context/DataContext';
import ModalAssign from '../../Elements/ModalAssign';
import { SvgSelector } from '../../../helper/svg-selector';

function NodesMoreMenu({
  nodeMoreItem,
  setNodeMoreItem,
  setOpenMenu,
  nodeColor,
  setNodeColor,
  nodeData,
  assignMember,
  setDate,
  onSelectStatus,
  props,
}) {
  const { treeData, dispatch, addToQueue } = useContext(DataContext);
  const [assignModalShown, showAssignModal] = useState('');
  const BlockBoardParent = treeData[nodeData.board_id];

  const duplicateNode = () => {
    const nodeParent = treeData[nodeData.id]?.parent;
    const index = treeData[nodeParent].order?.indexOf(nodeData.id);
    const newNodeId = uuidv4();
    const newNode = { ...nodeData, id: newNodeId, assignments: [] };
    treeData[newNodeId] = newNode;
    treeData[nodeParent].order.splice(index + 1, 0, newNodeId);
    dispatch({ type: 'setTree', payload: treeData });
    addToQueue('upsert', { ...newNode, previous: nodeData.id });
    setOpenMenu(null);
  };

  const deleteNode = () => {
    const nodeParent = treeData[nodeData.id]?.parent;
    treeData[nodeParent].order = treeData[nodeParent].order.filter(
      (o) => o !== nodeData.id,
    );
    if (!treeData[nodeParent]?.order.length > 0) {
      addToQueue('delete', treeData[nodeParent]);
      delete treeData[nodeParent];
      props.editor.removeBlocks([props.block.id]);
    }
    delete treeData[nodeData.id];
    dispatch({ type: 'setTree', payload: treeData });
    addToQueue('delete', nodeData);
  };
  const handleItemClick = (id) => {
    switch (id) {
      case 1:
        deleteNode();
        break;
      case 2:
        duplicateNode();
        break;
      case 4:
        setNodeMoreItem('status');
        break;
      case 5:
        setNodeMoreItem('calendar');
        break;
      case 6:
        setNodeMoreItem('color');
        break;
      case 7:
        setNodeMoreItem('user');
        break;
      default:
        setOpenMenu(false);
    }
  };

  return (
    <div>
      <div className="nodes-more-menu-container box-shadow">
        {nodesMoreMenu.map((i) =>
          i.name === 'br' ? (
            <span className="nodes-more-menu-separator" />
          ) : (
            <div
              className="nodes-more-menu-item cursor-pointer"
              onClick={() => handleItemClick(i.id)}
            >
              <div className="nodes-more-menu-item-name">
                {SvgSelector(i.icon, 'nodes-more-menu-icon')}
                <span className="font-14-reg">{i.name}</span>
              </div>
              <span className="font-12-reg nodes-more-menu-item-key">
                {i.id === 4 || i.id === 5 || i.id === 6 || i.id === 7
                  ? SvgSelector(i.key, 'nodes-menu-icon-chevron')
                  : i.key}
              </span>
            </div>
          ),
        )}
      </div>
      <AnimationSlide
        openMenu={setNodeMoreItem}
        show={nodeMoreItem === 'status'}
        state="rightToLeft"
      >
        <div className="nodes-list-status-position">
          <StatusMenu setState={onSelectStatus} state={nodeData.status} />
        </div>
      </AnimationSlide>
      <AnimationSlide
        openMenu={setNodeMoreItem}
        show={nodeMoreItem === 'calendar'}
        state="rightToLeft"
      >
        <div className="nodes-more-calendar--position">
          <Calendar node={nodeData} callback={setDate} />
        </div>
      </AnimationSlide>
      <AnimationSlide
        openMenu={setNodeMoreItem}
        show={nodeMoreItem === 'color'}
        state="rightToLeft"
      >
        <div className="nodes-more-color-position">
          <div className="verticalMenuColorPanel">
            {nodesColorPanel.map((i) => (
              <div key={i.id} className="vertical-menu-color-container">
                <button
                  type="button"
                  className="cursor-pointer vertical-menu-color-item"
                  style={{
                    backgroundColor: colors[i.color],
                  }}
                  onClick={() =>
                    setNodeColor({ color: i.color, background: i.background })
                  }
                >
                  {nodeColor === i.setColor && (
                    <div
                      className="vertical-menu-color-check"
                      style={{
                        backgroundColor: colors.white,
                      }}
                    />
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>
      </AnimationSlide>
      <AnimationSlide
        openMenu={setNodeMoreItem}
        show={nodeMoreItem === 'user'}
        state="rightToLeft"
      >
        <div className="nodes-more-user-position">
          <MenuSelectMember
            data={BlockBoardParent?.members}
            setAssignMember={assignMember}
            taskData={nodeData}
          />
        </div>
      </AnimationSlide>

      <AnimationSlide
        show={assignModalShown}
        openMenu={showAssignModal}
        state="rightToLeft"
      >
        <ModalAssign
          openModal={showAssignModal}
          onAssign={assignMember}
          blockId={nodeData}
        />
      </AnimationSlide>
    </div>
  );
}

export default NodesMoreMenu;
