import { useContext, useState } from 'react';
import { SvgSelector } from '../../../../helper/svg-selector';
import { checkListMenuItem } from '../../../../constants/arrays';
import AnimationSlide from '../../../Elements/AnimationSlide';
import Calendar from '../../../Calendar';
import { DataContext } from '../../../../context/DataContext';
import ModalAssign from '../../../Elements/ModalAssign';
import MenuSelectMember from '../../../Elements/MenuSelectMember';

function CheckListMoreMenu({ props, block }) {
  const blockId = block.id;
  const { treeData } = useContext(DataContext);
  const [openMenu, setOpenMenu] = useState('');
  const [assignModalShown, showAssignModal] = useState('');
  const BlockBoardParent = treeData[block.board_id];
  const duplicate = () => {
    // console.log('duplicate', block.id);
    // const oldCheckListOrder = treeData[block.id].order;
    // const newCheckListId = uuidv4();
    // const newCheckList = {
    //   ...props.block,
    //   id: newCheckListId,
    // };
    // newCheckList.props.type.blockOrder = [];
    // props.editor.insertBlocks([newCheckList], blockId, 'after');
    // treeData[newCheckListId] = {
    //   ...treeData[block.id],
    //   id: newCheckListId,
    //   order: [],
    // };
    // oldCheckListOrder?.forEach((i) => {
    //   const newId = uuidv4();
    //   treeData[newId] = { ...treeData[i], id: newId, parent: newCheckListId };
    //   treeData[newCheckListId].order.push(newId);
    // });
  };

  const handleOpenMenu = (name) => {
    switch (name) {
      case 'calendarPlus':
        setOpenMenu('calendar');
        break;
      case 'userProfile':
        setOpenMenu('addMember');
        break;
      case 'delete':
        props.editor.removeBlocks([props.block.id]);
        break;
      case 'copy':
        duplicate();
        break;

      default:
        console.log('name', name);
        break;
    }
  };

  const onAssign = () => {};

  return (
    <div>
      <div className="check-list-menu-container box-shadow">
        {checkListMenuItem.map((i) =>
          i.name === 'br' ? (
            <span className="check-list-menu-separator" />
          ) : (
            <div
              className="check-list-menu-item-container cursor-pointer"
              key={i.id}
              onClick={() => handleOpenMenu(i.icon)}
            >
              <div className="check-list-menu-name-container">
                {SvgSelector(i.icon, 'check-list-menu-name-icon')}
                <span className="font-14-reg">{i.name}</span>
              </div>
              <span className="check-list-menu-keyName font-12-reg">
                {i.id === 4 || i.id === 5
                  ? SvgSelector(i.keyName, 'check-list-menu-keyName-icon')
                  : i.keyName}
              </span>
            </div>
          ),
        )}
      </div>
      <AnimationSlide
        show={openMenu === 'calendar'}
        state="rightToLeft"
        openMenu={setOpenMenu}
      >
        <div className="check-list-more-menu-calendar">
          <Calendar node={props.block.id} />
        </div>
      </AnimationSlide>

      <AnimationSlide
        show={openMenu === 'addMember'}
        state="rightToLeft"
        openMenu={setOpenMenu}
      >
        <div className="check-list-more-menu-members">
          <MenuSelectMember
            data={BlockBoardParent?.members}
            setAssignMember={onAssign}
            taskData={block}
          />
        </div>
      </AnimationSlide>

      <AnimationSlide
        show={assignModalShown}
        openMenu={showAssignModal}
        state="rightToLeft"
      >
        <ModalAssign
          openModal={showAssignModal}
          onAssign={onAssign}
          blockId={blockId}
        />
      </AnimationSlide>
    </div>
  );
}

export default CheckListMoreMenu;
