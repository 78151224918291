import { sendOptions } from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';

export default function FloatMenuSharing({
  node,
  shareLayer,
  setInviteModal,
  showMenu,
}) {
  const clickHandler = (id) => {
    if (showMenu) showMenu(false);
    if (id === 0) shareLayer(node);
    else setInviteModal(node);
  };
  return (
    <div className="float-menu-sharing">
      <div className="sendOptionsWrapper">
        {sendOptions.map((i) => (
          <button
            type="button"
            key={i.id}
            className="statusItems hover float-menu-sharing-item"
            onClick={() => clickHandler(i.id)}
          >
            <div className="float-menu-sharing-icon">
              {SvgSelector(i.label)}
            </div>
            <div>{i.name}</div>
            <div className="float-menu-sharing-empty" />
          </button>
        ))}
      </div>
    </div>
  );
}
