import '../../styles/taskBoard.css';
import { useState } from 'react';
import Header from './Header';
import TabContainer from './TabContainer';

function Account({ setOpenAccountTab, tabNum }) {
  const [tab, setTab] = useState(tabNum);
  return (
    <div
      className="account-container"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          setOpenAccountTab(false);
        }
      }}
    >
      <div className="account-wrapper">
        <Header tab={tab} setTab={setTab} openAccount={setOpenAccountTab} />
        <TabContainer tab={tab} />
      </div>
    </div>
  );
}

export default Account;
