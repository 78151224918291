import { useState } from 'react';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { SvgSelector } from '../../helper/svg-selector';
import Button from './Button';
import { exportItems } from '../../constants/arrays';

function ModalExport({ openModal, boardID, treeRef, boardName }) {
  const [selectedItem, selectItem] = useState(exportItems[0]);
  const [isLoading, setIsLoading] = useState(false);

  const exportHandler = () => {
    if (isLoading) return;
    setIsLoading(true);
    if (selectedItem.id === 4) exportSvg();
    else if (selectedItem.id === 5) exportPdf();
    else if (selectedItem.id === 6) exportPng();
    else exportOtherFormats();
  };

  const exportOtherFormats = () => {
    openModal(false);
    window.location.href = `${process.env.REACT_APP_BASE_URL}api/layers/${boardID}/export/${selectedItem.type}/`;
    setIsLoading(false);
  };

  function exportPng() {
    const node = treeRef.current;
    htmlToImage
      .toPng(node, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${boardName}.png`;
        link.href = dataUrl;
        link.click();
        setIsLoading(false);
        openModal(false);
      })
      .catch(() => {
        setIsLoading(false);
        openModal(false);
      });
  }

  function exportPdf() {
    const node = treeRef.current;
    htmlToImage
      .toPng(node, {
        cacheBust: true,
        canvasWidth: node.clientWidth * 3,
        canvasHeight: node.clientHeight * 3,
      })
      .then(function (dataUrl) {
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF({
          orientation:
            treeRef.current.clientWidth > treeRef.current.clientHeight
              ? 'l'
              : 'p',
          format: [node.clientWidth, node.clientHeight],
        });
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(dataUrl, 'PNG', 0, 1, pdfWidth, pdfHeight);
        pdf.save(`${boardName}.pdf`);
        setIsLoading(false);
        openModal(false);
      })
      .catch(() => {
        setIsLoading(false);
        openModal(false);
      });
  }

  function filter(node) {
    return node.tagName !== 'i';
  }
  function exportSvg() {
    const node = treeRef.current;
    htmlToImage
      .toSvg(node, { filter })
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = `${boardName}.svg`;
        link.href = dataUrl;
        link.click();
        setIsLoading(false);
        openModal(false);
      })
      .catch(() => {
        openModal(false);
        setIsLoading(false);
      });
  }
  return (
    <div
      className="modal-container"
      aria-hidden="true"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          openModal(false);
        }
      }}
    >
      <div className="modal-wrapper modal-export-container">
        <div className="modal-header">
          <div className="font-16-bold">Export</div>
          <button
            type="button"
            className="cursor-pointer center"
            onClick={() => openModal(false)}
          >
            {SvgSelector('close')}
          </button>
        </div>
        <div className="modal-export-download-container">
          {exportItems.map((item) => (
            <div
              className="flex-row cursor-pointer modal-export-download-check"
              onClick={() => selectItem(item)}
            >
              {SvgSelector(
                item.id === selectedItem.id ? 'radioBtnFill' : 'radioBtn',
                'modal-export-radio-icon',
              )}
              <img
                src={item.src}
                width="30"
                height="30"
                alt=""
                className="modal-export-icons"
              />
              <div className="font-14-reg modal-export-download-text">
                {item.title}{' '}
                <span className="modal-export-download-name font-12-reg">
                  {item.description}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className="modal-buttons modal-export-buttons">
          <Button
            labelClass="font-12-bold"
            label="Cancel"
            textColor="black"
            color="gray_06"
            onClick={() => openModal(false)}
            width="47%"
            height={36}
          />
          <Button
            labelClass="font-12-bold"
            label="Export"
            textColor="white"
            color="blue_strong"
            isLoading={isLoading}
            onClick={exportHandler}
            width="47%"
            height={36}
          />
        </div>
      </div>
    </div>
  );
}
export default ModalExport;
