import { SvgSelector } from '../../helper/svg-selector';

function Loading() {
  return (
    <div className="loading-container">
      <div className="loading-text-wrapper">
        <div className="font-h3 loading-text">Loading</div>
        <div className="loading-spinner loading-wrapper">
          {SvgSelector('spinner', 'loading-page-spinner')}
        </div>
      </div>
    </div>
  );
}
export default Loading;
