import TaskBoardCard from './TaskBoardCard';

function TaskSection(props) {
  const {
    list,
    updateTaskList,
    reLocateTask,
    updateTask,
    deleteTask,
    duplicateTask,
    changeStatus,
    dataObject,
    treeData,
    setCheckMembers,
    title,
  } = props;
  return (
    <div className="task-section-container">
      <div className="font-16-bold task-section-title">{title}</div>
      <div>
        {list.map((board) => (
          <TaskBoardCard
            key={board}
            type="newTask"
            updateTaskList={updateTaskList}
            taskId={board.id}
            reLocateTask={reLocateTask}
            updateTask={updateTask}
            data={board}
            deleteTask={deleteTask}
            duplicateTask={duplicateTask}
            changeStatus={changeStatus}
            dataObject={dataObject}
            treeData={treeData}
            setCheckMembers={setCheckMembers}
          />
        ))}
      </div>
    </div>
  );
}

export default TaskSection;
