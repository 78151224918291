import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { Emoji } from 'emoji-picker-react';
import { v4 as uuidv4 } from 'uuid';
import { DataContext } from '../../../context/DataContext';
import {
  colorPanel,
  dashboardProfileMenu,
  floatMenuStatus,
} from '../../../constants/arrays';
import { SvgSelector } from '../../../helper/svg-selector';
import Button from '../../Elements/Button';
import Avatar from '../../Elements/Avatar';
import { colors } from '../../../constants';
import ModalExport from '../../Elements/ModalExport';
import MembersList from '../MembersList';
import TodoIcon from './TodoIcon';
import { layerProps } from '../../../constants/objects';
import ModalImport from '../../Elements/ModalImport';
import AnimationSlide from '../../Elements/AnimationSlide';
import LevelsMenu from '../LevelsMenu';
import CustomBoardEmoji from '../../../assets/png/board-custom-icon.png';
import CustomProjectEmoji from '../../../assets/png/project-custom-icon.png';
import dataServices from '../../../services/dataServices';

function MainMenu({
  setInputFocus,
  boardID,
  setReady,
  viewType,
  treeRef,
  setOpenThemeMenu,
  toastInfo,
  setToastInfo,
  duplicateTemplate,
}) {
  const ctx = useContext(DataContext);
  const navigate = useNavigate();
  const lastPageId = sessionStorage.getItem('lastPageId');
  const [menuOpened, menuOpen] = useState(false);
  const [isOpenExportMenu, openExportMenu] = useState(false);
  const [isOpenSharingMenu, openSharingMenu] = useState(false);
  const [isOpenLevelsMenu, openLevelsMenu] = useState(false);
  const [openedProfileMenu, openProfileMenu] = useState(false);
  const [shownImportModal, showImportModal] = useState(false);
  const [inputName, setInputName] = useState(false);
  const isTemplate = ctx.treeData[boardID]?.template;
  const todoNodesCounts = Object.values(ctx.treeData).filter(
    (node) => node.status === 'TOGO',
  )?.length;
  const [name, setName] = useState(ctx?.layers[boardID]?.title[0].text);

  const user = JSON.parse(localStorage.getItem('user'));
  const newProject = () => {
    const tempData = ctx.treeData;
    const boardOrder = tempData[boardID]?.order;
    const newChildID = uuidv4();
    const newChild = {
      ...layerProps,
      id: newChildID,
      parent: tempData[boardID]?.id,
      title: [{ text: '' }],
      color: 'PINK',
      layer_type: 'TASK',
      board_id: boardID,
      previous: boardOrder ? boardOrder[boardOrder.length - 1] : null,
    };
    ctx.setBlockParent(newChildID);
    ctx.setCurrNode(newChildID);
    tempData[boardID].order =
      tempData[boardID].order.length > 0
        ? [...tempData[boardID].order, newChildID]
        : [newChildID];
    tempData[newChildID] = newChild;
    ctx.addToQueue('create', tempData[newChildID]);
    setInputFocus(newChildID);
    ctx.dispatch({ type: 'setTree', payload: tempData });
  };
  const checkFilter = (filter, id) =>
    ctx.tempFilterOptions[filter]?.includes(id);

  const addFilterStatus = (property, item) => {
    const tempFilter = { ...ctx.tempFilterOptions };
    const index = tempFilter[property]?.indexOf(item);

    if (index >= 0) {
      tempFilter[property].splice(index, 1);
    } else {
      if (tempFilter.statuses.includes('DONE') && property === 'hideDone') {
        const doneFilterIdx = tempFilter.statuses.indexOf('DONE');
        tempFilter.statuses.splice(doneFilterIdx, 1);
      }
      if (
        tempFilter.hideDone.includes('DONE') &&
        property === 'statuses' &&
        item === 'DONE'
      ) {
        const doneFilterIdx = tempFilter.hideDone.indexOf('DONE');
        tempFilter.hideDone.splice(doneFilterIdx, 1);
      }
      tempFilter[property].push(item);
    }
    ctx.dispatch({ type: 'setTempFilter', payload: { ...tempFilter } });
  };
  const applyFilter = () => {
    ctx.dispatch({
      type: 'setFilter',
      payload: JSON.parse(JSON.stringify(ctx.tempFilterOptions)),
    });
    ctx.setRefresh((old) => old + 1);
  };
  const resetFilter = () => {
    ctx.dispatch({
      type: 'setFilter',
      payload: { colors: [], statuses: [], hideDone: [] },
    });
    ctx.dispatch({
      type: 'setTempFilter',
      payload: { colors: [], statuses: [], hideDone: [] },
    });
  };

  const logout = () => {
    window.location.href = '/accounts/logout/';
    localStorage.clear();
  };

  const changeTheme = () => {
    setOpenThemeMenu((prev) => !prev);
    openProfileMenu(false);
  };

  const menuClickHandler = (id) => {
    switch (id) {
      case 5:
        logout();
        break;
      case 6:
        changeTheme();
        break;
      default:
        break;
    }
  };

  const inviteMember = () => {
    openSharingMenu(false);
    ctx.setInviteModal(boardID);
  };

  const updateData = (id, params) => {
    setToastInfo({
      ...toastInfo,
      isLoading: true,
      text: 'Changing name',
      actionText: 'Okay',
      action: null,
    });
    dataServices.updateLayers(id, params).then(() =>
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: 'The name has been changed',
        actionText: 'Okay',
        action: null,
      }),
    );
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      setInputName(false);
      e.preventDefault();
      updateData(boardID, {
        title: [{ text: name !== '' ? name : 'Title' }],
      });
    }
  };

  const onConfirmName = () => {
    setInputName(false);
    updateData(boardID, { title: [{ text: name !== '' ? name : 'Title' }] });
  };

  return (
    <div>
      <div>
        <div className="mainMenu">
          <div className="main-menu-features">
            <div
              className="cursor-pointer hover board-main-menu-buttons tooltip"
              onClick={() => {
                setReady(false);
                navigate(`/${lastPageId || ''}`);
              }}
            >
              {SvgSelector('layerFav')}
              <span className="tooltiptext main-menu-back-tooltip font-10-med">
                Back
              </span>
            </div>
            {viewType === 'Tree View' &&
              (isTemplate ? (
                <Button
                  color="blue_strong"
                  height={34}
                  labelClass="font-14-bold"
                  buttonClass="editor-main-menu-add-to-dashboard-button"
                  label="Add to Dashboard"
                  onClick={() => duplicateTemplate(boardID)}
                  width={150}
                  textColor="white"
                />
              ) : (
                <>
                  {/* add-button */}
                  {ctx.userAccess === 'WRITE' && !ctx.togoMode && (
                    <div
                      className="board-main-menu-add cursor-pointer tooltip"
                      onClick={newProject}
                    >
                      {SvgSelector('plus', 'plus-main-menu')}
                      <div className="font-10-med tooltiptext main-menu-add-tooltip">
                        Add
                      </div>
                    </div>
                  )}
                  {/* todos */}
                  {ctx.userAccess === 'WRITE' && (
                    <TodoIcon
                      todoNodesCounts={Math.min(todoNodesCounts, 99)}
                      setTogoMode={ctx.setTogoMode}
                      togoMode={ctx.togoMode}
                      setReady={setReady}
                    />
                  )}
                  {/* undo */}
                  {ctx.userAccess === 'WRITE' && !ctx.togoMode && (
                    <div
                      className="cursor-pointer hover board-main-menu-buttons tooltip"
                      onClick={() => ctx.undoAction()}
                    >
                      {SvgSelector('arrowRotateLeft', 'arrowRotate-icon')}
                      <span className="tooltiptext font-10-med main-menu-back-tooltip">
                        Undo
                      </span>
                    </div>
                  )}
                  {/* redo */}
                  {ctx.userAccess === 'WRITE' && !ctx.togoMode && (
                    <div
                      className="cursor-pointer hover board-main-menu-buttons tooltip"
                      onClick={() => ctx.redoAction()}
                    >
                      {SvgSelector(
                        'arrowRotateLeft',
                        'arrowRotateLeft-icon arrowRotate-icon',
                      )}
                      <span className="tooltiptext font-10-med main-menu-back-tooltip">
                        Redo
                      </span>
                    </div>
                  )}
                  {!ctx.togoMode && (
                    <div
                      className="cursor-pointer hover board-main-menu-buttons tooltip"
                      onClick={() => ctx.expandAll()}
                    >
                      <div
                        style={{
                          backgroundColor: ctx.expandStatus
                            ? 'var(--gray_06)'
                            : 'transparent',
                        }}
                        className="main-menu-features-expend"
                      >
                        {SvgSelector(ctx.expandStatus ? 'layer' : 'layers')}
                        <span className="tooltiptext font-10-med main-menu-layers-tooltip">
                          {ctx.expandStatus ? 'Layer' : 'Layers'}
                        </span>
                      </div>
                    </div>
                  )}
                  {/* assignment */}
                  {ctx.userAccess === 'WRITE' && !ctx.togoMode && (
                    <div
                      className="cursor-pointer hover board-main-menu-buttons board-main-menu-member tooltip"
                      onClick={() => openSharingMenu((old) => !old)}
                    >
                      {SvgSelector('users')}
                      <span className="tooltiptext main-menu-back-tooltip font-10-med">
                        Users
                      </span>
                    </div>
                  )}
                  {!ctx.togoMode && (
                    <div
                      className="cursor-pointer hover board-main-menu-buttons board-main-menu-filter tooltip"
                      onClick={() => menuOpen((old) => !old)}
                    >
                      <div className="main-menu-features-filter">
                        {(ctx.filterOptions.statuses.length > 0 ||
                          ctx.filterOptions.colors.length > 0 ||
                          ctx.filterOptions.hideDone.length > 0) && (
                          <div className="main-menu-features-filter-icon" />
                        )}
                        {SvgSelector('settings')}
                        <span className="tooltiptext main-menu-filter-tooltip font-10-med">
                          Filter
                        </span>
                      </div>
                      {SvgSelector('chevronDown', 'chevron-main-menu')}
                    </div>
                  )}
                  {/* multi-selection */}
                  {ctx.userAccess === 'WRITE' && !ctx.togoMode && (
                    <div
                      className="cursor-pointer hover board-main-menu-buttons main-menu-features-select tooltip"
                      style={{
                        backgroundColor: ctx.treeMultiSelect
                          ? 'var(--gray_05)'
                          : undefined,
                      }}
                      onClick={() => ctx.setTreeMultiSelect((old) => !old)}
                    >
                      {SvgSelector('done')}
                      {/* <span className="tooltiptext main-menu-multiSelect-tooltip font-12-med">
                            Multi-Select
                          </span> */}
                    </div>
                  )}
                  {/* level-select */}
                  {ctx.userAccess === 'WRITE' && !ctx.togoMode && (
                    <div
                      className="cursor-pointer hover board-main-menu-buttons board-main-menu-filter tooltip"
                      onClick={() => openLevelsMenu((old) => !old)}
                    >
                      <div className="main-menu-features-filter">
                        {(ctx.filterOptions.statuses.length > 0 ||
                          ctx.filterOptions.colors.length > 0) && (
                          <div className="main-menu-features-filter-icon" />
                        )}
                        {SvgSelector('levelSelect', 'icon-16')}
                      </div>
                      {SvgSelector('chevronDown', 'chevron-main-menu')}
                      {/* <span className="tooltiptext main-menu-changeNodes-tooltip font-12-med">
                            Change-Nodes
                          </span> */}
                    </div>
                  )}
                </>
              ))}
          </div>
          {/* navigator */}
          <div className="main-menu-nav" onClick={() => ctx.queueLogger()}>
            {ctx.userAccess === 'WRITE' ? (
              !inputName ? (
                <span
                  onClick={() => setInputName(true)}
                  className="cursor-text font-14-med"
                >
                  {name !== '' ? name : 'Title'}
                </span>
              ) : (
                <input
                  className="font-14-med main-menu-nav-title-input"
                  style={{ width: `${name.length * 8}px` }}
                  maxLength={20}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyDown={onKeyDown}
                  onBlur={onConfirmName}
                />
              )
            ) : (
              <div>{name}</div>
            )}
            {ctx.nav.slice(1).map((i, index) => {
              const boardCustomEmoji = i.emoji === 'layer-board';
              const projectCustomEmoji = i.emoji === 'layer-project';
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="main-menu-title-separator-style"
                  style={{
                    opacity: ctx.nav.length === index + 1 ? 1 : 0.5,
                  }}
                >
                  <div
                    className="font-14-med main-menu-title-icon"
                    style={{
                      position:
                        index === 0
                          ? 'relative'
                          : index === ctx.nav.length - 2
                            ? 'relative'
                            : 'absolute',
                    }}
                  >
                    {index === 0 ? (
                      <p>{i.title?.at(0)?.text || ''} /</p>
                    ) : index === ctx.nav.length - 2 ? (
                      <p className="main-menu-title">
                        ... /&nbsp;
                        {i.emoji && (
                          <p>
                            <Emoji
                              emojiUrl={
                                boardCustomEmoji
                                  ? CustomBoardEmoji
                                  : projectCustomEmoji
                                    ? CustomProjectEmoji
                                    : null
                              }
                              unified={
                                boardCustomEmoji || projectCustomEmoji
                                  ? '1f4d8'
                                  : i.emoji
                              }
                              size="20"
                            />
                          </p>
                        )}
                        {i.title?.at(0)?.text}
                      </p>
                    ) : null}
                  </div>
                </div>
              );
            })}
            {ctx.isLoading && (
              <div className="loading-spinner margin-2">
                {SvgSelector('spinner', 'loading-svg')}
              </div>
            )}
            {!ctx.isLoading && (
              <div className="loading-success">{SvgSelector('checkMark')}</div>
            )}
          </div>
          {/* left side */}
          <div className="main-menu-right-side">
            {/* <div
                className="cursor-pointer hover"
                style={{ padding: 15, marginTop: 3, marginRight: 5 }}
              >
                {SvgSelector("alarm", "main-menu-alarm")}
              </div> */}
            {ctx.userAccess === 'WRITE' && (
              <div
                className="font-14-med main-menu-export cursor-pointer hover tooltip"
                onClick={() => openExportMenu(true)}
              >
                {SvgSelector('export')}
                <span className="tooltiptext font-10-med">Export</span>
              </div>
            )}
            {user && (
              <div
                className="cursor-pointer main-menu-account-profile"
                onClick={() => openProfileMenu(true)}
              >
                <Avatar size={24} name={user?.email} />

                {SvgSelector('chevronDown', 'chevron-main-menu')}
              </div>
            )}
          </div>
        </div>
        {/* {(menuOpened || openedProfileMenu || isOpenSharingMenu) && (
            <div
              className="main-menu-accounts"
              onClick={() => {
                if (menuOpened) menuOpen(false);
                if (openedProfileMenu) openProfileMenu(false);
                if (isOpenSharingMenu) openSharingMenu(false);
              }}
            />
          )} */}
        <AnimationSlide
          show={isOpenSharingMenu}
          openMenu={openSharingMenu}
          state="topToDown"
        >
          <MembersList boardId={boardID} inviteMember={inviteMember} />
        </AnimationSlide>
        <AnimationSlide
          show={isOpenLevelsMenu}
          openMenu={openLevelsMenu}
          state="topToDown"
        >
          <LevelsMenu />
        </AnimationSlide>
        <AnimationSlide show={menuOpened} openMenu={menuOpen} state="topToDown">
          <div className="menu">
            <div className="font-14-med filter-menu-status-title">Status</div>
            {Object.values(floatMenuStatus).map((i) => (
              <div
                key={i.id}
                className="statusItems hover"
                onClick={() => addFilterStatus('statuses', i.value)}
              >
                <div className="filter-menu-status-item">
                  {SvgSelector(i.label)}
                </div>
                <div className="font-14-reg filter-menu-status-name">
                  {i.name}
                </div>
                <div className="filter-menu-status-check-item">
                  {checkFilter('statuses', i.value) && SvgSelector('checkMark')}
                </div>
              </div>
            ))}
            <div className="font-14-med filter-color-title">Colors</div>
            <div className="filter-color-items">
              {colorPanel.map((i) => (
                <div
                  key={i.id}
                  className="cursor-pointer filter-color-item"
                  style={{
                    backgroundColor: colors[i.color],
                  }}
                  onClick={() => addFilterStatus('colors', i.setColor)}
                >
                  {checkFilter('colors', i.setColor) && (
                    <div
                      className="filter-color-check-item"
                      style={{
                        backgroundColor: colors.white,
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
            <div>
              <div className="font-14-med filter-color-title">Hide</div>
              <div
                className="statusItems hover"
                onClick={() => addFilterStatus('hideDone', 'DONE')}
              >
                <div className="filter-menu-status-item">
                  {SvgSelector('done')}
                </div>
                <div className="font-14-reg filter-menu-status-name">Done</div>
                <div className="filter-menu-status-check-item">
                  {checkFilter('hideDone', 'DONE') && SvgSelector('checkMark')}
                </div>
              </div>
            </div>
            <div className="menu-buttons">
              <Button
                labelClass="font-14-bold"
                label="Reset"
                textColor="black"
                color="gray_06"
                width={83}
                onClick={resetFilter}
              />
              <Button
                labelClass="font-14-bold"
                label="Apply"
                textColor="white"
                color="blue_strong"
                width={83}
                onClick={applyFilter}
              />
            </div>
          </div>
        </AnimationSlide>
        <AnimationSlide
          show={openedProfileMenu}
          openMenu={openProfileMenu}
          state="topToDown"
        >
          <div className="header-profile-menu">
            <div className="header-profile-menu-wrapper">
              <div className="header-profile-menu-info">
                <Avatar size={40} name={user?.email} />
                <div className="font-14-med main-menu-account-data">
                  {`${user?.first_name} ${user?.last_name}`}
                </div>
                <div className="font-12-reg main-menu-account-email">
                  {user?.email}
                </div>
              </div>
              <div className="header-menu-rest-items">
                {dashboardProfileMenu.slice(-2).map((i) => (
                  <div
                    key={i.id}
                    className="header-menu-profile-items font-14-reg"
                    onClick={() => menuClickHandler(i.id)}
                  >
                    {SvgSelector(i.img, 'change-theme-icon')}
                    <div className="header-menu-profile-text">{i.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </AnimationSlide>
        <AnimationSlide
          show={isOpenExportMenu}
          openMenu={openExportMenu}
          state="topToDown"
        >
          <ModalExport
            openModal={openExportMenu}
            boardID={boardID}
            treeRef={treeRef}
            boardName={ctx.treeData[boardID]?.title[0].text}
          />
        </AnimationSlide>
      </div>
      <AnimationSlide
        show={shownImportModal}
        openMenu={showImportModal}
        state="topToDown"
      >
        <ModalImport boardID={boardID} openModal={showImportModal} />
      </AnimationSlide>
    </div>
  );
}

export default MainMenu;
