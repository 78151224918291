import { useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import './styles/header.css';
import {
  dashboardChangeView,
  dashboardProfileMenu,
} from '../../constants/arrays';
import Avatar from '../Elements/Avatar';
import './styles/dashboardStyle.css';
import AnimationSlide from '../Elements/AnimationSlide';
import Button from '../Elements/Button';

function Header({
  openAccountHandler,
  searchContent,
  setSearchContent,
  viewType,
  setViewType,
  setOpenThemeMenu,
  clickHandlerProject,
  selectedTab,
  newTask,
}) {
  const [openedProfileMenu, openProfileMenu] = useState(false);
  const [openedViewMenu, openViewMenu] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));

  const logout = () => {
    window.location.href = '/accounts/logout/';
    localStorage.clear();
  };
  const changeTheme = () => {
    setOpenThemeMenu((prev) => !prev);
    openProfileMenu(false);
  };
  const clickHandlerProfile = (i) => {
    switch (i.id) {
      case 1:
        openAccountHandler(1);
        break;
      case 2:
        openAccountHandler(2);
        break;
      case 5:
        logout();
        break;
      case 6:
        changeTheme();
        break;
      default:
        break;
    }
  };
  const viewTypeHandler = (type) => {
    openViewMenu(false);
    setViewType(type);
  };
  return (
    <div className="header">
      {SvgSelector('search')}
      <input
        className="font-16-reg dashboard-header-search"
        value={searchContent}
        onChange={(e) => setSearchContent(e.target.value)}
        placeholder="Search"
      />
      <div className="dashboard-header-buttons">
        {selectedTab === 5 ? (
          <div className="dashboard-header-task">
            <Button
              color="blue_strong"
              height={36}
              image={SvgSelector('plus', 'dashboard-header-icon')}
              labelClass="font-14-bold"
              label="Task"
              width={90}
              textColor="white"
              onClick={() => newTask('New Task')}
            />
          </div>
        ) : (
          <div className="dashboard-header-buttons-wrapper">
            <Button
              color="blue_strong"
              height={36}
              image={SvgSelector('plus', 'dashboard-header-icon')}
              labelClass="font-14-bold"
              label="Board"
              width={90}
              textColor="white"
              onClick={() => clickHandlerProject('addBoard')}
            />
            <Button
              color="purple"
              height={36}
              image={SvgSelector('plus', 'dashboard-header-icon')}
              labelClass="font-14-bold"
              label="Project"
              width={98}
              textColor="white"
              onClick={() => clickHandlerProject('addProject')}
            />
            <button
              type="button"
              className="cursor-pointer hover center dashboard-header-export tooltip"
              onClick={() => clickHandlerProject('importModal')}
            >
              {SvgSelector('uploadBoard', 'view-svg')}
              <span className="tooltiptext font-10-med dashboard-header-export-tooltip">
                import
              </span>
            </button>
          </div>
        )}
        {selectedTab !== 5 && (
          <button
            type="button"
            className="cursor-pointer hover center dashboard-header-sort"
            onClick={() => openViewMenu(true)}
          >
            {SvgSelector(viewType, 'view-svg')}
            {SvgSelector('chevronDown', 'chevron-main-menu')}
          </button>
        )}
        {/* <div
          className="cursor-pointer hover center"
          style={{ padding: 12, marginRight: 8, marginLeft: 5 }}
        >
          {SvgSelector("alarm", "main-menu-alarm")}
        </div> */}
        <button
          type="button"
          className="cursor-pointer dashboard-header-account"
          onClick={() => openProfileMenu(true)}
        >
          <Avatar size={24} name={user?.email} />
          {SvgSelector('chevronDown', 'chevron-main-menu')}
        </button>
      </div>
      <AnimationSlide
        show={openedViewMenu}
        openMenu={openViewMenu}
        state="topToDown"
      >
        <div className="header-new-project-menu dashboard-header-sort-menu">
          {dashboardChangeView.map((i) => (
            <button
              type="button"
              key={i.id}
              className="header-new-project-items font-14-reg"
              onClick={() => viewTypeHandler(i.img)}
            >
              {SvgSelector(i.img)}
              <div className="w-100 flex-row flex-jc-sb">
                <div className="header-new-project-item">{i.name}</div>
                <div>{i.img === viewType && SvgSelector('checkMark')}</div>
              </div>
            </button>
          ))}
        </div>
      </AnimationSlide>
      <AnimationSlide
        show={openedProfileMenu}
        openMenu={openProfileMenu}
        state="topToDown"
      >
        <div className="header-profile-menu-container">
          <div className="header-profile-menu-width">
            <div className="header-profile-menu-info">
              {/* <img
                src={devImgs.avatar}
                alt={"Avatar"}
                className="header-menu-avatar"
              /> */}
              <Avatar size={40} name={user?.email} />

              <div className="font-14-med dashboard-header-account-name">
                {`${user?.first_name} ${user?.last_name}`}
              </div>
              <div
                className="font-12-reg dashboard-header-account-email"
                title={user?.email}
              >
                {user?.email}
              </div>
            </div>
            <div className="header-menu-rest-items">
              {dashboardProfileMenu
                .filter((i) => !i.onlyBoard)
                .map((i) =>
                  i.name === 'br' ? (
                    <div
                      key={i.id}
                      className="card-board-menu-separator-container"
                    >
                      <div className="card-board-menu-separator" />
                    </div>
                  ) : (
                    <div
                      key={i.id}
                      className="header-menu-profile-items font-14-reg"
                      onClick={() => clickHandlerProfile(i)}
                    >
                      {SvgSelector(i.img, 'change-theme-icon')}
                      <div className="header-menu-profile-text">{i.name}</div>
                    </div>
                  ),
                )}
            </div>
          </div>
        </div>
      </AnimationSlide>
    </div>
  );
}
export default Header;
