import Button from '../Elements/Button';

export default function MultiSelection({
  deleteMultiData,
  hardDeleteForEverData,
  selectedTab,
  showModalMoveIn,
}) {
  return (
    <div className="multi-selection-container">
      <Button
        labelClass="font-14-bold"
        label={selectedTab === 4 ? 'Permanently Delete ' : 'Delete'}
        textColor="black"
        color="orange_light"
        style={{ borderRadius: 6 }}
        width={selectedTab === 4 ? 160 : 80}
        onClick={selectedTab === 4 ? hardDeleteForEverData : deleteMultiData}
      />
      {selectedTab === 1 && (
        <Button
          labelClass="font-14-bold"
          label="Move"
          textColor="black"
          color="orange_light"
          style={{ borderRadius: 6 }}
          width={80}
          onClick={() => showModalMoveIn(true)}
        />
      )}
    </div>
  );
}
