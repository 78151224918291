import { useState } from 'react';
import { taskMoreDetails } from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';
import AnimationSlide from '../Elements/AnimationSlide';
import StatusMenu from '../StatusMenu';
import Calendar from '../Calendar';
import MenuSelectMember from '../Elements/MenuSelectMember';

function TaskBoardMoreMenu({
  deleteTask,
  duplicateTask,
  taskId,
  findedBoardId,
  type,
  setOpenMenu,
  changeStatus,
  name,
  data,
  statusIcon,
  setAssignMember,
  assignedBoard,
  updateTaskList,
  setDate,
  warnIfNotAssigned,
}) {
  const [openMenuMore, setOpenMenuMore] = useState('');
  const editTaskHandler = (i) => {
    switch (i.id) {
      case 1: {
        // 'Delete'
        deleteTask(data);
        setOpenMenu('');
        break;
      }
      case 2: {
        // 'Duplicate'
        duplicateTask(taskId, findedBoardId, type, name);
        break;
      }
      case 4: {
        // 'Status'
        setOpenMenuMore('status');
        break;
      }
      case 5: {
        // 'Set a deadline'
        setOpenMenuMore('deadLine');
        break;
      }
      case 6: {
        // 'Add tag'
        break;
      }
      case 7: {
        // 'Appoint members'
        setOpenMenuMore(assignedBoard ? 'newMember' : warnIfNotAssigned());
        break;
      }
      case 8: {
        // 'Add subtask'
        break;
      }
      case 9: {
        // 'Copy link to the block'
        break;
      }

      default:
        break;
    }
  };

  const onSelectStatus = (item) => {
    changeStatus(item);
    setOpenMenuMore('');
  };

  return (
    <div className="project-card-menu task-more-menu">
      {taskMoreDetails.map((i) =>
        i.title !== 'br' ? (
          <div
            key={`${i.key} ${i.title}`}
            className="contextMenuItemWrapper font-14-reg task-more-menu-item hover"
            onClick={() => editTaskHandler(i)}
          >
            <div className="task-more-menu-item-text">
              {SvgSelector(i.icon)}
              {i.title}
            </div>
            {i.key ? (
              <div className="contextMenuShortcutText font-12-reg task-more-menu-shortcut-text">
                {i.key}
              </div>
            ) : (
              i.id >= 3 &&
              i.id <= 7 && (
                <div className="card-board-menu-chevron">
                  {SvgSelector('chevronRight')}
                </div>
              )
            )}
          </div>
        ) : (
          i.title === 'br' && (
            <div className="card-board-menu-separator-container">
              <div className="card-board-menu-separator" />
            </div>
          )
        ),
      )}
      <AnimationSlide
        openMenu={setOpenMenuMore}
        show={openMenuMore === 'status'}
        state="rightToLeft"
      >
        <div className="task-board-more-status-menu">
          <StatusMenu setState={onSelectStatus} state={statusIcon.value} />
        </div>
      </AnimationSlide>
      <AnimationSlide
        openMenu={setOpenMenuMore}
        show={openMenuMore === 'deadLine'}
        state="rightToLeft"
      >
        <div className="task-board-more-deadline">
          <Calendar
            toggle={() => setOpenMenuMore(null)}
            node={data}
            directReq
            callback={setDate}
          />
        </div>
      </AnimationSlide>

      <AnimationSlide
        openMenu={setOpenMenuMore}
        show={openMenuMore === 'newMember'}
        state="rightToLeft"
      >
        <div className="task-board-more-member-more-pos">
          <MenuSelectMember
            data={assignedBoard?.members}
            setAssignMember={setAssignMember}
            taskData={data}
            updateTaskList={updateTaskList}
          />
        </div>
      </AnimationSlide>
    </div>
  );
}

export default TaskBoardMoreMenu;
