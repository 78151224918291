import { SvgSelector } from '../../../helper/svg-selector';
import '../../../styles/customFormattingToolbar.scss';
import { moreMenuTextEditor } from '../../../constants/arrays';

function TextEditorMoreMenu() {
  return (
    <div>
      <div className="text-editor-menu-container box-shadow">
        {moreMenuTextEditor.map((i) =>
          i.name === 'br' ? (
            <div className="text-editor-menu-separator" />
          ) : (
            <div className="text-editor-menu-item cursor-pointer">
              <div className="text-editor-menu-name font-14-reg">
                {SvgSelector(i.icon, 'text-editor-menu-icon')}
                {i.name}
              </div>
              <div className="text-editor-menu-key font-12-reg">
                {i.id === 4
                  ? SvgSelector(i.key, 'text-editor-menu-key-icon')
                  : i.key}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
}

export default TextEditorMoreMenu;
