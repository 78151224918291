import { themeList } from '../../constants/arrays';
import accountServices from '../../services/accountServices';

function ModalTheme({ setOpenThemeMenu, setSelectedTheme }) {
  const user = JSON.parse(localStorage.getItem('user'));
  const saveThemeData = (name) => {
    setSelectedTheme(name);
    setOpenThemeMenu(false);
    accountServices.updateUser({ theme: name }).then(() => {
      user.theme = name;
      localStorage.setItem('user', JSON.stringify(user));
    });
  };
  return (
    <div className="change-theme-container box-shadow">
      <div className="change-theme-title font-18-bold">Change Theme</div>
      <div className="change-theme-item-container">
        {themeList.map((themeItem) => (
          <div
            className="change-theme-item font-14-reg"
            onClick={() => saveThemeData(themeItem.name)}
            key={themeItem.name}
          >
            <img
              className="change-theme-image"
              src={themeItem.background}
              alt={themeItem.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ModalTheme;
