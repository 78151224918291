import '../../styles/switchButton.scss';

function SwitchButton({
  isToggled,
  setIsToggled,
  name,
  saveDatePickerData,
  nameSaveData,
}) {
  return (
    <label className="switch-container" htmlFor={name}>
      {}
      <input
        id={name}
        type="checkbox"
        checked={isToggled}
        value={!isToggled}
        name={name}
        onClick={() => {
          setIsToggled(!isToggled);
          saveDatePickerData(nameSaveData, !isToggled);
        }}
        className="switch-item"
      />
      <span className="switch-slider cursor-pointer" />
    </label>
  );
}

export default SwitchButton;
