import { useEffect, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/taskBoard.scss';

function AddTask({ newTask }) {
  const [focusIcon, setFocusICon] = useState('plus');
  const [taskName, setTaskName] = useState('');

  const addNewTask = () => {
    if (taskName.length === 0) return;
    setTaskName('');
    newTask(taskName);
  };

  const addTaskKeyConfirm = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addNewTask();
    }
  };

  useEffect(() => {
    if (taskName.length) setFocusICon('checkMark');
    else setFocusICon('plus');
  }, [taskName]);

  return (
    <div
      className={`add-task-container ${
        focusIcon === 'checkMark' ? 'add-task-name-focus' : ''
      }`}
    >
      <div className="">{SvgSelector('arrowCurve', 'add-task-arrow')}</div>
      <div
        onClick={() => (focusIcon === 'plus' ? null : addNewTask())}
        className={`${
          focusIcon === 'plus' ? 'add-task-plus' : 'add-task-check'
        } cursor-pointer`}
      >
        {SvgSelector(
          focusIcon,
          `${
            focusIcon === 'plus'
              ? 'add-task-plus-icon'
              : 'add-task-supercheck-icon'
          }`,
        )}
      </div>
      <input
        value={taskName}
        type="text"
        className="font-14-med add-task-name"
        placeholder="Enter task name"
        onChange={(e) => setTaskName(e.target.value)}
        onKeyDown={addTaskKeyConfirm}
      />
    </div>
  );
}

export default AddTask;
