import '../../styles/template.scss';
import { SvgSelector } from '../../helper/svg-selector';

function CardTemplate({ clickHandler, templateData }) {
  return (
    <div
      className="template-card-container cursor-pointer"
      onClick={() => templateData?.id && clickHandler(templateData.id)}
    >
      <div className="template-card-img" />
      <div className="template-card-title-container">
        <div className="template-card-title-wrapper">
          <div
            className="font-14-med template-card-name"
            title={templateData?.title[0].text}
          >
            {templateData?.title[0].text}
          </div>
          <div className="font-12-reg template-card-text">
            Description of template. Limited by 255 characters. Shown in card
            with 2 lines.
          </div>
        </div>
        <div className="template-card-feature">
          <div className="template-card-feature-wrapper">
            {SvgSelector('like', 'template-card-feature')}
            <div className="template-card-feature font-12-med">1230 used</div>
          </div>
          <div className="template-card-feature-wrapper">
            {SvgSelector('like', 'template-card-feature')}
            <div className="template-card-feature font-12-med">124</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardTemplate;
