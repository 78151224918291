import { useContext } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import { DataContext } from '../../context/DataContext';
import { levelItems } from '../../constants/arrays';

export default function LevelsMenu() {
  const { levelsMode, setLevelsMode, setRefresh } = useContext(DataContext);

  const changeLevelHandler = (i) => {
    setLevelsMode(i.status);
    setRefresh((old) => old + 1);
  };
  return (
    <div className="levels-card-menu">
      {levelItems?.map((i) => (
        <div
          key={i.id}
          className="levels-card-menu-items hover"
          onClick={() => changeLevelHandler(i)}
        >
          <div className="flex-row">
            {SvgSelector(i.img)}
            <div className="levels-card-menu-items-text font-14-reg">
              {i.title}
            </div>
          </div>
          {levelsMode === i.status && (
            <div> {SvgSelector('checkMark', 'levels-card-checkMark')}</div>
          )}
        </div>
      ))}
    </div>
  );
}
