import data from '@emoji-mart/data/sets/14/apple.json';
import { Emoji } from 'emoji-picker-react';
import EmPicker from '@emoji-mart/react';
import { SearchIndex } from 'emoji-mart';
import { useContext, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import '../../styles/emojiPicker.scss';
import AnimationSlide from './AnimationSlide';
import { SvgSelector } from '../../helper/svg-selector';
import { customEmojis, skinEmojiList } from '../../constants/arrays';

function EmojiPicker({ selectEmoji, argument }) {
  const { selectedTheme } = useContext(DataContext);
  const [changedHandSkin, setChangedHandSkin] = useState(1);
  const [openedMenu, openMenu] = useState(false);
  const [showedSearchEmoji, setShowedSearchEmoji] = useState(null);
  const [changedTab, setChangedTab] = useState(1);
  const [changedSearchIcon, setChangedSearchIcon] = useState(
    SvgSelector('search'),
  );
  const [selectedHand, setSelectedHand] = useState('270b');
  const [changedSearchValue, setChangedSearchValue] = useState();

  const shuffleEmoji = () => {
    const allEmojis = Object.values(data?.emojis);
    const randomIndex = Math.floor(Math.random() * allEmojis.length);
    const selectedEmoji = allEmojis[randomIndex].skins[0].unified;
    selectEmoji(argument, selectedEmoji);
  };

  const searchEmoji = async (value, handNum) => {
    setChangedSearchValue(value);
    const emojis = await SearchIndex.search(value);
    if (emojis?.length === 0) {
      setChangedSearchIcon(
        <div className="loading-spinner margin-2">
          {SvgSelector('spinner', 'loading-search')}
        </div>,
      );
    } else {
      setChangedSearchIcon(SvgSelector('search'));
    }
    const nativeResult = emojis?.map((emoji) => {
      const skins = handNum - 1;
      if (emoji.skins.length === 1) {
        return emoji.skins[0].unified;
      }
      return emoji?.skins[skins].unified;
    });
    setShowedSearchEmoji(nativeResult);
  };

  const changeSkin = (skin, hand) => {
    if (changedSearchValue) {
      searchEmoji(changedSearchValue, skin);
    }
    openMenu(false);
    setChangedHandSkin(skin);
    setSelectedHand(hand);
  };

  return (
    <div className="emojiPicker-container box-shadow">
      <div className="emojiPicker-tabs-container">
        <div className="emojiPicker-change-tab-item">
          <span
            className={`emojiPicker-change-tab ${
              changedTab === 1 && 'emojiPicker-selected'
            }`}
            onClick={() => setChangedTab(1)}
          >
            Emojis
          </span>
          <span
            className={`emojiPicker-change-tab ${
              changedTab === 2 && 'emojiPicker-selected'
            }`}
            onClick={() => setChangedTab(2)}
          >
            Custom
          </span>
        </div>
        <div
          className="hover cursor-pointer emojiPicker-delete"
          onClick={() => selectEmoji(argument, null)}
        >
          Delete
        </div>
      </div>
      <div className="emojiPicker-custom-items">
        <div className="emojiPicker-custum-search-icon">
          {changedSearchIcon}
        </div>
        <input
          onChange={(e) => searchEmoji(e.target.value, changedHandSkin)}
          className="emojiPicker-custom-search"
          placeholder="Search"
        />
        <div className="emojiPicker-extra-buttons-container">
          <div
            className="emojiPicker-extra-buttons cursor-pointer hover tooltip"
            onClick={() => shuffleEmoji()}
          >
            <span className="tooltiptext emojiPicker-change-shuffle font-small-reg">
              Random
            </span>
            {SvgSelector('shuffle')}
          </div>
          <div
            className="emojiPicker-extra-buttons cursor-pointer hover tooltip"
            onClick={() => openMenu(true)}
          >
            <span className="tooltiptext emojiPicker-change-skin font-small-reg">
              Skin
            </span>
            <Emoji unified={selectedHand} size="24" />
          </div>
        </div>
      </div>
      <AnimationSlide openMenu={openMenu} state="topToDown" show={openedMenu}>
        <div className="emojiPicker-change-skin-item box-shadow">
          {skinEmojiList.map((i) => (
            <div
              className="cursor-pointer hover"
              onClick={() => {
                changeSkin(i.skin, i.hand);
              }}
            >
              <Emoji unified={i.hand} size="24" />
            </div>
          ))}
        </div>
      </AnimationSlide>
      {showedSearchEmoji ? (
        <div style={{ width: '100%' }}>
          {showedSearchEmoji?.length === 0 ? (
            <div className="emojiPicker-change-tab">No Results</div>
          ) : (
            <div className="emojiPicker-search-result">
              {showedSearchEmoji.map((i) => (
                <div
                  onClick={() => selectEmoji(argument, i)}
                  className="cursor-pointer hover emojiPicker-search-result-item"
                >
                  <Emoji unified={i} size="24" />
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div>
          {changedTab === 1 && (
            <EmPicker
              onEmojiSelect={(emj) => {
                selectEmoji(argument, emj.unified);
              }}
              theme={selectedTheme === 'default' ? 'light' : 'dark'}
              previewPosition="none"
              skinTonePosition="none"
              searchPosition="none"
              skin={changedHandSkin}
              perLine={10}
              navPosition="none"
              set="apple"
            />
          )}
          {changedTab === 2 && (
            <div className="emojiPicker-search-result">
              {customEmojis?.map((i) => (
                <div
                  onClick={() => selectEmoji(argument, i.id)}
                  className="emojiPicker-search-result-item"
                >
                  <Emoji emojiUrl={i.imgUrl} unified="1f4d8" size="24" />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EmojiPicker;
