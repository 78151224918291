import { accountHeaderTabs } from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';

function Header({ tab, setTab, openAccount }) {
  return (
    <div className="account-header">
      <div className="account-header-tabs">
        {accountHeaderTabs.map((item) => (
          <div
            aria-hidden="true"
            key={item.id}
            className={`font-18-bold account-header-tab ${
              tab === item.id ? 'account-header-tab-active' : ''
            }`}
            onClick={() => setTab(item.id)}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className="cursor-pointer" onClick={() => openAccount(false)}>
        {SvgSelector('close')}
      </div>
    </div>
  );
}

export default Header;
