import './styles/dashboardStyle.css';

export default function DashboardHeader({
  sortedItem,
  setSortedItem,
  deletedScreen,
  sharedScreen,
}) {
  return (
    <div className="project-items">
      {!sharedScreen && <div className="dashboard-header-lineAgeView" />}
      <div className="dashboard-header-card" />
      <div
        className={`project-item-name cursor-pointer ${
          sortedItem === 'title[0].text' ? 'font-14-bold' : 'font-14-reg'
        }`}
        onClick={() =>
          setSortedItem((prev) =>
            prev === 'title[0].text' ? null : 'title[0].text',
          )
        }
      >
        Name
      </div>
      {!deletedScreen && (
        <div
          className={`project-item-count font-14-reg cursor-pointer ${
            sortedItem === 'block_count' ? 'font-14-bold' : 'font-14-reg'
          }`}
          onClick={() =>
            setSortedItem((prev) =>
              prev === 'block_count' ? null : 'block_count',
            )
          }
        >
          Blocks
        </div>
      )}
      <div
        className={`project-item-time font-14-reg cursor-pointer  ${
          sortedItem === 'modified_at' ? 'font-14-bold' : 'font-14-reg'
        }`}
        onClick={() =>
          setSortedItem((prev) =>
            prev === 'modified_at' ? null : 'modified_at',
          )
        }
      >
        Edited
      </div>
      <div className="project-item-add-member font-14-reg">
        <div className="flex-row">Members</div>
      </div>
      <div className="project-item-members" />
      <div className="project-item-fav" />
      <div className="project-item-more" />
    </div>
  );
}
