import { useCallback, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Account from '../Account';
import DashboardBody from '../Dashboard/DashboardBody';
import Header from '../Dashboard/Header';
import Sidebar from '../Dashboard/Sidebar';
import '../../styles/dashboard.css';
import Loading from '../Elements/Loading';
import { SvgSelector } from '../../helper/svg-selector';
import { DataContext } from '../../context/DataContext';
import Toast from '../Elements/Toast';
import { setTextLimit } from '../../helper/functions';
import useSocket from '../../hooks/useSocket';
import MenuMoveIn from '../Elements/MenuMoveIn';
import ModalTheme from '../Elements/ModalTheme';
import AnimationSlide from '../Elements/AnimationSlide';
import dataServices from '../../services/dataServices';
import SelectBoard from '../Dashboard/SelectBoard';
import '../../styles/recent.scss';
import Template from '../Dashboard/Template';
import TaskBoard from '../Dashboard/TaskBoard';
import { layerProps } from '../../constants/objects';

let loadingText;
let deletedBoard = null;
let duplicatedBoard = null;
let doneText;
let prevTab = 1;

function Dashboard({
  currentProject,
  setCurrentProject,
  RefreshLayer,
  boardID,
}) {
  const navigate = useNavigate();
  const {
    layers,
    setLayers,
    isLoading: loading,
    setTreeMultiSelect,
    setTogoMode,
    setOpenAccountTab,
    openAccountTab,
    selectedTheme,
    setSelectedTheme,
    recentLayer,
    setTasks,
    treeData,
  } = useContext(DataContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const RootId = user?.root?.id;
  const [tab, setTab] = useState(1);
  const [data, setData] = useState([]);
  const [dataObject, setDataObject] = useState([]);
  const [sharedWithMe, setSharedWithMe] = useState([]);
  const [templates, setTemplates] = useState();
  const [sharedByMe, setSharedByMe] = useState([]);
  const [deletedData, setDeletedData] = useState([]);
  const [SharedNotification, setSharedNotification] = useState(0);
  const [favoriteData, setFavoriteData] = useState({});
  const [isFocus, setFocus] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingData, setLoadingData] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [searchContent, setSearchContent] = useState('');
  const [viewType, setViewType] = useState();
  const [layerToShare, setLayerToShare] = useState();
  const [selectedItems, selectItems] = useState([]);
  const [checkShowTemplate, setCheckShowTemplate] = useState([]);
  const [modalMoveInShown, showModalMoveIn] = useState(false);
  const [toastInfo, setToastInfo] = useState({
    text: null,
    isLoading: false,
    actionText: 'Okay',
    action: null,
  });
  const [openThemeMenu, setOpenThemeMenu] = useState(false);
  const [shownImportModal, showImportModal] = useState(false);
  const [changedTab, setChangedTab] = useState('all');
  const [uploadingFile, setUploadingFile] = useState(true);

  const { socket } = useSocket();
  const openAccountHandler = (t) => {
    setTab(t);
    setOpenAccountTab(true);
  };
  const getViewType = () => {
    setViewType(localStorage.getItem('ViewType') || 'lineageView');
  };
  const setViewTypeHandler = (type) => {
    localStorage.setItem('ViewType', type);
    setViewType(type);
  };

  const getUnReadSharedBoard = () => {
    dataServices.getLengthSharedUnread().then((result) => {
      const unReadBoard = result.data.length;
      setSharedNotification(unReadBoard);
    });
  };

  const getData = async () => {
    const object = [];
    currentProject?.order.forEach((i) => {
      const it = layers?.find((item) => item.id === i);
      if (it) object.push(it);
    });
    setDataObject(object);
    setData(layers);
    getSharedWithMe();
    getSharedByMe();
    getTemplatesList();
    getUnReadSharedBoard();
    setLoading(false);
  };

  const getTemplatesList = () => {
    dataServices.getTemplates().then((res) => {
      if (res.data) setTemplates(res.data);
      return 'OK';
    });
  };

  const getDataByLayerType = (type) => {
    if (selectedTab === 4) return deletedData;
    if (changedTab === 'sharedWithMe') return sharedWithMe;
    if (changedTab === 'sharedByMe') return sharedByMe;
    if (selectedTab === 6) return null;
    return type === 'OBJECT' && dataObject;
  };
  const getFavorites = () => {
    dataServices.getFavorites().then((res) => {
      const favBoards = [];
      const favProjects = [];
      res.data.map((item) => {
        if (item.layer_type === 'BOARD') favBoards.push(item);
        else if (item.layer_type === 'PROJECT') favProjects.push(item);
        return null;
      });
      setFavoriteData({
        boards: favBoards,
        projects: favProjects,
      });
    });
  };

  useEffect(() => {
    setCheckShowTemplate(() => {
      return (
        (selectedTab === 1 &&
          recentLayer.length === 0 &&
          dataObject.length === 0 &&
          sharedByMe.length === 0 &&
          sharedWithMe.length === 0) ||
        selectedTab === 6
      );
    });
  }, [data, sharedByMe, sharedWithMe, recentLayer, selectedTab]);

  const navigateAddData = (item) => {
    const nav = `/${item}`;
    navigate(nav);
  };

  const addData = (type) => {
    setLoadingData(true);
    const newID = uuidv4();
    const newObj = {
      id: newID,
      layer_type: type === 'PROJECT' ? 'PROJECT' : 'BOARD',
      title: [{ text: 'Title' }],
      parent: currentProject.id,
      comments_count: 0,
      favorite: false,
      index: 0,
    };
    dataServices.addLayers(newObj).then(() => {
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: 'New Layer created',
        actionText: 'Okay',
        action: null,
      });
      if (type === 'BOARD') {
        sessionStorage.setItem('lastPageId', currentProject?.id);
        navigateAddData(newID);
      }
      if (type === 'PROJECT') {
        const currProject = currentProject;
        currProject.order = [newID, ...currProject.order];
        setLayers((old) => [...old, newObj]);
        setData((old) => [...old, newObj]);
        setCurrentProject(currProject);
        setFocus(newID);
      }
      setLoadingData(false);
    });
  };

  const updateSelectedItems = (id) => {
    if (id === null) {
      selectItems([]);
      return;
    }
    let tempSelectedItems = [...selectedItems];
    const findIndex = tempSelectedItems.findIndex((item) => item === id);
    if (findIndex === -1) {
      tempSelectedItems.push(id);
    } else {
      tempSelectedItems = tempSelectedItems.filter((item) => item !== id);
    }
    selectItems(tempSelectedItems);
  };

  const updateData = (id, params) => {
    const objIndex = layers.findIndex((i) => i.id === id);
    layers[objIndex] = { ...layers[objIndex], ...params };
    if (Object.keys(params)[0] === 'emoji') {
      loadingText = 'Changing emoji';
      doneText = 'The emoji has been changed';
    }
    if (Object.keys(params)[0] === 'title') {
      loadingText = 'Changing name';
      doneText = 'The name has been changed';
    }
    setToastInfo({
      ...toastInfo,
      isLoading: true,
      text: loadingText,
      actionText: 'Okay',
      action: null,
    });
    dataServices.updateLayers(id, params).then(() =>
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: doneText,
        actionText: 'Okay',
        action: null,
      }),
    );
  };

  const changeOrder = (parentID, id) => {
    if (parentID === data.find((i) => i.id === id).parent) return;
    loadingText = 'Moving...';
    const newOrder = currentProject.order.filter((i) => i !== id);
    setCurrentProject({ ...currentProject, order: newOrder });
    const object = [];
    newOrder?.map((i) => {
      const it = layers?.find((item) => item.id === i);
      if (it !== undefined) {
        object.push(it);
      }
      return null;
    });
    setDataObject(object);
    dataServices
      .relocateLayers({
        pks: [id],
        parent_id: parentID,
      })
      .then(() => {
        RefreshLayer();
        setLoading(false);
      });
    setFocus(-1);
  };
  const multiItemMoveToProject = (parentID) => {
    const firstId = selectedItems[0];
    if (parentID === data.find((i) => i.id === firstId)?.parent) return;
    loadingText = 'Moving...';
    const newOrder = currentProject.order.filter(
      (i) => !selectedItems.includes(i),
    );
    setCurrentProject({ ...currentProject, order: newOrder });
    const object = [];
    newOrder?.map((i) => {
      const it = layers?.find((item) => item.id === i);
      if (it !== undefined) {
        object.push(it);
      }
      return null;
    });
    setDataObject(object);
    showModalMoveIn(false);
    dataServices
      .relocateLayers({
        pks: selectedItems,
        parent_id: parentID,
      })
      .then(() => {
        RefreshLayer();
        setLoading(false);
      });
    selectItems([]);
    setFocus(-1);
  };

  const relocateOrderData = (id, params) => {
    setToastInfo({
      ...toastInfo,
      isLoading: true,
      text: loadingText,
      actionText: 'Okay',
      action: null,
    });
    dataServices.relocate(id, params).then(() =>
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: doneText,
        actionText: 'Okay',
        action: null,
      }),
    );
  };

  const reOrder = (newOrder, previousId, boardId) => {
    setCurrentProject({ ...currentProject, order: newOrder });
    const object = [];
    newOrder?.map((i) => {
      const it = layers?.find((item) => item.id === i);
      object.push(it);
      return null;
    });
    setDataObject(object);
    doneText = 'Order changed';
    loadingText = 'Changing order';
    if (previousId === null) {
      relocateOrderData(boardId, { parent: currentProject?.id });
    } else {
      relocateOrderData(boardId, { previous: previousId });
    }
  };

  const delayDuplicate = (item) => {
    setToastInfo({
      ...toastInfo,
      isLoading: false,
      text: `${setTextLimit(item.title[0].text)} Duplicated`,
      extra: { item: duplicatedBoard },
    });
    RefreshLayer();
  };

  const duplicateCard = (item) => {
    setLoadingData(true);
    duplicatedBoard = item;
    if (toastInfo.isLoading) return;
    if (item.block_count > 100) {
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: `${setTextLimit(item.title[0].text)} block's is more than 100`,
        actionText: 'Okay',
        action: null,
      });
      setLoadingData(false);
      return;
    }
    setToastInfo({
      ...toastInfo,
      isLoading: true,
      text: `Duplicating ${setTextLimit(item.title[0].text)}`,
      actionText: 'Okay',
      extra: { item },
    });
    dataServices.duplicateAllLayers(item.id).then(() => {
      const timer = setTimeout(() => {
        delayDuplicate(item);

        return () => clearTimeout(timer);
      }, 2000);
    });
    setFocus(-1);
  };

  const favHandler = (item, status) => {
    if (toastInfo.isLoading) return;

    if (status) {
      setToastInfo({
        ...toastInfo,
        isLoading: true,
        text: 'Adding to Favorites...',
        actionText: 'Okay',
        action: null,
      });
      dataServices.addFavorite(item.id).then(() => {
        setToastInfo({
          ...toastInfo,
          isLoading: false,
          text: `${setTextLimit(item.title[0].text)} added to Favorites`,
          actionText: 'Okay',
          action: null,
        });
        getFavorites();
      });
    } else {
      setToastInfo({
        ...toastInfo,
        isLoading: true,
        text: 'Removing from Favorites...',
      });
      dataServices.deleteFavorites(item.id).then(() => {
        setToastInfo({
          ...toastInfo,
          isLoading: false,
          text: `${setTextLimit(item.title[0].text)} removed from Favorites`,
          actionText: 'Okay',
          action: null,
        });
        getFavorites();
      });
    }
  };

  const deleteData = (item) => {
    deletedBoard = item;
    if (toastInfo.isLoading) return;
    setToastInfo({
      ...toastInfo,
      isLoading: true,
      text: `Deleting ${setTextLimit(item.title[0].text)}`,
      actionText: 'Okay',
      extra: { item },
    });
    dataServices.deleteLayer(item.id).then(() => {
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: `${setTextLimit(item.title[0].text)} Deleted`,
        actionText: 'Undo',
        action: undoDelete,
        extra: { item: deletedBoard },
      });
      RefreshLayer();
      getFavorites();
    });
  };

  const deleteMultiData = () => {
    if (toastInfo.isLoading) return;
    setToastInfo({
      ...toastInfo,
      isLoading: true,
      text: `Deleting Some items`,
      actionText: 'Okay',
    });
    dataServices.deleteMultiLayers({ pks: selectedItems }).then(() => {
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: 'Some items deleted',
        actionText: 'Okay',
        action: null,
      });
      RefreshLayer();
      getFavorites();
    });
  };

  const deleteForEverMultiData = () => {
    setToastInfo({
      ...toastInfo,
      isLoading: true,
      text: `Permanently Deleting Some items`,
      actionText: 'Okay',
      action: null,
    });
    dataServices.hardDeleteMultiLayers({ pks: selectedItems }).then(() => {
      selectItems([]);
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: 'Some items permanently Deleted',
        actionText: 'Okay',
        action: null,
      });
      getDeletedLayers();
    });
  };

  const undoDelete = (item) => {
    setToastInfo({
      ...toastInfo,
      isLoading: true,
      text: 'Undoing...',
      actionText: 'Okay',
      action: null,
    });

    doneText = 'Delete Undone';
    dataServices.unDeleteLayer(item.id).then(() => {
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: doneText,
        actionText: 'Okay',
        action: null,
      });
      RefreshLayer();
      getDeletedLayers();
      getFavorites();
    });
  };

  const deleteForEverData = (item) => {
    setToastInfo({
      ...toastInfo,
      isLoading: true,
      text: `Permanently Deleting ${setTextLimit(item.title[0].text)}`,
      actionText: 'Okay',
      action: null,
    });
    doneText = `${setTextLimit(item.title[0].text)} Permanently Deleted`;
    dataServices.hardDeleteLayer(item.id).then(() => {
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: doneText,
        actionText: 'Okay',
        action: null,
      });
      getDeletedLayers();
    });
  };

  const refreshData = () => {
    RefreshLayer();
  };

  const getDeletedLayers = () => {
    dataServices.getDeletedDescendants(RootId).then((res) => {
      setDeletedData(res.data);
      setLoading(false);
    });
  };

  const getSharedWithMe = () => {
    dataServices.getSharedWithMeLayers().then((res) => {
      setSharedWithMe(res.data);
      setLoading(false);
    });
  };

  const getSharedByMe = () => {
    dataServices.getSharedByMe().then((res) => {
      setSharedByMe(res.data);
      setLoading(false);
    });
  };

  const openBoard = (item) => {
    sessionStorage.setItem('lastPageId', currentProject?.id);
    const nav = `/${item.id}`;
    navigate(nav);
  };

  const openProject = (item) => {
    const nav = `/${item}`;
    setLoading(true);
    setChangedTab('all');
    navigate(nav);
  };

  const navigation = () => (
    <>
      <div className="selected-board-position-container">
        <SelectBoard
          shownImportModal={shownImportModal}
          showImportModal={showImportModal}
          clickHandlerProject={clickHandlerProject}
          selectedTab={selectedTab}
          checkMultiSelect={selectedItems?.length > 0}
          showModalMoveIn={showModalMoveIn}
          deleteMultiData={deleteMultiData}
          deleteForEverMultiData={deleteForEverMultiData}
          setUploadingFile={setUploadingFile}
          navigateAddData={navigateAddData}
          currentProject={currentProject}
          allData={dataObject}
          setAllData={setDataObject}
        />
      </div>
      <div className="flex-row dashboard-breadcrumb font-16-reg">
        <div
          aria-hidden="true"
          className="flex-row cursor-pointer dashboard-breadcrumb-back"
          onClick={() => navigate(`/${currentProject?.parent}`)}
        >
          {SvgSelector('chevronRight', 'flip')}
          <div>Back</div>
        </div>
        {currentProject?.ancestors.slice(1).map((i) => (
          <div
            aria-hidden="true"
            key={i.id}
            className="flex-row cursor-pointer"
            onClick={() => navigate(`/${i.id}`)}
          >
            <div style={{ marginLeft: 15, marginRight: 10 }}>
              {i.title[0].text}
            </div>
            {SvgSelector('chevronRight')}
          </div>
        ))}
        <div className="dashboard-breadcrumb-item font-16-bold">
          {currentProject?.title[0]?.text}
        </div>
      </div>
    </>
  );
  useEffect(() => {
    selectItems([]);
    if (!Array.isArray(layers)) return;
    setLoadingData(false);
    getData();
  }, [layers]);

  useEffect(() => {
    selectItems([]);
    if (prevTab === selectedTab) return;
    prevTab = selectedTab;
    if (selectedTab === 1) {
      // RefreshLayer();
    }
    if (selectedTab === 4) getDeletedLayers();
    setChangedTab('all');
  }, [selectedTab]);

  useEffect(() => {
    getFavorites();
  }, [dataObject]);

  const clickHandlerProject = (name) => {
    switch (name) {
      case 'addBoard':
        addData('BOARD');
        break;
      case 'addProject':
        addData('PROJECT');
        break;
      case 'importModal':
        showImportModal('importModal');
        break;
      case 'importModalAi':
        showImportModal('importModalAi');
        break;
      default:
        break;
    }
  };

  const updateUnreadMessage = (res) => {
    const timer = setTimeout(() => {
      getUnReadSharedBoard();
      getSharedWithMe();
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: `${res.email} added you to ${res.layer[0].text}`,
        actionText: 'Okay',
        action: null,
        timer: 5000,
      });
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const onMessage = useCallback((message) => {
    const res = JSON.parse(message?.data);
    switch (res.action) {
      case 'invitation_notif':
        if (!res.notifications) break;
        updateUnreadMessage(res);
        break;
      default:
        break;
    }
  }, []);
  const newTask = (taskName) => {
    const newChildID = uuidv4();
    const newChild = {
      ...layerProps,
      id: newChildID,
      parent: boardID,
      title: [{ text: taskName }],
      color: 'PINK',
      layer_type: 'TASK',
      status: 'BACKLOG',
      assignments: [],
    };
    dataServices.addLayers(newChild).then(() => {
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: 'New Task created',
        actionText: 'Okay',
        action: null,
      });

      setTasks((prevState) => [newChildID, ...prevState]);
      treeData[newChildID] = newChild;
    });
  };
  useEffect(() => {
    socket.addEventListener('message', onMessage);

    return () => {
      socket.removeEventListener('message', onMessage);
    };
  }, [socket, onMessage]);

  useEffect(() => {
    setTreeMultiSelect(false);
    setTogoMode(false);
    getViewType();
  }, []);

  return (
    <div className="dashboard">
      <Sidebar
        SharedNotification={SharedNotification}
        favoriteData={favoriteData}
        openBoard={openBoard}
        selectedTab={selectedTab}
        setLoading={setLoading}
        setSelectedTab={setSelectedTab}
      />
      <div className="dashboard-main">
        <Header
          openAccountHandler={openAccountHandler}
          viewType={viewType}
          setViewType={setViewTypeHandler}
          searchContent={searchContent}
          setSearchContent={setSearchContent}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          setOpenThemeMenu={setOpenThemeMenu}
          clickHandlerProject={clickHandlerProject}
          selectedTab={selectedTab}
          newTask={newTask}
        />

        {currentProject &&
          selectedTab === 1 &&
          RootId !== currentProject?.id &&
          navigation()}

        {RootId === currentProject?.id && !isLoading && selectedTab === 1 && (
          <div className="selected-board-position-container">
            <SelectBoard
              shownImportModal={shownImportModal}
              showImportModal={showImportModal}
              clickHandlerProject={clickHandlerProject}
              selectedTab={selectedTab}
              checkMultiSelect={selectedItems?.length > 0}
              showModalMoveIn={showModalMoveIn}
              deleteMultiData={deleteMultiData}
              deleteForEverMultiData={deleteForEverMultiData}
              setUploadingFile={setUploadingFile}
              navigateAddData={navigateAddData}
              currentProject={currentProject}
              allData={dataObject}
              setAllData={setDataObject}
            />
          </div>
        )}
        {!isLoading && templates && checkShowTemplate && (
          <Template templates={templates} />
        )}
        {selectedTab === 5 && !isLoading && (
          <TaskBoard
            boardID={boardID}
            newTask={newTask}
            dataObject={dataObject}
          />
        )}
        {dataObject.length > 0 &&
          recentLayer?.length !== 0 &&
          !isLoading &&
          selectedTab !== 4 &&
          selectedTab !== 6 &&
          selectedTab !== 5 && (
            <DashboardBody
              data={recentLayer}
              dashboardType="Recent"
              viewType={viewType}
              deletedScreen={selectedTab === 4}
              sharedScreen={false}
              checkedShared={sharedWithMe?.length !== 0}
              openBoard={openBoard}
              updateData={updateData}
              deleteData={deleteData}
              setData={setData}
              addData={addData}
              deleteForEverData={deleteForEverData}
              unDeleteData={undoDelete}
              changeOrder={changeOrder}
              currentProject={currentProject}
              reOrder={reOrder}
              duplicateCard={duplicateCard}
              isFocus={isFocus}
              setFocus={setFocus}
              searchContent={searchContent}
              RefreshLayer={refreshData}
              favHandler={favHandler}
              isLoading={toastInfo.isLoading}
              selectedItems={selectedItems}
              updateSelectedItems={updateSelectedItems}
              changedTab={changedTab}
              setChangedTab={setChangedTab}
              selectedTab={selectedTab}
              layerToShare={layerToShare}
              setToastInfo={setToastInfo}
              toastInfo={toastInfo}
              setLayerToShare={setLayerToShare}
              dataObject={dataObject}
              RootId={RootId}
              SharedNotification={SharedNotification}
              openProject={openProject}
            />
          )}
        {!checkShowTemplate &&
          !isLoading &&
          selectedTab !== 6 &&
          selectedTab !== 5 && (
            <DashboardBody
              data={getDataByLayerType('OBJECT')}
              dashboardType="All"
              viewType={viewType}
              deletedScreen={selectedTab === 4}
              sharedScreen={
                sharedWithMe?.length !== 0 && changedTab === 'sharedWithMe'
              }
              checkedShared={sharedWithMe?.length !== 0}
              openBoard={openBoard}
              updateData={updateData}
              deleteData={deleteData}
              setData={setData}
              addData={addData}
              deleteForEverData={deleteForEverData}
              unDeleteData={undoDelete}
              changeOrder={changeOrder}
              currentProject={currentProject}
              reOrder={reOrder}
              duplicateCard={duplicateCard}
              isFocus={isFocus}
              setFocus={setFocus}
              searchContent={searchContent}
              RefreshLayer={refreshData}
              favHandler={favHandler}
              isLoading={toastInfo.isLoading}
              selectedItems={selectedItems}
              updateSelectedItems={updateSelectedItems}
              changedTab={changedTab}
              setChangedTab={setChangedTab}
              selectedTab={selectedTab}
              layerToShare={layerToShare}
              toastInfo={toastInfo}
              setToastInfo={setToastInfo}
              setLayerToShare={setLayerToShare}
              showSharedTabs={RootId === currentProject?.id}
              dataObject={dataObject}
              SharedNotification={SharedNotification}
              uploadingFile={uploadingFile}
              openProject={openProject}
            />
          )}
        <AnimationSlide
          show={openAccountTab}
          openMenu={setOpenAccountTab}
          state="rightToLeft"
        >
          <Account setOpenAccountTab={setOpenAccountTab} tabNum={tab} />
        </AnimationSlide>
      </div>
      {(isLoading || loading || isLoadingData) && <Loading />}
      {toastInfo.text && (
        <Toast toastInfo={toastInfo} setToastInfo={setToastInfo} />
      )}
      {modalMoveInShown > 0 && (
        <MenuMoveIn
          selfItem={data.find((i) => i.id === selectedItems[0])}
          openModal={showModalMoveIn}
          moveTo={multiItemMoveToProject}
        />
      )}
      <AnimationSlide
        show={openThemeMenu}
        openMenu={setOpenThemeMenu}
        state="rightToLeft"
      >
        <ModalTheme
          setOpenThemeMenu={setOpenThemeMenu}
          setSelectedTheme={setSelectedTheme}
        />
      </AnimationSlide>
    </div>
  );
}

export default Dashboard;
