import { useEffect, useState } from 'react';

export default function useOutsideAlerter(ref1) {
  const [state, setState] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref1.current && !ref1.current.contains(event.target)) {
        setState(true);
      } else {
        setState(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref1]);
  return state;
}
