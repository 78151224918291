import { useState, useEffect } from 'react';

function useNetwork() {
  const [state, setState] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setState(true);
    };
    const handleOffline = () => {
      setState(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return state;
}
export default useNetwork;
