import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Elements/Loading';
import accountServices from '../services/accountServices';
import { DataContext } from '../context/DataContext';

function NotFound() {
  const { setSelectedTheme } = useContext(DataContext);
  const navigate = useNavigate();
  const development =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

  const user =
    localStorage.getItem('user') !== 'undefined' &&
    JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (development && window.location.pathname === '/accounts/login/') {
      window.location.href = 'https://dev-app.layer.cafe/accounts/login/';
      return;
    }
    if (user?.root?.id) {
      navigate(`/${user.root?.id}`);
    } else {
      accountServices.getUser().then((res) => {
        if (res.data?.root) {
          localStorage.setItem('user', JSON.stringify(res.data));
          setSelectedTheme(res.data.theme);
          navigate(`/${res?.data?.root?.id}`);
        } else window.location.href = '/accounts/login';
      });
    }
  }, []);

  return <Loading />;
}
export default NotFound;
