import { defaultProps } from '@blocknote/core';
import { createReactBlockSpec } from '@blocknote/react';
import TaskNoteNodeMain from './TaskNoteNodeMain';

const TaskNoteNode = createReactBlockSpec(
  {
    type: 'node',
    propSchema: {
      textAlignment: defaultProps.textAlignment,
      textColor: defaultProps.textColor,
      type: defaultProps,
    },
    content: 'none',
  },
  {
    render: (props) => {
      props.editor.domElement.addEventListener(
        'keydown',
        null,
        // (e) => cancelUndoCallback(e, props),
        true,
      );
      return (
        <div className="flex-row" style={{ outline: 'none', width: '100%' }}>
          <TaskNoteNodeMain props={props} />
        </div>
      );
    },
  },
);
export default TaskNoteNode;
