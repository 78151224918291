import { SvgSelector } from '../../helper/svg-selector';
import Avatar from './Avatar';

function MembersMenu({ info, deleteMember }) {
  return (
    <div className="member-card-menu">
      <Avatar size={40} name={info.email} />

      <div className="font-14-med member-card-name">
        {!info.first_name && !info.last_name
          ? 'NoName'
          : `${info.first_name}\u00A0${info.last_name}`}
      </div>
      <div className="font-12-reg member-card-email">{info.email}</div>
      <div
        className="font-14-reg flex-row hover cursor-pointer member-card-remove"
        onClick={(e) => {
          e.stopPropagation();
          deleteMember(info);
        }}
      >
        {SvgSelector('delete', 'icon-stroke-black')}
        <div className="member-card-remove-text"> Remove from board</div>
      </div>
    </div>
  );
}
export default MembersMenu;
