import { useState, useEffect } from 'react';
import { Emoji } from 'emoji-picker-react';
import { dashboardMainMenu } from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';
import CustomBoardEmoji from '../../assets/png/board-custom-icon.png';
import CustomProjectEmoji from '../../assets/png/project-custom-icon.png';
import './styles/sidebar.css';

function FavListGen({ type, setShowFav, showFav, favList, openLayer }) {
  return (
    <div>
      <div className="sidebar-h-divider" />
      <div className="sidebar-favorite-title-container">
        <div className="sidebar-favorite-title font-14-med">
          Favorite {type}
        </div>
        <div
          className="sidebar-favorite-title-icon cursor-pointer"
          onClick={() => setShowFav(!showFav)}
        >
          {SvgSelector(
            'chevronDown',
            `${!showFav ? 'sidebar-favorite-title-icon-up' : ''}`,
          )}
        </div>
      </div>
      {showFav &&
        favList?.map((item) => (
          <button
            type="button"
            key={item.id}
            onClick={() => openLayer(item)}
            className="sidebar-container-menu-items"
          >
            <div className="sidebar-container-menu-icon">
              <Emoji
                emojiUrl={
                  item.layer_type === 'PROJECT'
                    ? CustomProjectEmoji
                    : CustomBoardEmoji
                }
                unified={item.emoji || '1f4d8'}
                size="24"
              />
            </div>
            <div
              className="sidebar-container-menu-text font-14-med sidebar-favorite-section-title"
              title={item.title[0]?.text}
            >
              {item.title[0]?.text}
            </div>
          </button>
        ))}
    </div>
  );
}

function Sidebar({
  favoriteData,
  openBoard,
  SharedNotification,
  selectedTab,
  setSelectedTab,
  setLoading,
}) {
  const tabStorage = sessionStorage.getItem('dashBoardTab');

  const [showFavBoard, setShowFavBoard] = useState(true);
  const [showFavProject, setShowFavProject] = useState(true);

  const [favBoardsList, setFavBoards] = useState(favoriteData.boards);
  const [favProjectsList, setFavProjects] = useState(favoriteData.projects);

  useEffect(() => {
    if (tabStorage) {
      setSelectedTab(parseInt(tabStorage, 10));
    }
  }, []);

  useEffect(() => {
    setFavBoards(favoriteData.boards);
    setFavProjects(favoriteData.projects);
  }, [favoriteData]);

  const tabHandler = (id) => {
    if (id !== selectedTab && id !== 2) {
      setSelectedTab(id);
      sessionStorage.setItem('dashBoardTab', id);
      if (id !== 0 || id !== 6) {
        setLoading(false);
      } else setLoading(true);
    }
  };
  return (
    <div className="sidebar-container">
      {SvgSelector('layerIcon', 'sidebar-container-layer-icon')}
      <div className="sidebar-container-menu-upper">
        {dashboardMainMenu.map((item) => {
          if (item.id === 4) return null;
          return (
            <button
              type="button"
              key={item.id}
              onClick={() => tabHandler(item.id)}
              className={`sidebar-container-menu-items flex-jc-sb ${
                selectedTab === item.id
                  ? 'sidebar-container-menu-items-selected'
                  : ''
              }`}
            >
              <div className="flex-row">
                <div className="sidebar-container-menu-icon">
                  {SvgSelector(item.img)}
                </div>
                <div className="sidebar-container-menu-text font-14-med">
                  {item.name}
                </div>
              </div>
              {item.id === 3 && SharedNotification > 0 && (
                <div className="budget-shared font-x-small">
                  {Math.min(99, SharedNotification)}
                </div>
              )}
            </button>
          );
        })}
        {favBoardsList?.length > 0 && (
          <FavListGen
            type="Boards"
            favList={favBoardsList}
            showFav={showFavBoard}
            setShowFav={setShowFavBoard}
            openLayer={openBoard}
          />
        )}
        {favProjectsList?.length > 0 && (
          <FavListGen
            type="Projects"
            favList={favProjectsList}
            showFav={showFavProject}
            setShowFav={setShowFavProject}
            openLayer={openBoard}
          />
        )}
      </div>
      <button
        type="button"
        onClick={() => tabHandler(4)}
        className={`sidebar-container-menu-items-delete flex-jc-sb ${
          selectedTab === 4 ? 'sidebar-container-menu-items-selected' : ''
        }`}
      >
        <div className="flex-row">
          <div className="sidebar-container-menu-icon">
            {SvgSelector('delete')}
          </div>
          <div className="sidebar-container-menu-text font-14-med">Deleted</div>
        </div>
      </button>
    </div>
  );
}
export default Sidebar;
