/* eslint-disable prefer-destructuring */
import {
  useEffect,
  useState,
  createContext,
  useReducer,
  useCallback,
  useMemo,
} from 'react';

import * as Sentry from '@sentry/react';
import useSocket from '../hooks/useSocket';
import { redoHandler, undoHandler } from './undoRedoHandler';
import syncHandler from './syncHandler';

const queueData = {};
const undoQueue = [];
const redoQueue = [];
let lastReqId = 0;
let prjArr = {};
const initialState = {
  treeHistory: {},
  treeData: [],
  undoDelete: false,
  nav: ['part1', 'part2'],
  board: false,
  showComments: false,
  filterOptions: { statuses: [], colors: [], hideDone: [] },
  tempFilterOptions: { statuses: [], colors: [], hideDone: [] },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'delete':
      return {
        ...state,
        undoDelete: action.payload.status,
        treeHistory: action.payload.status ? action.payload.history : {},
      };
    case 'undoDelete':
      return { ...state, undoDelete: false, treeData: action.payload };
    case 'setNav':
      return { ...state, nav: action.payload };
    case 'setTree':
      return { ...state, treeData: action.payload };
    case 'setRoot':
      return { ...state, roots: action.payload };
    case 'boardToggle':
      return { ...state, board: action.payload };
    case 'showComment':
      return { ...state, showComments: action.payload };
    case 'setFilter':
      return { ...state, filterOptions: action.payload };
    case 'setTempFilter':
      return { ...state, tempFilterOptions: action.payload };
    // case "setStatusesFilter":
    // return { ...state, filterOptions: { ...state.filterOptions, statuses: action.payload } };
    // case "setNode":
    //   return { ...state, currNode: action.payload };
    default:
      throw new Error();
  }
};

// const initializer = (arg) => console.log('USER INITIALIZED.', arg);

export const DataContext = createContext();

export function DataProvider({ children }) {
  const { socket } = useSocket();
  const user = JSON.parse(localStorage.getItem('user'));
  const [value, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setLoading] = useState(false);
  const [currNode, setCurrNode] = useState(-1);
  const [projectArr, setProjectArr] = useState({});
  const [maxProjectWidth, setMaxProjectWidth] = useState(null);
  const [startNode, setStartNode] = useState();
  const [boardId, setBoardId] = useState();
  const [wsError, setWsError] = useState({ text: null, force: false });
  const [httpError, setHttpError] = useState({ text: null });
  const [refresh, setRefresh] = useState(0);
  const [expandStatus, setExpandStatus] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [shownAssignmentIcon, showAssignmentIcon] = useState(true);
  const [layers, setLayers] = useState();
  const [shownNote, showNote] = useState(0);
  const [shownContextMenu, showContextMenu] = useState(0);
  const [shownAssignmentMenu, showAssignmentMenu] = useState(0);
  const [userAccess, setUserAccess] = useState();
  const [treeMultiSelect, setTreeMultiSelect] = useState(false);
  const [treeMultiSelectByKey, setTreeMultiSelectByKey] = useState(false);
  const [treeMultiSelectList, setTreeMultiSelectList] = useState([]);
  const [refreshBoard, setRefreshBoard] = useState(false);
  const [loadingBoard, setLoadingBoard] = useState(false);
  const [togoMode, setTogoMode] = useState(null);
  const [openAccountTab, setOpenAccountTab] = useState(false);
  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(user?.theme || 'default');
  const [levelsMode, setLevelsMode] = useState(false);
  const [changedHandSkin, setChangedHandSkin] = useState(1);
  const [recentLayer, SetRecentLayer] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [blockParent, setBlockParent] = useState(null);
  const [taskFilter, setTaskFilter] = useState({
    status: [],
    tag: [],
    assignments: {},
  });
  const [isGroupByBoards, setIsGroupByBoards] = useState(
    JSON.parse(localStorage.getItem('groupByBoards')),
  );
  const [originData, setOriginData] = useState([]);
  const [focusModeKey, setFocusModeKey] = useState();
  const [isDraggingNode, setIsDraggingNode] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const event = e;
      if (!isLoading) return;
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isLoading]);

  useEffect(() => {
    if (!treeMultiSelect && !treeMultiSelectByKey) setTreeMultiSelectList([]);
  }, [treeMultiSelect, treeMultiSelectByKey]);

  useEffect(() => {
    setTreeMultiSelect(false);
  }, [togoMode]);

  const updateGroupBy = useCallback(() => {
    localStorage.setItem('groupByBoards', !isGroupByBoards);
    setIsGroupByBoards(!isGroupByBoards);
  }, [isGroupByBoards]);

  useEffect(() => {
    if (!treeMultiSelect) setTreeMultiSelectByKey(false);
  }, [treeMultiSelect]);

  const updateSelectedItems = useCallback(
    (id) => {
      if (id === null) {
        setTreeMultiSelectList([]);
        return;
      }
      let tempSelectedItems = [...treeMultiSelectList];
      const findIndex = tempSelectedItems.findIndex((item) => item === id);
      if (findIndex === -1) {
        tempSelectedItems.push(id);
      } else {
        tempSelectedItems = tempSelectedItems.filter((item) => item !== id);
      }
      setTreeMultiSelectList(tempSelectedItems);
    },
    [treeMultiSelectList],
  );

  const errorHandler = (errorObj) => {
    setHttpError({
      text: errorObj.message,
    });
  };

  const syncData = () => {
    syncHandler(queueData, syncDataFinish, errorHandler);
  };

  const updatePrjArr = useCallback((items) => {
    prjArr = items;
  }, []);

  function getKeyByValue(object, val) {
    return Object.keys(object).find((key) => object[key] === val);
  }
  const saveMaxPrjWidth = (v) => {
    localStorage.setItem('MaxPrjWidth', v);
    localStorage.setItem('ProjectArray', JSON.stringify(projectArr));
  };

  const updateTree = useCallback(
    (property, item, tree, undoRedo, n) => {
      const tempData = tree || value.treeData;
      const nodeToChange = n || currNode;
      tempData[nodeToChange][property] = item;
      const payload = {
        id: nodeToChange,
        [property]: item,
      };
      // const statusPayload = {
      //   id: nodeToChange,
      //   status: item,
      //   layer_type: 'ASSIGNMENT',
      // };
      if (togoMode && property === 'status' && item !== 2) {
        if (tempData[tempData[nodeToChange].parent]?.layer_type === 'BOARD') {
          if (!tempData[nodeToChange]?.order?.length > 0) {
            delete tempData[nodeToChange];
          }
        } else {
          tempData[tempData[nodeToChange].parent].order = tempData[
            tempData[nodeToChange].parent
          ].order.filter((i) => i !== nodeToChange);
          if (
            tempData[tempData[nodeToChange].parent].order?.length === 0 &&
            tempData[tempData[nodeToChange].parent].status !== 2
          )
            delete tempData[tempData[nodeToChange].parent];
          delete tempData[nodeToChange];
        }
      }
      if (Object.values(tempData).length === 1) setTogoMode(false);
      addToQueue('update', payload, undoRedo);
      dispatch({ type: 'setTree', payload: tempData });
      setRefresh((old) => old + 1);
    },
    [currNode, userAccess, togoMode, value.treeData],
  );

  const updateTreeMulti = useCallback(
    (ids, type, property) => {
      addToQueue('multiPatch', { ids, type, property });
    },
    [userAccess],
  );

  const updateNote = useCallback(
    (notes, id, tree) => {
      const tempData = tree;
      tempData[currNode].has_note = !!notes;
      tempData[currNode].note = notes;
      addToQueue('noteUpdate', { id, data: notes });
      // dispatch({ type: "setTree", payload: tempData });
    },
    [currNode],
  );

  const assignUserToTask = useCallback(
    (layerIdsToAssign, userToAssign) => {
      const tempData = value.treeData;
      layerIdsToAssign.forEach((id) => {
        const duplicateUser = tempData[id].assignments?.find(
          (a) => a.id === userToAssign.id,
        );
        tempData[id].assignments = tempData[id]?.assignments || [];
        if (prjArr[id] && tempData[id]?.assignments?.length < 2) {
          calculateProjectsWidth(id, prjArr[id] + 30);
        }
        if (!duplicateUser) {
          tempData[id].assignments = tempData[id]?.assignments || [];
          tempData[id].assignments = [
            ...tempData[id].assignments,
            userToAssign,
          ];
        }
      });
      dispatch({ type: 'setTree', payload: tempData });
      addToQueue('assignment', { layerIdsToAssign, userToAssign });
      setRefresh((old) => old + 1);
    },
    [value.treeData, userAccess, prjArr],
  );
  const unAssignUserToTask = useCallback(
    (layerToUnAssign, userToUnAssign) => {
      const tempData = value.treeData;
      if (
        prjArr[layerToUnAssign.id] &&
        tempData[layerToUnAssign.id].assignments?.length < 3
      ) {
        calculateProjectsWidth(
          layerToUnAssign.id,
          prjArr[layerToUnAssign.id] - 30,
        );
      }
      tempData[layerToUnAssign.id].assignments = tempData[
        layerToUnAssign.id
      ].assignments?.filter(
        (assignment) => assignment.id !== userToUnAssign.id,
      );

      dispatch({ type: 'setTree', payload: tempData });
      addToQueue('unAssignment', {
        layerToUnAssignId: layerToUnAssign.id,
        userToUnAssign,
      });
      setRefresh((old) => old + 1);
    },
    [value.treeData, userAccess],
  );

  const delChild = (board, n) => {
    const tempData = value.treeData;
    const nodeParent = tempData[n]?.parent;
    const isProject = tempData[n]?.parent === board;
    tempData[nodeParent].order = tempData[nodeParent].order.filter(
      (i) => i !== n,
    );

    if (isProject) {
      const tempProject = JSON.parse(JSON.stringify(prjArr));
      delete tempProject[n];
      updatePrjArr(tempProject);
      calculateProjectsWidth();
    }

    // delete tempData[n];
    dispatch({ type: 'setTree', payload: tempData });
    setRefresh((old) => old + 1);
  };

  const calculateProjectsWidth = useCallback((node, projectWidth) => {
    if (node) prjArr = { ...prjArr, [node]: projectWidth };
    if (!prjArr || prjArr?.length === 0) return;
    const arr = Object.values(prjArr)?.map((v) => v);
    const maxVal = Math.max(...arr);
    const maxKey = getKeyByValue(prjArr, maxVal);
    setMaxProjectWidth([maxKey, maxVal]);
    setProjectArr(prjArr);
    if (maxKey) saveMaxPrjWidth([maxKey, maxVal]);
  }, []);

  const selectNodeHandler = useCallback(
    (node, tree) => {
      if (!node) {
        dispatch({ type: 'setNav', payload: ['', ''] });
        return;
      }
      setCurrNode(node);
      let currentNode = tree[node];
      let labels = [
        {
          node: currentNode.id,
          title: currentNode.title,
          emoji: currentNode.emoji,
        },
      ];
      while (currentNode.id !== boardId) {
        currentNode = tree[currentNode.parent];
        labels = [
          ...labels,
          {
            key: currentNode.id,
            node: currentNode.id,
            title: currentNode.title,
            emoji: currentNode.emoji,
          },
        ];
      }
      dispatch({ type: 'setNav', payload: labels.reverse() });
    },
    [boardId],
  );

  const expandAll = useCallback(() => {
    const tempData = value.treeData;
    Object.keys(tempData).map((k) => {
      if (
        !tempData[tempData[k].parent] ||
        tempData[k].id === startNode ||
        tempData[tempData[k].parent].id === boardId
      )
        tempData[k].collapse = !expandStatus;
      return tempData[k].collapse;
    });

    setExpandStatus((old) => !old);
    dispatch({ type: 'setTree', payload: tempData });
    if (userAccess === 'WRITE')
      addToQueue('collapse', {
        id: boardId,
        data: { collapse: !expandStatus },
      });
  }, [value.treeData, expandStatus, boardId, startNode]);

  const syncDataFinish = (reqId) => {
    delete queueData[reqId];
    if (Object.keys(queueData).length === 0) {
      setLoading(false);
    } else {
      syncData();
    }
  };

  const addToQueue = useCallback(
    (type, obj, undoRedo = false) => {
      const objKeys = Object.keys(obj);
      setRefresh((old) => old + 1);
      let reqId = new Date().getTime();
      if (reqId <= lastReqId) reqId = lastReqId + 1;
      lastReqId = reqId;
      if (userAccess !== 'WRITE') return;
      if (!undoRedo) {
        redoQueue.length = 0;
        if (['create', 'delete'].includes(type)) {
          undoQueue.push({ type, obj });
        } else if (type === 'update') {
          // eslint-disable-next-line no-unused-vars
          const [id, ...changeItem] = objKeys;
          if (changeItem.includes('order')) {
            undoQueue.push({ type: 'move', obj });
          } else if (
            ['title', 'color', 'emoji', 'status'].includes(changeItem[0])
          ) {
            undoQueue.push({
              type,
              obj: {
                changeItem,
                id: obj.id,
                oldValue: value.treeData[obj.id][changeItem],
                newValue: obj[changeItem],
              },
            });
          }
        } else if (type === 'copy') {
          undoQueue.push({ type, obj: { ...obj, idx: obj.idx } });
        } else if (type === 'move') {
          undoQueue.push({ type: 'move', obj });
        }
      }
      setLoading(true);
      setCtrlKeyPressed(false);
      Object.assign(queueData, { [reqId]: { type, obj, undoRedo } });
    },
    [userAccess, value.treeData, refresh],
  );

  const addChild = (node, changes, board, isUndo = false) => {
    const tempData = value.treeData;
    const parent = changes.parent;
    const previous = changes.previous;
    let parentOrder = tempData[parent].order;
    const newChild = isUndo
      ? changes
      : {
          id: node,
          title: '',
          parent,
          previous,
          previous_id: previous,
          comments_count: 0,
          layer_type: 'TASK',
          color: changes.parent === board ? 'PINK' : 'BLUE',
          expansion: tempData[parent].expansion,
          status: 'NO_STATUS',
        };
    if (parentOrder?.length > 0) parentOrder.push(node);
    else parentOrder = [node];
    tempData[parent].order = parentOrder;
    tempData[node] = newChild;
    dispatch({ type: 'setTree', payload: tempData });
  };

  const relocate = (node, changes) => {
    const tempData = value.treeData;
    const parent = changes.parent;
    const idx = changes.index;
    tempData[node].parent = parent;
    let parentOrder = tempData[parent].order;
    const oldParent = changes.old_parent;
    tempData[oldParent].order = tempData[oldParent].order.filter(
      (i) => i !== node,
    );
    if (parentOrder?.length > 0) parentOrder.splice(idx, 0, node);
    else parentOrder = [node];
    tempData[parent].order = parentOrder;
    dispatch({ type: 'setTree', payload: tempData });
    setRefresh((old) => old + 1);
  };

  const relocateDnd = (parent, changes) => {
    const tempData = value.treeData;
    const olderOrder = tempData[parent].order || [];
    const relocatedNode = changes.filter((n) => !olderOrder.includes(n))[0];
    if (relocatedNode) {
      const oldParent = tempData[relocatedNode].parent;
      tempData[oldParent].order = tempData[oldParent].order.filter(
        (i) => i !== relocatedNode,
      );
      tempData[relocatedNode].parent = parent;
    }
    tempData[parent].order = changes;
    dispatch({ type: 'setTree', payload: tempData });
    setRefresh((old) => old + 1);
  };

  const otherUserChanges = useCallback(
    (obj) => {
      const { task } = obj;
      const operation = task.action.toLowerCase();
      let tempData = value.treeData;
      if (operation === 'update') {
        const changes = Object.entries(task.changes)[0];
        const hasNoteProperty = Object.prototype.hasOwnProperty.call(
          task.changes,
          'has_note',
        );
        const hasOrderProperty = Object.prototype.hasOwnProperty.call(
          task.changes,
          'order',
        );
        if (hasOrderProperty) {
          relocateDnd(task.task_id, task.changes.order);
        } else if (hasNoteProperty) {
          tempData[task.task_id].has_note = task.changes.has_note;
        } else {
          tempData[task.task_id][changes[0]] = changes[1];
        }
        dispatch({ type: 'setTree', payload: tempData });
      } else if (operation === 'create') {
        addChild(task.task_id, task.changes, task.board);
      } else if (operation === 'copy') {
        task.changes.tasks.map((child) => {
          tempData = { ...tempData, [child.id]: child };
          tempData[child.id].parent = child.parent_id;
          tempData[child.id].previous = child.previous_id;
          tempData[child.id].order = child.order;
          if (tempData[task.changes.previous_id].parent === child.parent) {
            tempData[tempData[task.changes.previous_id].parent].order =
              tempData[tempData[task.changes.previous_id].parent].order || [];
            const order =
              tempData[tempData[task.changes.previous_id].parent].order;
            const idx = order.indexOf(task.changes.previous_id);
            tempData[tempData[task.changes.previous_id].parent].order.splice(
              idx + 1,
              0,
              child.id,
            );
          }
          return tempData;
        });
        dispatch({ type: 'setTree', payload: tempData });
        setRefresh((old) => old + 1);
      } else if (operation === 'relocate') {
        relocate(task.task_id, task.changes);
      } else if (operation === 'delete') {
        delChild(task.board, task.task_id);
      } else if (operation === 'undelete') {
        tempData[tempData[task.task_id].parent].order.push(task.task_id);
        dispatch({ type: 'setTree', payload: tempData });
        setRefresh((old) => old + 1);
      } else if (operation === 'assign') {
        tempData[task.task_id].assignments =
          tempData[task.task_id].assignments || [];
        tempData[task.task_id].assignments.push(task.changes.member);
        dispatch({ type: 'setTree', payload: tempData });
        setRefresh((old) => old + 1);
      } else if (operation === 'unassign') {
        tempData[task.task_id].assignments = tempData[
          task.task_id
        ].assignments.filter(
          (assignment) => assignment.id !== task.changes.member.id,
        );
        dispatch({ type: 'setTree', payload: tempData });
        setRefresh((old) => old + 1);
      } else if (operation === 'add comment') {
        if (!tempData[task.task_id]) return;
        tempData[task.task_id].comments_count =
          tempData[task.task_id].comments_count || [];
        tempData[task.task_id].comments_count += 1;
        dispatch({ type: 'setTree', payload: tempData });
        setRefresh((old) => old + 1);
      } else console.log('task', operation, task);
      // setRefresh((old) => !old);
    },
    [value.treeData],
  );

  useEffect(() => {
    setMaxProjectWidth(localStorage.getItem('MaxPrjWidth'));
    setProjectArr(JSON.parse(localStorage.getItem('ProjectArray')));
    // setProjectArr({});
    prjArr = {};
  }, []);

  // refresh
  useEffect(() => {
    setRefresh((old) => old + 1);
  }, [
    maxProjectWidth,
    shownAssignmentIcon,
    treeMultiSelect,
    treeMultiSelectByKey,
  ]);

  useEffect(() => {
    if (!layers) return;
    const initialValue = layers;
    setExpandStatus(layers[boardId]?.collapse);
    if (initialValue) dispatch({ type: 'setTree', payload: initialValue });
  }, [layers]);

  useEffect(() => {
    if (isLoading && socket.readyState === socket.OPEN) syncData();
  }, [isLoading, socket.readyState]);

  const queueLogger = useCallback(() => {
    console.log('queue: ', JSON.stringify(queueData));
    Sentry.captureMessage('Queue logger');
  }, []);

  const onMessage = useCallback(
    (message) => {
      const res = JSON.parse(message?.data);
      // handle errors
      if (res.response_status === 401) {
        // window.location.href = '/accounts/login/';
      }
      if (res?.errors?.length > 0) {
        if (
          res.response_status === 400 &&
          ['check_conn', 'create', 'duplicate'].includes(res.action)
        )
          return;
        console.log('Error on queue: ', res.errors[0]);
        console.log('Error object: ', res);
        Sentry.captureMessage('error on queue');
        let error = res.errors;
        if (typeof error[0] === 'object')
          error = Object.values(res.errors[0])[0][0];
        else if (Array.isArray(error)) error = error[0];
        if (res.action === 'change_password') {
          setWsError({ text: error, force: false });
          setLoading(false);
        } else if (
          res.action.match(
            /^(delete|set_note|patch|create|multi_delete|multi_patch|assign|unassign)$/,
          )
        ) {
          setWsError({ text: error, force: true });
          setLoading(false);
        } else if (res.action.match(/^(relocate|multi_relocate|duplicate)$/)) {
          setLoadingBoard(false);
          setWsError({ text: error, force: false });
        } else {
          setWsError({ text: error, force: true });
          setLoading(false);
        }
        return;
      }
      if (Number(res.request_id) in queueData) {
        syncDataFinish(res.request_id);
      }
      //  else if (
      //   res.action !== 'subscribed' &&
      //   Object.keys(queueData)?.length > 0
      // ) {
      //   console.log('Queue length:', Object.keys(queueData)?.length);
      //   console.log('Something went wrong', res);
      //   Sentry.captureMessage('Something went wrong');
      // }
      const tempData = value.treeData;
      let layerName;
      switch (res.action) {
        case 'duplicate':
          if (Array.isArray(tempData)) {
            layerName = tempData.find(
              (item) => item.id === res.data.changes.old_layer,
            )?.title;
          } else {
            layerName = tempData[res.data?.changes?.old_layer]?.title;
          }
          setWsError({
            text: `${layerName} has been copied`,
            force: false,
            persist: false,
          });
          break;
        case 'relocate':
          if (Array.isArray(tempData)) {
            layerName = tempData.find(
              (item) => item.id === res.data.layer_id,
            )?.title;
          } else {
            layerName = tempData[res.data.layer_id].title;
          }
          setWsError({
            text: `${layerName} has been moved`,
            force: false,
            persist: false,
          });
          break;
        case 'multi_relocate':
          setWsError({
            text: `Some items has been moved`,
            force: false,
            persist: false,
          });
          break;
        case 'subscribed':
          if (!user?.id || user?.id === res.data.task.user) break;
          otherUserChanges(res.data);
          break;
        default:
          break;
      }
    },
    [value.treeData, socket],
  );
  const undoAction = useCallback(() => {
    undoHandler(
      undoQueue,
      redoQueue,
      addToQueue,
      delChild,
      addChild,
      boardId,
      updateTree,
      value,
      dispatch,
    );
  }, [addToQueue, delChild, addChild, boardId, updateTree, value, dispatch]);
  const redoAction = useCallback(() => {
    redoHandler(
      undoQueue,
      redoQueue,
      addToQueue,
      delChild,
      addChild,
      boardId,
      updateTree,
      value,
      dispatch,
    );
  }, [addToQueue, delChild, addChild, boardId, updateTree, value, dispatch]);

  useEffect(() => {
    socket.addEventListener('message', onMessage);

    return () => {
      socket.removeEventListener('message', onMessage);
    };
  }, [socket, onMessage]);

  const contextValue = useMemo(
    () => ({
      ...value,
      dispatch,
      updateTree,
      updateNote,
      setCurrNode,
      selectNodeHandler,
      isLoading,
      projectArr,
      setProjectArr,
      maxProjectWidth,
      startNode,
      setStartNode,
      setBoardId,
      expandAll,
      expandStatus,
      refresh,
      setRefresh,
      addToQueue,
      setLayers,
      layers,
      userAccess,
      setUserAccess,
      calculateProjectsWidth,
      shownNote,
      showNote,
      shownAssignmentMenu,
      showAssignmentMenu,
      updatePrjArr,
      inviteModal,
      setInviteModal,
      refreshBoard,
      setRefreshBoard,
      loadingBoard,
      setLoadingBoard,
      wsError,
      setWsError,
      httpError,
      setHttpError,
      shownAssignmentIcon,
      showAssignmentIcon,
      treeMultiSelect,
      setTreeMultiSelect,
      treeMultiSelectList,
      updateSelectedItems,
      assignUserToTask,
      unAssignUserToTask,
      updateTreeMulti,
      togoMode,
      setTogoMode,
      queueLogger,
      boardId,
      setOpenAccountTab,
      openAccountTab,
      ctrlKeyPressed,
      setCtrlKeyPressed,
      treeMultiSelectByKey,
      setTreeMultiSelectByKey,
      shownContextMenu,
      showContextMenu,
      selectedTheme,
      setSelectedTheme,
      levelsMode,
      setLevelsMode,
      changedHandSkin,
      setChangedHandSkin,
      undoAction,
      redoAction,
      recentLayer,
      SetRecentLayer,
      tasks,
      setTasks,
      blockParent,
      setBlockParent,
      taskFilter,
      setTaskFilter,
      isGroupByBoards,
      updateGroupBy,
      setOriginData,
      originData,
      setFocusModeKey,
      focusModeKey,
      isDraggingNode,
      setIsDraggingNode,
    }),
    [
      value,
      dispatch,
      updateTree,
      updateNote,
      setCurrNode,
      selectNodeHandler,
      isLoading,
      projectArr,
      setProjectArr,
      maxProjectWidth,
      startNode,
      setStartNode,
      setBoardId,
      expandAll,
      expandStatus,
      refresh,
      setRefresh,
      addToQueue,
      setLayers,
      layers,
      userAccess,
      setUserAccess,
      calculateProjectsWidth,
      shownNote,
      showNote,
      updatePrjArr,
      inviteModal,
      setInviteModal,
      refreshBoard,
      setRefreshBoard,
      loadingBoard,
      setLoadingBoard,
      wsError,
      setWsError,
      httpError,
      setHttpError,
      shownAssignmentMenu,
      shownAssignmentIcon,
      showAssignmentIcon,
      treeMultiSelect,
      setTreeMultiSelect,
      treeMultiSelectList,
      updateSelectedItems,
      assignUserToTask,
      unAssignUserToTask,
      updateTreeMulti,
      togoMode,
      setTogoMode,
      queueLogger,
      boardId,
      setOpenAccountTab,
      openAccountTab,
      ctrlKeyPressed,
      setCtrlKeyPressed,
      treeMultiSelectByKey,
      setTreeMultiSelectByKey,
      shownContextMenu,
      showContextMenu,
      selectedTheme,
      setSelectedTheme,
      levelsMode,
      setLevelsMode,
      changedHandSkin,
      setChangedHandSkin,
      undoAction,
      redoAction,
      recentLayer,
      SetRecentLayer,
      tasks,
      setTasks,
      blockParent,
      setBlockParent,
      taskFilter,
      setTaskFilter,
      isGroupByBoards,
      updateGroupBy,
      setOriginData,
      originData,
      setFocusModeKey,
      focusModeKey,
      isDraggingNode,
      setIsDraggingNode,
    ],
  );

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
}
