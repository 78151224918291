import { useEffect, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/taskBoard.scss';
import AnimationSlide from '../Elements/AnimationSlide';
import MenuSelectBoard from '../Elements/MenuSelectBoard';
import StatusMenu from '../Elements/StatusMenu';
import MenuSelectMember from '../Elements/MenuSelectMember';
import dataServices from '../../services/dataServices';
import { taskBoardFilter } from '../../constants/arrays';
import Calendar from '../Calendar';
import FilterTag from '../Elements/FilterTag';
import { deadlineCal } from '../../helper/functions';

function TaskBoardHeader({ checkMembers, setTaskFilter, taskFilter }) {
  const [openMenu, setOpenMenu] = useState('');
  const [membersList, setMembersList] = useState([]);

  const getAllMembers = () => {
    dataServices.getAllAssignments().then((res) => setMembersList(res.data));
  };

  useEffect(() => {
    getAllMembers();
  }, [checkMembers]);

  const onClickFilter = (filter) => {
    setOpenMenu(filter);
  };

  const selectFilterBoard = (id) => {
    setTaskFilter((prev) => ({
      ...prev,
      board_id: [...prev.board_id, id],
    }));
  };

  const selectFilterStatus = (status) => {
    setTaskFilter((prev) => {
      if (prev.status.includes(status.value)) {
        return prev;
      }
      return {
        ...prev,
        status: [...prev.status, status.value],
      };
    });
  };

  const selectFilterAssign = (info) => {
    if (taskFilter.assignments.userNames) {
      setTaskFilter((prev) => {
        if (prev.assignments.userId.includes(info.id)) {
          return prev;
        }
        return {
          ...prev,
          assignments: {
            ...prev.assignments,
            userId: [...prev.assignments.userId, info.id],
            userNames: [...prev.assignments.userId, info.id],
            userEmail: [...prev.assignments.userEmail, info.email],
            display_userNames: [...prev.assignments.userEmail, info.email],
          },
        };
      });
    } else {
      setTaskFilter((prev) => ({
        ...prev,
        assignments: {
          ...prev.assignments,
          userId: [info.id],
          userNames: [info.id],
          userEmail: [info.email],
          display_userNames: [info.email],
        },
      }));
    }
  };

  const selectFilterDeadLine = (tStartDate, tDueDate) => {
    let startTemp;
    startTemp = tStartDate;
    if (tDueDate && !tStartDate) {
      startTemp = tDueDate;

      const givenDate = new Date(startTemp);
      const lastDate = new Date(givenDate.getTime() - 24 * 60 * 60 * 1000);
      const lastSameDate = lastDate.toISOString();
      const newDate = new Date(givenDate.getTime());
      const sameDate = newDate.toISOString();

      setTaskFilter((prev) => ({
        ...prev,
        due_date: {
          ...prev.due_date,
          due_timeGt: [lastSameDate],
          due_timeIt: [sameDate],
          display_date: [
            startTemp ? deadlineCal(tDueDate)[0] : '',
            tDueDate ? deadlineCal(sameDate)[0] : '',
          ],
        },
      }));
    }

    if (tDueDate && tStartDate) {
      setTaskFilter((prev) => ({
        ...prev,
        due_date: {
          ...prev.due_date,
          due_timeGt: [tStartDate],
          due_timeIt: [tDueDate],
          display_date: [
            startTemp ? deadlineCal(tStartDate)[0] : '',
            tDueDate ? deadlineCal(tDueDate)[0] : '',
          ],
        },
      }));
    }
    setOpenMenu('');
  };

  const selectFilterTag = (tag) => {
    setTaskFilter((prev) => {
      if (prev.tag.includes(tag.setColor)) {
        return prev;
      }
      return {
        ...prev,
        tag: [...prev.tag, tag.setColor],
      };
    });
  };

  return (
    <div>
      <div className="task-board-header-container">
        {taskBoardFilter.map((i) => (
          <div style={{ width: i.grid }}>
            <div
              className="font-14-med task-board-header-item cursor-pointer tooltip"
              onClick={() => onClickFilter(i.text)}
            >
              {i.text}
              {SvgSelector('chevronDown', 'task-board-header-item-icon')}
              <span className={`tooltiptext font-10-med ${i.tooltipClass}`}>
                {i.tooltiptext}
              </span>
              {i.id === 2 ? (
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'Boards'}
                  state="topToDown"
                >
                  <div className="select-board-filter-pos">
                    <MenuSelectBoard
                      selectBoard={selectFilterBoard}
                      filterId={i.id}
                    />
                  </div>
                </AnimationSlide>
              ) : i.id === 3 ? (
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'Deadline'}
                  state="topToDown"
                >
                  <div className="select-date-filter-pos">
                    <Calendar
                      toggle={() => setOpenMenu('')}
                      callback={selectFilterDeadLine}
                      type="filter"
                    />
                  </div>
                </AnimationSlide>
              ) : i.id === 4 ? (
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'Status'}
                  state="topToDown"
                >
                  <div className="select-status-filter-pos">
                    <StatusMenu handleClickItem={selectFilterStatus} />
                  </div>
                </AnimationSlide>
              ) : i.id === 5 ? (
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'Tags'}
                  state="topToDown"
                >
                  <div className="select-tags-filter-pos">
                    <FilterTag onSelectItem={selectFilterTag} />
                  </div>
                </AnimationSlide>
              ) : i.id === 6 ? (
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'Members'}
                  state="topToDown"
                >
                  <div className="select-members-filter-pos">
                    <MenuSelectMember
                      data={membersList}
                      setFilterTask={selectFilterAssign}
                    />
                  </div>
                </AnimationSlide>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TaskBoardHeader;
