import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/template.scss';
import ControlTabs from '../Elements/ControlTabs';
import { templateTabs } from '../../constants/arrays';
import CardTemplate from '../Elements/CardTemplate';
import { SvgSelector } from '../../helper/svg-selector';

const displayLimit = 8;

function Template(props) {
  const { templates } = props;
  const [singleCategoryTemplates, setSingleCategoryTemplates] = useState([]);
  const [templateCategory, setTemplateCategory] = useState('all');
  const [showedMore, setShowedMore] = useState(displayLimit);
  const nav = useNavigate();

  const clickHandler = (tempID) => nav(`/${tempID}`);

  const displaySingleCategory = (tag) => {
    const upperCasedTag = tag.toUpperCase();
    setSingleCategoryTemplates(
      templateCategory === 'all'
        ? templates
        : templates.filter((i) => i.tag === upperCasedTag),
    );
  };

  useEffect(() => {
    displaySingleCategory(templateCategory);
    setShowedMore(displayLimit);
  }, [templateCategory]);

  if (templates.length === 0)
    return (
      <div className="template-container">
        <div className="font-18-bold">
          There&apos;s no templates available /O_O\
        </div>
      </div>
    );
  return (
    <div className="template-container">
      <div className="font-18-bold">{templates.length || 0} Templates</div>
      <ControlTabs
        changeTab={templateCategory}
        listTab={templateTabs}
        setChangeTab={setTemplateCategory}
      />
      <div className="template-card-wrapper">
        {templates &&
        templateCategory &&
        singleCategoryTemplates?.length > 0 ? (
          singleCategoryTemplates
            .slice(0, showedMore)
            .map((i) => (
              <CardTemplate
                key={i?.id}
                templateData={i}
                clickHandler={clickHandler}
              />
            ))
        ) : (
          <div className="template-no-template-wrapper">
            {SvgSelector('happyMan')}
            <span className="no-template-title">
              There&apos;s no
              {templateCategory !== 'all' && ` ${templateCategory} `}
              template available right now!
            </span>
          </div>
        )}
      </div>
      {singleCategoryTemplates?.length !== 0 &&
      showedMore !== singleCategoryTemplates?.length ? (
        <div className="template-more-container">
          <div
            className="template-more font-14-bold cursor-pointer"
            onClick={() => setShowedMore(singleCategoryTemplates?.length)}
          >
            Show more
          </div>
        </div>
      ) : (
        showedMore === singleCategoryTemplates?.length && (
          <div className="template-more-container">
            <div
              className="template-more font-14-bold cursor-pointer"
              onClick={() => setShowedMore(displayLimit)}
            >
              Show less
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default Template;
