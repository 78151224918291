import dataServices from '../services/dataServices';

export default function syncHandler(queueData, syncDataFinish, errorHandler) {
  if (Object.keys(queueData).length === 0) return;
  const item = Object.keys(queueData)[0];
  if (queueData[item].type === 'create') {
    const { order, ...objToSend } = queueData[item].obj;
    if (queueData[item].undoRedo)
      dataServices
        .unDeleteLayer(queueData[item].obj.id)
        .then(() => syncDataFinish(item))
        .catch((err) => errorHandler(err));
    else
      dataServices
        .addLayers(objToSend)
        .then(() => syncDataFinish(item))
        .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'upsert') {
    dataServices
      .addBlock(queueData[item].obj)
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'update') {
    dataServices
      .updateLayers(queueData[item].obj.id, queueData[item].obj)
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'moveAll') {
    dataServices
      .relocateLayers({
        pks: [queueData[item].obj.node],
        parent_id: queueData[item].obj.newParent,
      })
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'move') {
    dataServices
      .relocate(queueData[item].obj.id, {
        parent: queueData[item].obj.parent,
        previous: queueData[item].obj.previous,
      })
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'copy') {
    dataServices
      .duplicateLayer(queueData[item].obj.node, {
        pk: queueData[item].obj.data.pk,
        parent: queueData[item].obj.newParent,
        layers: queueData[item].obj.data.layers,
      })
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'noteUpdate') {
    // todo
    // send(
    //   JSON.stringify({
    //     action: 'set_note',
    //     request_id: item,
    //     pk: queueData[item].obj.id,
    //     data: { note: queueData[item].obj.data },
    //   }),
    // );
  } else if (queueData[item].type === 'delete') {
    dataServices
      .deleteLayer(queueData[item].obj.id)
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'multiDelete') {
    dataServices
      .deleteMultiLayers({ pks: queueData[item].obj.ids })
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'multiPatch') {
    dataServices
      .updateMultiLayers({
        pks: queueData[item].obj.ids,
        data: { [queueData[item].obj.type]: queueData[item].obj.property },
      })
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'assignment') {
    dataServices
      .assignMultiTasks({
        pks: queueData[item].obj.layerIdsToAssign,
        data: {
          member: queueData[item].obj.userToAssign.id,
          assignment: true,
          privilege: 'READ',
        },
      })
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'unAssignment') {
    dataServices
      .assignMultiTasks({
        pks: [queueData[item].obj.layerToUnAssignId],
        data: {
          member: queueData[item].obj.userToUnAssign.id,
          assignment: false,
          // privilege: 'NO_ACCESS',
        },
      })
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'addComment') {
    dataServices
      .addComment(queueData[item].obj.pk, queueData[item].obj.data)
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'likeComment') {
    dataServices
      .likeComment(
        queueData[item].obj.layer,
        queueData[item].obj.comment,
        queueData[item].obj.data,
      )
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'editComment') {
    dataServices
      .editComment(
        queueData[item].obj.layer,
        queueData[item].obj.comment,
        queueData[item].obj.data,
      )
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'deleteComment') {
    dataServices
      .deleteComment(queueData[item].obj.layer, queueData[item].obj.comment)
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'collapse') {
    dataServices
      .collapseLayer(queueData[item].obj.id, queueData[item].obj.data)
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'expand') {
    dataServices
      .expandLayer(queueData[item].obj.id, queueData[item].obj.data)
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  } else if (queueData[item].type === 'bulk') {
    dataServices
      .bulkCreate(queueData[item].obj)
      .then(() => syncDataFinish(item))
      .catch((err) => errorHandler(err));
  }
}
