import { useEffect, useRef } from 'react';

function useOutsideAlerter(ref, openMenu, numeral) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        openMenu(numeral ? 0 : false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function Menu({
  openMenu,
  numeral = false,
  includeDiv,
  children,
  styles,
  menuClassName,
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, openMenu, numeral, includeDiv);
  return (
    <div ref={wrapperRef} style={{ ...styles }} className={menuClassName}>
      {children}
    </div>
  );
}
export default Menu;
