import { useContext, useState, useEffect } from 'react';
import '../../styles/floatMenu.css';
import EmojiPicker from '../Elements/EmojiPicker';
import { SvgSelector } from '../../helper/svg-selector';
import { verticalMenu } from '../../constants/arrays';
import { DataContext } from '../../context/DataContext';
import VerticalMenuAssignment from '../Elements/VerticalMenuAssignment';
import AnimationSlide from '../Elements/AnimationSlide';
import Calendar from '../Calendar';
import StatusMenu from '../StatusMenu';
import TagMenu from '../Elements/TagMenu';
import dataServices from '../../services/dataServices';

export default function VerticalMenu(props) {
  const { status, stepExpand, setToastInfo } = props;
  const [openedMenu, openMenu] = useState(-1);
  const {
    dispatch,
    updateTreeMulti,
    treeData,
    treeMultiSelectList,
    setTreeMultiSelect,
    setTreeMultiSelectByKey,
    addToQueue,
    projectArr,
    updatePrjArr,
    calculateProjectsWidth,
  } = useContext(DataContext);

  useEffect(() => {
    if (!treeMultiSelectList.length > 0) openMenu(-1);
  }, [treeMultiSelectList]);

  const changeHandler = (type, item) => {
    const tempData = treeData;
    treeMultiSelectList.forEach((i) => {
      tempData[i][type] = item;
    });
    dispatch({ type: 'setTree', payload: tempData });
    updateTreeMulti(treeMultiSelectList, type, item);
    setTreeMultiSelect(false);
    openMenu(-1);
  };

  const multiSelectDeadLine = (tStartDate, tDueDate, tReminder, hasTime) => {
    const tempData = treeData;
    treeMultiSelectList.forEach((i) => {
      tempData[i].start_time = tStartDate;
      tempData[i].due_time = tDueDate;
      tempData[i].due_time_has_time = hasTime;
      tempData[i].reminder = tReminder;
    });
    dispatch({ type: 'setTree', payload: tempData });
    dataServices.updateMultiLayers({
      data: {
        start_time: tStartDate,
        due_time: tDueDate,
        due_time_has_time: hasTime,
        reminder: tReminder,
      },
      pks: treeMultiSelectList,
    });
    setTreeMultiSelect(false);
    openMenu(-1);
  };

  const multiSelectStatus = (item) => {
    changeHandler('status', item.value);
  };
  const delChild = () => {
    const tempProject = JSON.parse(JSON.stringify(projectArr));
    treeMultiSelectList.forEach((i) => {
      const nodeParent = treeData[i]?.parent;
      if (tempProject[i]) delete tempProject[i];
      treeData[nodeParent].order = treeData[nodeParent].order.filter(
        (o) => o !== i,
      );
    });
    updatePrjArr(tempProject);
    calculateProjectsWidth();
    addToQueue('multiDelete', {
      ids: treeMultiSelectList,
    });
    setTreeMultiSelect(false);
    setTreeMultiSelectByKey(false);
    openMenu(-1);
  };

  const limitForMultiSelect = (num) => {
    if (treeMultiSelectList.length > 10) {
      setToastInfo({
        isLoading: false,
        text: "You can't change more than 10 nodes at once",
        actionText: 'OK',
      });
      return;
    }
    openMenu(num);
  };

  const itemClick = (i) => {
    if (i.id !== 8 && openedMenu === i.id) {
      openMenu(-1);
      return;
    }
    switch (i.id) {
      case 1:
        limitForMultiSelect(1);
        break;
      case 2:
        limitForMultiSelect(2);
        break;
      case 3:
        limitForMultiSelect(3);
        break;
      case 4:
        limitForMultiSelect(4);
        break;
      case 5:
        limitForMultiSelect(5);
        break;
      case 6:
        delChild();
        break;
      default:
        openMenu(-1);
        break;
    }
  };

  return (
    <div
      className={`vertical-menu-container ${
        treeMultiSelectList.length > 0 ? 'slide-in' : 'slide-out'
      }`}
    >
      <div>
        <div className="box-shadow vertical-menu-wrapper">
          {verticalMenu.map((i) => (
            <button
              type="button"
              key={i.id}
              className="vertical-menu-popupMenuItem hover"
              onClick={() => itemClick(i)}
            >
              <div
                className="vertical-menu-child"
                style={{
                  backgroundColor:
                    i.id === 5 && stepExpand ? 'var(--gray_06)' : 'transparent',
                }}
              >
                {SvgSelector(i.label, 'vertical-menu-icon')}
              </div>
            </button>
          ))}
        </div>
        <div>
          <AnimationSlide
            show={openedMenu === 1}
            state="rightToLeft"
            openMenu={openMenu}
          >
            <div className="menu-item-container">
              <StatusMenu setState={multiSelectStatus} state={status} />
            </div>
          </AnimationSlide>
          <AnimationSlide
            show={openedMenu === 2}
            state="rightToLeft"
            openMenu={openMenu}
          >
            <div className="vertical-menu-date">
              <Calendar
                toggle={() => openMenu(-1)}
                node={treeMultiSelectList}
                directReq={false}
                type="multiSelect"
                callback={multiSelectDeadLine}
              />
            </div>
          </AnimationSlide>
          <AnimationSlide
            show={openedMenu === 3}
            state="rightToLeft"
            openMenu={openMenu}
          >
            <div className="verticalMenuColorPanel">
              <TagMenu
                task={treeMultiSelectList}
                directReq={false}
                onHandleVertical={changeHandler}
              />
            </div>
          </AnimationSlide>
          <AnimationSlide
            show={openedMenu === 4}
            state="rightToLeft"
            openMenu={openMenu}
          >
            <div className="vertical-menu-emoji">
              <EmojiPicker selectEmoji={changeHandler} argument="emoji" />
            </div>
          </AnimationSlide>
          <AnimationSlide
            show={openedMenu === 5}
            state="rightToLeft"
            openMenu={openMenu}
          >
            <div className="vertical-menu-assignment">
              <VerticalMenuAssignment layers={treeMultiSelectList} />
            </div>
          </AnimationSlide>
        </div>
      </div>
    </div>
  );
}
