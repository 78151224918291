import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/button.css';

function Button({
  label,
  labelClass,
  color,
  textColor,
  image,
  onClick,
  width,
  style,
  buttonClass,
  height,
  isLoading,
}) {
  return (
    <div
      className={`btnWrapper btnHover ${buttonClass || ''}`}
      style={{
        background: `var(--${color})`,
        width,
        ...style,
        height,
      }}
      role="button"
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
    >
      <div
        style={{ color: `var(--${textColor})` }}
        className={`flex-row ${labelClass}`}
      >
        {label}
        {isLoading && (
          <div className="loading-spinner">
            {SvgSelector('spinner', 'btnLoadingSvg')}
          </div>
        )}
      </div>
      {image}
    </div>
  );
}

export default Button;
