import { useContext, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import { DataContext } from '../../context/DataContext';
import { SvgSelector } from '../../helper/svg-selector';
import { layerProps } from '../../constants/objects';

let timer = null;

export default function OutlineView({
  nodes,
  mainNode,
  inputFocused,
  setInputFocus,
  showNotify,
}) {
  return (
    <div className="outLine-container">
      <h1 className="outline-header">Outline View</h1>
      <OutlineViewNodes
        nodes={nodes}
        mainNode={mainNode}
        inputFocused={inputFocused}
        setInputFocus={setInputFocus}
        showNotify={showNotify}
      />
    </div>
  );
}
function OutlineViewNodes({
  nodes,
  mainNode,
  inputFocused,
  setInputFocus,
  showNotify,
}) {
  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {nodes?.map((node) => {
          return (
            <Child
              key={node}
              node={node}
              mainNode={mainNode}
              inputFocused={inputFocused}
              setInputFocus={setInputFocus}
              showNotify={showNotify}
            />
          );
        })}
      </div>
    </DndProvider>
  );
}

function Child({ node, mainNode, inputFocused, setInputFocus, showNotify }) {
  const { treeData, dispatch, setRefresh, addToQueue, userAccess } =
    useContext(DataContext);
  const [shownChildren, showChildren] = useState(true);

  const reOrder = (src, des) => {
    let nodeParentId = treeData[treeData[des].parent].id;
    while (nodeParentId !== mainNode) {
      if (nodeParentId === src) {
        showNotify({
          isLoading: false,
          text: 'This operation is not allowed!',
          actionText: 'Okay',
          action: null,
        });
        return;
      }
      nodeParentId = treeData[treeData[nodeParentId].parent].id;
    }
    const tempData = treeData;
    const srcParent = tempData[tempData[src].parent];
    const desParent = tempData[tempData[des].parent];

    const idx = desParent.order.indexOf(node);
    srcParent.order = srcParent?.order?.filter((i) => i !== src) || [];
    desParent.order = [
      ...desParent.order.slice(0, idx + 1),
      src,
      ...desParent.order.slice(idx + 1),
    ];
    addToQueue('update', {
      id: desParent.id,
      order: desParent.order,
    });
    tempData[src].parent = desParent.id;
    dispatch({ type: 'setTree', payload: tempData });
    setRefresh((old) => old + 1);
  };

  const dragRef = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop(droppedItem, monitor) {
      if (!dragRef.current) {
        return;
      }
      if (droppedItem.node === node) return;
      reOrder(droppedItem.node, node);
      monitor.isOver({ shallow: true });
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isDragging: !!monitor.isDragging,
    }),
  });
  const [, preview, drag] = useDrag({
    item: { node },
    type: 'card',
    canDrag: userAccess === 'WRITE',
  });
  drag(drop(dragRef));
  if (!treeData[node]) return null;
  return (
    <div
      className={`${
        mainNode !== treeData[node].parent ? 'outline-view-node-style' : ''
      }`}
    >
      <div className="outline-view-node-container" ref={dragRef}>
        <div ref={preview} className="outline-view-node-handle">
          {SvgSelector('gridHandle', 'outline-view-node-handle-svg')}
        </div>
        <Node
          node={node}
          isOver={isOver}
          mainNode={mainNode}
          inputFocused={inputFocused}
          setInputFocus={setInputFocus}
          showChildren={showChildren}
          shownChildren={shownChildren}
        />
      </div>
      {shownChildren && treeData[node]?.order && (
        <OutlineViewNodes
          nodes={treeData[node]?.order}
          mainNode={mainNode}
          inputFocused={inputFocused}
          setInputFocus={setInputFocus}
          showNotify={showNotify}
        />
      )}
    </div>
  );
}

function Node({
  node,
  isOver,
  MainNode,
  shownChildren,
  showChildren,
  inputFocused,
  setInputFocus,
}) {
  const {
    treeData,
    userAccess,
    addToQueue,
    setCurrNode,
    dispatch,
    setRefresh,
    boardId,
  } = useContext(DataContext);
  const inputRef = useRef(null);
  const [name, setName] = useState(treeData[node].title[0].text);
  const [isHover, setHover] = useState(false);
  const [focusedItem, focusItem] = useState(inputFocused);

  useEffect(() => {
    if (focusedItem) if (inputFocused === node) inputRef?.current?.focus();
  }, [focusItem]);

  const addChild = (event, sibling = false) => {
    let nodeColor = 'BLUE';
    if (sibling && treeData[node]?.parent === MainNode) nodeColor = 'PINK';
    const tempData = treeData;
    const nodeParent = tempData[node].parent;
    const newChildID = uuidv4();
    const idx = tempData[nodeParent]?.order?.indexOf(node);
    const newChild = {
      ...layerProps,
      id: newChildID,
      title: [
        {
          text: '',
        },
      ],
      layer_type: 'TASK',
      parent: sibling ? nodeParent : node,
      color: nodeColor,
      expansion: tempData[node].expansion,
      idx: sibling ? idx + 1 : tempData[node].order.length,
      board_id: boardId,
    };
    const parentOrder = tempData[nodeParent].order;
    if (sibling) parentOrder.splice(idx + 1, 0, newChildID);

    tempData[sibling ? nodeParent : node].order = tempData[
      sibling ? nodeParent : node
    ].order
      ? sibling
        ? parentOrder
        : [...tempData[node].order, newChildID]
      : [newChildID];

    tempData[newChildID] = newChild;
    if (sibling) tempData[newChildID].index = idx + 1;
    addToQueue('create', tempData[newChildID]);
    setCurrNode(newChildID);
    dispatch({ type: 'setTree', payload: tempData });
    setInputFocus(newChildID);
    setRefresh((old) => old + 1);
  };

  const delChild = () => {
    const tempData = treeData;
    const idx = treeData[node]?.order?.indexOf(node);
    const deletedNode = { ...treeData[node], idx };
    const nodeParent = tempData[node]?.parent;
    tempData[nodeParent].order = tempData[nodeParent].order.filter(
      (i) => i !== node,
    );
    delete tempData[node];
    addToQueue('delete', deletedNode);
    dispatch({ type: 'setTree', payload: tempData });
    setInputFocus(-1);
    setRefresh((old) => old + 1);
  };
  const onKeyDown = (e) => {
    if (userAccess !== 'WRITE') return;
    if (e.key === 'Enter') {
      e.preventDefault();
      addChild(e, true);
    } else if (e.key === 'Tab') {
      e.preventDefault();
      addChild(e, false);
    }
  };
  const nameChangeHandler = (n = null) => {
    clearInterval(timer);
    if (name === n) return;
    setName(n);
    dispatch({ type: 'setTree', payload: treeData });

    timer = setTimeout(() => {
      addToQueue('update', { id: treeData[node].id, title: [{ text: n }] });
    }, 500);
    treeData[node].title = n;
  };
  return (
    <div
      className={`flex-row outline-view-node ${
        isOver ? 'outline-view-node-is-hovered' : ''
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className="outLine-show-child"
        onClick={(e) => {
          e.stopPropagation();
          showChildren(!shownChildren);
        }}
      >
        <div
          className={`'cursor-pointer' ${
            !treeData[node]?.order?.length > 0
              ? 'outline-view-node-dot'
              : shownChildren
                ? 'outline-view-node-arrow outline-view-node-arrow-expanded'
                : 'outline-view-node-arrow'
          }`}
        />
      </div>
      <input
        ref={inputRef}
        value={name}
        disabled={userAccess !== 'WRITE'}
        onChange={(e) => nameChangeHandler(e.target.value)}
        className="outline-view-node-input font-18-med"
        onKeyDown={onKeyDown}
      />
      {isHover && userAccess === 'WRITE' && (
        <div className="cursor-pointer" onClick={delChild}>
          {SvgSelector('delete')}
        </div>
      )}
    </div>
  );
}
