/* eslint-disable jsx-a11y/no-autofocus */
import { useContext, useEffect, useRef, useState } from 'react';
import { Emoji } from 'emoji-picker-react';
import { SvgSelector } from '../../../helper/svg-selector';
import AnimationSlide from '../../Elements/AnimationSlide';
import NodesMoreMenu from './NodesMoreMenu';
import MembersList from '../../Board/MembersList';
import Calendar from '../../Calendar';
import Avatar from '../../Elements/Avatar';
import { nodeColors } from '../../../constants/colors';
import { DataContext } from '../../../context/DataContext';
import StatusMenu from '../../StatusMenu';
import { floatMenuStatus, noStatus } from '../../../constants/arrays';
import { deadlineCal } from '../../../helper/functions';

let timer;
function NodesCard({ data, addNewNode, props, order, focusedNode }) {
  const inputRef = useRef();
  const nodeData = data;
  const { addToQueue, treeData, dispatch, setBlockParent, userAccess } =
    useContext(DataContext);
  const fullAccess = userAccess === 'WRITE';
  const [openMenu, setOpenMenu] = useState();
  const [nodeMoreItem, setNodeMoreItem] = useState('');
  const [value, setValue] = useState('');

  const [nodeColor, setNodeColor] = useState({ color: '', background: '' });

  const updateServer = (params) => {
    treeData[nodeData.id] = { ...nodeData, ...params };
    addToQueue('upsert', {
      id: nodeData.id,
      ...params,
    });
  };

  const nodeChangeColorHandler = ({ color, background }) => {
    setNodeColor({ color, background });
    updateServer({ color: background });
  };

  const debounceAction = (val) => {
    setValue(val);
    clearTimeout(timer);
    timer = setTimeout(
      () => updateServer({ title: [{ text: val, type: 'text', style: {} }] }),
      500,
    );
  };

  const nodeClickHandler = (e) => {
    e.stopPropagation();
    setBlockParent(nodeData.id);
  };

  const onSelectStatus = (a) => {
    let currentStatus = a;
    if (treeData[nodeData.id].status === a.value) currentStatus = noStatus;
    treeData[nodeData.id].status = currentStatus.value;
    dispatch({ type: 'setTree', payload: treeData });
    updateServer({ status: currentStatus.value });
  };

  const setDate = (startDate, dueDate, reminder) => {
    if (!dueDate) return;
    // setDeadline(dueDate);
    nodeData.due_time = dueDate;
    nodeData.start_time = startDate;
    nodeData.reminder = reminder;
  };

  const assignMember = () => {
    console.log('assignMember');
  };

  const addNodeHandler = (val, odr) => {
    updateServer({ title: [{ text: val, type: 'text', style: {} }] });
    addNewNode(odr);
  };

  useEffect(() => {
    if (focusedNode === nodeData.id) inputRef?.current.focus();
    setValue(nodeData?.title[0]?.text || '');
  }, [focusedNode, data, treeData]);

  if (!nodeData) return null;
  return (
    <div className="cursor-pointer">
      <div className="nodes-list-card-container cursor-pointer">
        <div
          className="nodes-list-card-status"
          style={{
            backgroundColor: `var(--${
              floatMenuStatus[nodeData?.status]?.color || 'gray_06'
            })`,
          }}
          disabled={!fullAccess}
          onClick={(e) => {
            e.stopPropagation();
            setOpenMenu('status');
          }}
        >
          {SvgSelector(
            floatMenuStatus[nodeData?.status]?.label || 'status',
            'nodes-list-status-icon',
          )}
        </div>
        <div
          className="nodes-list-card-feature"
          style={{ backgroundColor: nodeColors[nodeColor.background] }}
          onClick={(e) => {
            e.stopPropagation();
            nodeClickHandler(e);
          }}
        >
          <div
            className="nodes-list-task-name-container"
            disabled={!fullAccess}
          >
            <div>
              <Emoji unified="1f2764" size={20} />
            </div>
            <input
              ref={inputRef}
              type="text"
              value={value}
              autoFocus
              disabled={!fullAccess}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => debounceAction(e.target.value)}
              onKeyDown={(e) =>
                e.code === 'Enter'
                  ? addNodeHandler(e.target.value, order)
                  : null
              }
              className="font-18-med nodes-list-task-name"
              style={{
                backgroundColor: nodeColors[nodeColor.background],
                width: '100%',
              }}
            />
          </div>
          <div
            disabled={!fullAccess}
            className="nodes-list-date-container cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setOpenMenu('calendar1');
            }}
          >
            <span
              className="font-14-med nodes-list-date-text"
              style={{
                color:
                  deadlineCal(nodeData.due_time)[1] === 'alert'
                    ? 'var(--red)'
                    : 'var(--gray_01)',
              }}
            >
              {nodeData.due_time
                ? deadlineCal(nodeData.due_time)[0]
                : 'Deadline'}
            </span>
            {SvgSelector(
              'chevronDown',
              `${deadlineCal(nodeData.due_time)[1] === 'alert' ? 'nodes-list-date-icon-alert' : 'nodes-list-date-icon'}`,
            )}
            <AnimationSlide
              show={openMenu === 'calendar1'}
              state="topToDown"
              openMenu={setOpenMenu}
            >
              <div
                className="nodes-list-card-calendar"
                onClick={(e) => e.stopPropagation()}
              >
                <Calendar node={nodeData} callback={setDate} />
              </div>
            </AnimationSlide>
          </div>
          <div
            className="check-list-title-member-container cursor-pointer"
            disabled={!fullAccess}
          >
            {nodeData?.assignments?.slice(0, 3)?.map((user) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenMenu(user);
                }}
              >
                <Avatar size={23} name={user.email} />
              </div>
            ))}
            {nodeData?.assignments?.length > 3 && (
              <div className="check-list-title-member-more cursor-pointer font-12-med">
                +{nodeData.assignments.length - 3}
              </div>
            )}
          </div>
          {fullAccess && (
            <div
              className="nodes-list-task-feature"
              onClick={(e) => {
                e.stopPropagation();
                setOpenMenu('moreMenu');
              }}
            >
              <div className="nodes-list-feature-separator">
                <div className="cursor-pointer nodes-list-card-more">
                  {SvgSelector('more', 'nodes-list-card-more-icon')}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <AnimationSlide
        openMenu={setOpenMenu}
        show={openMenu === 'memberMenu'}
        state="topToDown"
      >
        <div className="nodes-list-card-member-menu">
          <MembersList
          // boardId={boardID} inviteMember={inviteMember}
          />
        </div>
      </AnimationSlide>

      <AnimationSlide
        openMenu={setOpenMenu}
        show={openMenu === 'moreMenu'}
        state="topToDown"
      >
        <div className="nodes-list-more-menu">
          <NodesMoreMenu
            nodeMoreItem={nodeMoreItem}
            setNodeMoreItem={setNodeMoreItem}
            onSelectStatus={onSelectStatus}
            setOpenMenu={setOpenMenu}
            nodeColor={nodeColor}
            setNodeColor={nodeChangeColorHandler}
            nodeData={nodeData}
            assignMember={assignMember}
            setDate={setDate}
            props={props}
          />
        </div>
      </AnimationSlide>
      <AnimationSlide
        show={openMenu === 'status'}
        state="topToDown"
        openMenu={setOpenMenu}
      >
        <div className="nodes-list-status">
          <StatusMenu setState={onSelectStatus} state={nodeData.status} />
        </div>
      </AnimationSlide>
    </div>
  );
}

export default NodesCard;
