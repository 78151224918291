import '../../styles/controlTabs.scss';

function ControlTabs({ listTab, changeTab, setChangeTab, length }) {
  return (
    <div className="control-tab-container" key="controlled-tabs-container">
      {listTab.map((i) => (
        <div
          key={`${i.key}-tab`}
          className={`control-tab-item font-14-med cursor-pointer ${
            changeTab === i.key ? 'control-tab-item-selected' : ''
          }`}
          onClick={() => setChangeTab(i.key)}
        >
          {i.name}
          {length > 0 && i.key === 'sharedWithMe' && (
            <div className="budget-shared font-x-small">
              {Math.min(99, length)}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ControlTabs;
