import { timeSince } from '../../helper/functions';

const convertDate = (date) => {
  return date > 30 ? `${Math.ceil(date / 30)}mo` : `${date}d`;
};
const dateStyle = (date) => {
  if (date < 4) return 'deadline-close';
  if (date < 31) return 'deadline-normal';
  return 'deadline-long';
};
export default function Deadline({ date, fontSchedule }) {
  const remainingDate = timeSince(date);
  if (!remainingDate) return null;
  return (
    <div className={`${fontSchedule} deadline ${dateStyle(remainingDate)}`}>
      {convertDate(remainingDate)}
    </div>
  );
}
