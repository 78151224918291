import { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import '../../styles/App.css';
import Main from './Main';
import Loading from '../Elements/Loading';
import useSocket from '../../hooks/useSocket';
import dataServices from '../../services/dataServices';

let mainBoard;
let reqIdSubscribe = null;

function Editor({
  boardId,
  loading,
  setLoading,
  RefreshLayer,
  layerInfo,
  descendantsInfo,
}) {
  const {
    setLayers,
    setUserAccess,
    refreshBoard,
    setRefreshBoard,
    setLoadingBoard,
    togoMode,
    isLoading: busyQueue,
  } = useContext(DataContext);
  const { send } = useSocket();
  const [isReady, setReady] = useState(false);
  const initializeTree = (obj) => {
    let dataArray = { [mainBoard.id]: mainBoard };
    Object.values(obj).map((v) => {
      dataArray = { ...dataArray, [v.id]: v };
      return dataArray;
    });
    return dataArray;
  };
  const findProjectOfLayer = (tree, mainNode, layer) => {
    let l = layer;
    while (l.parent !== mainNode.id) {
      l = tree[l.parent];
    }
    return tree[l.id].id;
  };
  const createToDoTree = (treeObj, obj) => {
    let todoTree = { [mainBoard.id]: mainBoard };
    const prjs = mainBoard.order;
    prjs.forEach((prj) => {
      todoTree = {
        ...todoTree,
        [treeObj[prj].id]: { ...treeObj[prj], order: [] },
      };
    });
    const todoLayers = obj.filter(
      (o) => o.status === 'TOGO' && o.parent !== mainBoard.id,
    );
    todoLayers.forEach((layer) => {
      const p = findProjectOfLayer(treeObj, mainBoard, layer);
      todoTree = {
        ...todoTree,
        [layer.id]: { ...layer, parent: p, order: [] },
      };
      todoTree[p].order.push(layer.id);
    });
    prjs.forEach((prj) => {
      if (!todoTree[prj].order?.length > 0 && todoTree[prj].status !== 'TOGO')
        delete todoTree[prj];
    });
    return todoTree;
  };
  const getData = () => {
    dataServices
      .getLayerDescendants(boardId, { layer_type__in: 'TASK' })
      .then((res) => {
        if (!mainBoard) return;
        setLayers(initializeTree(res.data));
        setRefreshBoard(false);
        setLoadingBoard(false);
        setReady(true);
      });
  };
  const getLayer = () => {
    dataServices.getLayerById(boardId).then((res) => {
      mainBoard = res.data;
      getData();
    });
  };
  const subscribeToBoard = () => {
    send(
      JSON.stringify({
        action: 'subscribe_to_board',
        request_id: reqIdSubscribe,
        pk: boardId,
      }),
    );
  };
  const unSubscribeToBoard = (lastBoardId) => {
    send(
      JSON.stringify({
        action: 'unsubscribe_to_board',
        request_id: reqIdSubscribe,
        pk: lastBoardId,
      }),
    );
    reqIdSubscribe = null;
  };

  useEffect(() => {
    if (layerInfo.id === boardId && !layerInfo.template)
      if (!loading && !reqIdSubscribe) {
        reqIdSubscribe = new Date().getTime();
        subscribeToBoard();
      }
  }, [loading, boardId, layerInfo]);

  useEffect(() => {
    return () => {
      if (reqIdSubscribe && !layerInfo.template)
        unSubscribeToBoard(layerInfo.id);
    };
  }, [layerInfo]);

  useEffect(() => {
    if (refreshBoard) getLayer();
  }, [refreshBoard]);

  useEffect(() => {
    setReady(false);
    if (!layerInfo || !descendantsInfo) return;
    setUserAccess(layerInfo.self_privilege);
    mainBoard = layerInfo;
    setLayers(initializeTree(descendantsInfo));
    setLayers(
      togoMode
        ? createToDoTree(initializeTree(descendantsInfo), descendantsInfo)
        : initializeTree(descendantsInfo),
    );
    setRefreshBoard(false);
    setLoadingBoard(false);
    setReady(true);
  }, [layerInfo, descendantsInfo]);

  if (isReady || busyQueue) {
    return (
      <Main
        boardID={boardId}
        loading={loading}
        setLoading={setLoading}
        layerType={layerInfo?.layer_type}
        RefreshLayer={RefreshLayer}
      />
    );
  }
  return <Loading />;
}

export default Editor;
