import { useEffect, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import Avatar from './Avatar';
import '../../styles/menuMember.css';

function MenuMembersMore({ openModal, deleteMember, info }) {
  const [searchContent, setSearchContent] = useState('');
  const [items, setItems] = useState(info.members);

  useEffect(() => {
    if (!searchContent || searchContent.length === 0) {
      setItems(info.members);
      return;
    }
    let tempData = [...info.members];
    tempData = tempData?.filter(
      (item) =>
        item.email?.toLowerCase().indexOf(searchContent.toLowerCase()) !== -1 ||
        item.first_name?.toLowerCase().indexOf(searchContent.toLowerCase()) !==
          -1 ||
        item.last_name?.toLowerCase().indexOf(searchContent.toLowerCase()) !==
          -1,
    );
    setItems(tempData);
  }, [searchContent, info.members]);
  return (
    <div className="relocate-card-menu">
      <div className="modal-move-in-search-wrapper">
        {SvgSelector('search', 'svgColor')}
        <input
          className="font-16-reg menu-member-search"
          value={searchContent}
          onChange={(e) => setSearchContent(e.target.value)}
          placeholder="Search"
        />
      </div>
      <div className="modal-menu-member-more-items-wrapper">
        {items?.map((i) => {
          if (i.privilege === 0) return null;
          return (
            <div
              key={i.id}
              className="modal-member-in-items flex-row menu-member-items"
            >
              <div className="flex-row menu-member-avatar">
                <Avatar size={24} name={i.email} />
                <div className="modal-move-in-item-text font-14-reg">
                  {!i.first_name && !i.last_name
                    ? i.email
                    : `${i.first_name}\u00A0${i.last_name}`}
                </div>
              </div>
              <div
                className="menu-member-accounts"
                onClick={() => {
                  deleteMember(i);
                  openModal(false);
                }}
              >
                {SvgSelector('delete')}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default MenuMembersMore;
