import { SvgSelector } from '../../../helper/svg-selector';

export default function TodoIcon({
  todoNodesCounts,
  togoMode,
  setTogoMode,
  setReady,
}) {
  const bgColor = () => {
    if (todoNodesCounts < 1) return 'var(--white)';
    if (todoNodesCounts < 6) return 'var(--blue_light)';
    if (todoNodesCounts < 11) return 'var(--yellow_light)';
    return 'var(--red_light)';
  };
  const togoModeHandler = () => {
    setReady(false);
    setTogoMode((old) => !old);
  };
  if (togoMode)
    return (
      <div
        className="cursor-pointer hover board-main-menu-buttons main-menu-features-select"
        onClick={() => setTogoMode((old) => !old)}
      >
        {SvgSelector('close')}
      </div>
    );

  return (
    <div
      className="cursor-pointer hover board-main-menu-buttons main-menu-features-todo tooltip"
      style={{
        backgroundColor: bgColor(),
      }}
      onClick={() => (todoNodesCounts > 0 ? togoModeHandler() : null)}
    >
      {SvgSelector('scale2')}
      <span className="tooltiptext font-10-med main-menu-todo-tooltip">
        Todos
      </span>
      <div className="font-10-reg main-menu-todo-btn">
        {todoNodesCounts === 0 ? '' : todoNodesCounts}
      </div>
    </div>
  );
}
