import { avatarColors } from '../../constants/arrays';

function Avatar({
  size,
  font,
  name = 'NoName',
  onClick,
  style,
  isRounded = false,
}) {
  const colorCode = name.toLowerCase().charCodeAt(0) % 7 || 1;
  return (
    <div
      onClick={onClick}
      className={`avatar-pic ${font}`}
      style={{
        width: size,
        height: size,
        lineHeight: `${size}px`,
        borderRadius: isRounded ? size / 2 : size / 5,
        backgroundColor: avatarColors[colorCode],
        ...style,
      }}
    >
      {name.length === 0 ? 'N' : name[0].toUpperCase()}
    </div>
  );
}
export default Avatar;
