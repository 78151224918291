/* eslint-disable global-require */
export const Imgs = {
  'plus-btn': require('../assets/svg/button-plus.svg').default,
  delete: require('../assets/svg/delete.svg').default,
  attach: require('../assets/svg/attach.svg').default,
  bell: require('../assets/svg/bell.svg').default,
  calendar: require('../assets/svg/calendar.svg').default,
  copy: require('../assets/svg/copy.svg').default,
  eye: require('../assets/svg/eye.svg').default,
  hierarchy: require('../assets/svg/hierarchy.svg').default,
  spark: require('../assets/svg/spark.svg').default,
  timer: require('../assets/svg/timer.svg').default,
  upload: require('../assets/svg/upload.svg').default,
  status: require('../assets/svg/status.svg').default,
  menu: require('../assets/svg/menu.svg').default,
  layers: require('../assets/svg/layers.svg').default,
  settings: require('../assets/svg/settings.svg').default,
  alarm: require('../assets/svg/alarm.svg').default,
  chevronDown: require('../assets/svg/chevron_down.svg').default,
  plus: require('../assets/svg/plus.svg').default,
  pencil: require('../assets/svg/pencil.svg').default,
};
export const devImgs = {
  avatar: require('../assets/dev/avatar.png'),
};
