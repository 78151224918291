import { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import '../../styles/taskBoard.scss';
import TaskBoardBody from './TaskBoardBody';
import TaskBoardHeader from './TaskBoardHeader';
import { layerProps } from '../../constants/objects';
import dataServices from '../../services/dataServices';
import Toast from '../Elements/Toast';
import { setTextLimit } from '../../helper/functions';
import { DataContext } from '../../context/DataContext';
import Loading from '../Elements/Loading';

function TaskBoard({ boardID, newTask, dataObject }) {
  const { tasks, setTasks, treeData, dispatch, setOriginData } =
    useContext(DataContext);
  const [isLoading, setLoading] = useState(true);
  const [toastInfo, setToastInfo] = useState({
    text: null,
    isLoading: false,
    actionText: 'Okay',
    action: null,
  });
  const [checkMembers, setCheckMembers] = useState(false);
  const [taskFilter, setTaskFilter] = useState({
    board_id: [],
    tag: [],
    status: [],
    assignments: {},
  });

  const user = JSON.parse(localStorage.getItem('user'));
  const rootId = user?.root?.id;

  const getData = () => {
    const taskOrders = [];
    dataServices.getTasks(taskFilter).then((res) => {
      res.data.map((task) => {
        treeData[task.id] = task;
        taskOrders.push(task.id);
        return treeData;
      });
      dispatch({ type: 'setTree', payload: treeData });
      setTasks(taskOrders);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, [taskFilter]);

  const updateTaskList = (taskId, params, action) => {
    treeData[taskId] = { ...treeData[taskId], ...params };
    if (action) {
      setToastInfo({
        isLoading: false,
        text: `${action} has been changed`,
        actionText: 'Ok',
      });
    }
  };

  const updateTask = (taskId, params) => {
    updateTaskList(taskId, params);
    dataServices.updateLayers(taskId, params).then(() => {
      setToastInfo({
        isLoading: false,
        text: 'The name has been changed',
        actionText: 'Ok',
      });
    });
  };

  const reLocateTask = (id, params) => {
    dataServices.relocate(id, params);
  };

  const deleteTask = (item) => {
    const deleteFromList = tasks.filter((i) => i !== item.id);
    dataServices.deleteLayer(item.id).then(() => {
      setToastInfo({
        isLoading: false,
        text: `${setTextLimit(item.title[0].text)} Deleted`,
        actionText: 'Delete',
        extra: { item: item.id },
      });
      setTasks(deleteFromList);
    });
  };

  const duplicateTask = (from, to, type, name) => {
    const newChildID = uuidv4();
    const layer = [
      {
        ...layerProps,
        id: newChildID,
        parent_id: boardID,
        title: [{ text: name }],
        color: 'PINK',
        layer_type: 'TASK',
        status: 'BACKLOG',
        board_id: boardID,
        previous_id: from,
        previous: from,
      },
    ];
    const parentID = type === 'newTask' ? boardID : to;
    const params = {
      layers: layer,
      parent: parentID,
      pk: newChildID,
    };
    dataServices.duplicateLayer(from, params).then(() => {
      // eslint-disable-next-line
      treeData[newChildID] = layer[0];
      setTasks((prevState) => [newChildID, ...prevState]);
    });
  };
  const changeStatus = (taskId, taskStatus) => {
    updateTaskList(taskId, { status: taskStatus }, 'Status');
    const statusParams = {
      id: taskId,
      status: taskStatus,
    };
    dataServices.updateLayers(taskId, statusParams);
  };

  const getBoards = () => {
    dataServices
      .getLayerDescendants(rootId, { layer_type__in: 'BOARD' })
      .then((res) => {
        setOriginData(res.data);
      });
  };

  useEffect(() => {
    getBoards();
  }, []);

  if (isLoading) return <Loading />;
  return (
    <div className="task-board-container">
      <TaskBoardHeader
        checkMembers={checkMembers}
        setTaskFilter={setTaskFilter}
        taskFilter={taskFilter}
      />
      {tasks && (
        <TaskBoardBody
          newTask={newTask}
          updateTaskList={updateTaskList}
          reLocateTask={reLocateTask}
          updateTask={updateTask}
          deleteTask={deleteTask}
          duplicateTask={duplicateTask}
          changeStatus={changeStatus}
          setCheckMembers={setCheckMembers}
          taskFilter={taskFilter}
          setTaskFilter={setTaskFilter}
          dataObject={dataObject}
        />
      )}
      {toastInfo.text && (
        <Toast toastInfo={toastInfo} setToastInfo={setToastInfo} />
      )}
    </div>
  );
}

export default TaskBoard;
