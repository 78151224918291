import '../../styles/recent.scss';
import { SvgSelector } from '../../helper/svg-selector';
import Button from '../Elements/Button';
import AnimationSlide from '../Elements/AnimationSlide';
import ModalImport from '../Elements/ModalImport';
import MultiSelection from './MultiSelection';

function SelectBoard({
  shownImportModal,
  showImportModal,
  clickHandlerProject,
  selectedTab,
  checkMultiSelect,
  showModalMoveIn,
  deleteMultiData,
  deleteForEverMultiData,
  setUploadingFile,
  navigateAddData,
  currentProject,
  allData,
  setAllData,
}) {
  return (
    <div>
      {checkMultiSelect ? (
        <MultiSelection
          showModalMoveIn={showModalMoveIn}
          selectedTab={selectedTab}
          deleteMultiData={deleteMultiData}
          hardDeleteForEverData={deleteForEverMultiData}
        />
      ) : (
        <div className="recent-select-board-container">
          <div className="font-18-bold">Create new board</div>
          <div className="recent-select-board">
            <div
              className="box-shadow cursor-pointer recent-create-board-button"
              onClick={() => clickHandlerProject('addBoard')}
            >
              {SvgSelector('addBoard')}
              <div className="recent-create-board-text">
                <span className="font-14-med recent-create-board-title">
                  Create board
                </span>
                {SvgSelector('plus', 'add-project-icon')}
              </div>
            </div>
            <div
              className="box-shadow cursor-pointer recent-create-project-button"
              onClick={() => clickHandlerProject('addProject')}
            >
              {SvgSelector('addProject')}
              <div className="recent-create-board-text">
                <span className="font-14-med recent-create-board-title">
                  Create project
                </span>
                {SvgSelector('plus', 'add-project-icon')}
              </div>
            </div>
            <div className="recent-select-board-wrapper">
              {SvgSelector('selectFile')}
              <div className="recent-select-board-button-container">
                <span className="font-16-bold">Upload or embed your file</span>
                <div className="recent-select-board-button cursor-pointer">
                  <Button
                    labelClass="font-14-bold"
                    buttonClass="recent-select-board-button"
                    label="Select file"
                    textColor="white"
                    color="blue_strong"
                    onClick={() => clickHandlerProject('importModalAi')}
                    width={117}
                    height={36}
                    image={SvgSelector(
                      'uploadBoard',
                      'recent-select-board-icon',
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <AnimationSlide
        show={shownImportModal}
        openMenu={showImportModal}
        state="topToDown"
      >
        <ModalImport
          openModal={showImportModal}
          setUploadingFile={setUploadingFile}
          navigateAddData={navigateAddData}
          currentProject={currentProject}
          allData={allData}
          setAllData={setAllData}
          shownImportModal={shownImportModal}
        />
      </AnimationSlide>
    </div>
  );
}

export default SelectBoard;
