import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';

const offset = 2;
function NodeConnectionLine({ itemEls, node }) {
  const { treeData, filterOptions, isDraggingNode } = useContext(DataContext);
  const parent = itemEls.current[node]?.parent;
  if (!itemEls.current[parent]?.element) return null;

  const calculateOpacity = () => {
    if (isDraggingNode) return '0.2';
    return filterNode(node) ? '1' : '0.1';
  };

  const filterNode = (item) => {
    if (
      filterOptions.statuses.length === 0 &&
      filterOptions.colors.length === 0
    )
      return true;
    if (
      filterOptions.statuses.includes(treeData[item]?.status) ||
      filterOptions.colors.includes(treeData[item]?.color)
    )
      return true;
    return false;
  };

  let top = 0;

  const onlyChild = treeData[parent]?.order?.length === 1 ? 2 : 0;
  const lastElement = treeData[parent]?.order?.slice(-1);
  const isLastElement = lastElement[0] === node;
  const firstElement = treeData[parent]?.order[0];
  const isFirstElement = firstElement === node;

  const x1 =
    (itemEls?.current[parent]?.element?.offsetLeft || 0) +
    (itemEls?.current[parent]?.element?.clientWidth || 0) -
    5;
  const x2 = itemEls.current[node]?.element?.offsetLeft;
  const parentCenter =
    (itemEls.current[parent]?.element?.offsetTop || 0) +
    (itemEls.current[parent]?.element?.clientHeight || 0) / 2;
  const nodeWidth = itemEls.current[node]?.element?.clientWidth;
  const nodeHeight = itemEls.current[node]?.element?.clientHeight;
  const nodeTop = itemEls.current[node]?.element?.offsetTop;
  const nodeCenter = (nodeTop || 0) + (nodeHeight || 0) / 2;

  if (Math.abs(nodeCenter - parentCenter) < 3) top = nodeHeight / offset - 5;
  else if (nodeCenter < parentCenter) top = nodeHeight / offset - 3;
  else top = parentCenter - nodeTop - 3;

  const X = (x2 - x1) / 2;
  const height = Math.max(Math.abs(nodeCenter - parentCenter), 10) + 5.5;
  return (
    <div
      style={{
        zIndex: -1,
        position: 'absolute',
        top,
        right: nodeWidth,
        height,
        width: x2 - x1,
      }}
    >
      <svg height="100%" width="100%">
        {itemEls.current[node]?.element && itemEls.current[parent]?.element ? (
          <>
            {isFirstElement && (
              <path
                className="connectors-levels"
                strokeOpacity={calculateOpacity()}
                strokeWidth="1.2"
                fill="transparent"
                d={`M ${0} ${parentCenter - nodeCenter + 3.5 + onlyChild} L ${
                  X + 0.1
                } ${parentCenter - nodeCenter + 3.5 + onlyChild}`}
              />
            )}
            <path
              className="connectors-levels"
              strokeOpacity={calculateOpacity()}
              strokeWidth="1.2"
              fill="transparent"
              d={
                Math.abs(nodeCenter - parentCenter) < 3
                  ? `M ${X} ${parentCenter - nodeCenter + 5.5} L ${x2 - x1} ${
                      parentCenter - nodeCenter + 5.5
                    }`
                  : parentCenter > nodeCenter
                    ? `
                      M${X},10 Q${X},${offset} ${
                        x2 - x1 - 15
                      },${offset} T ${x2},1
                       ${
                         isFirstElement
                           ? ` M ${X} ${10} L ${X} ${
                               parentCenter - nodeCenter + 3
                             }`
                           : ``
                       }
                       `
                    : `
                  M${X},${nodeCenter - parentCenter - 8} Q${X},${
                    nodeCenter - parentCenter
                  } ${x2 - x1 - 15},${nodeCenter - parentCenter} T ${x2},${
                    nodeCenter - parentCenter
                  }
                      ${
                        isLastElement
                          ? `M ${X} ${nodeCenter - parentCenter - 8} L ${X} ${3}
                            `
                          : ``
                      }`
              }
            />
          </>
        ) : (
          <path />
        )}
      </svg>
    </div>
  );
}
export default NodeConnectionLine;
