import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SvgSelector } from '../../../../helper/svg-selector';
import { checkListMenuItem } from '../../../../constants/arrays';
import AnimationSlide from '../../../Elements/AnimationSlide';
import Calendar from '../../../Calendar';
import { DataContext } from '../../../../context/DataContext';
import ModalAssign from '../../../Elements/ModalAssign';
import MenuSelectMember from '../../../Elements/MenuSelectMember';

function TodoMoreMenu({ block }) {
  const blockId = block.id;
  const { treeData, dispatch, addToQueue } = useContext(DataContext);
  const [openMenu, setOpenMenu] = useState('');
  const [assignModalShown, showAssignModal] = useState('');
  const BlockBoardParent = treeData[block.board_id];

  const deleteTodo = () => {
    const parentId = treeData[blockId].parent;
    const parentOrder = treeData[parentId].order;
    const idx = parentOrder.findIndex((i) => i === blockId);
    parentOrder.splice(idx, 1);
    treeData[parentId].order = parentOrder;
    delete treeData[blockId];
    addToQueue('multiDelete', {
      ids: [blockId],
    });
    dispatch({ type: 'setTree', payload: treeData });
  };
  const duplicate = () => {
    const newBlock = { ...treeData[blockId] };
    const newId = uuidv4();
    newBlock.id = newId;
    newBlock.previous = blockId;
    newBlock.assignments = [];
    treeData[newId] = newBlock;

    const parentId = treeData[blockId].parent;
    const parentOrder = treeData[parentId].order;
    const idx = parentOrder.findIndex((i) => i === blockId);
    parentOrder.splice(idx + 1, 0, newId);
    dispatch({ type: 'setTree', payload: treeData });
    addToQueue('upsert', newBlock);
  };

  const handleOpenMenu = (name) => {
    switch (name) {
      case 'calendarPlus':
        setOpenMenu('calendar');
        break;
      case 'userProfile':
        setOpenMenu('addMember');
        break;
      case 'delete':
        deleteTodo();
        break;
      case 'copy':
        duplicate();
        break;

      default:
        console.log('name', name);
        break;
    }
  };

  const onAssign = () => {};

  return (
    <div>
      <div className="check-list-menu-container box-shadow">
        {checkListMenuItem.map((i) =>
          i.name === 'br' ? (
            <span className="check-list-menu-separator" />
          ) : (
            <div
              className="check-list-menu-item-container cursor-pointer"
              key={i.id}
              onClick={() => handleOpenMenu(i.icon)}
            >
              <div className="check-list-menu-name-container">
                {SvgSelector(i.icon, 'check-list-menu-name-icon')}
                <span className="font-14-reg">{i.name}</span>
              </div>
              <span className="check-list-menu-keyName font-12-reg">
                {i.id === 4 || i.id === 5
                  ? SvgSelector(i.keyName, 'check-list-menu-keyName-icon')
                  : i.keyName}
              </span>
            </div>
          ),
        )}
      </div>
      <AnimationSlide
        show={openMenu === 'calendar'}
        state="rightToLeft"
        openMenu={setOpenMenu}
      >
        <div className="check-list-more-menu-calendar">
          <Calendar node={blockId} />
        </div>
      </AnimationSlide>

      <AnimationSlide
        show={openMenu === 'addMember'}
        state="rightToLeft"
        openMenu={setOpenMenu}
      >
        <div className="check-list-more-menu-members">
          <MenuSelectMember
            data={BlockBoardParent?.members}
            setAssignMember={onAssign}
            taskData={block}
          />
        </div>
      </AnimationSlide>

      <AnimationSlide
        show={assignModalShown}
        openMenu={showAssignModal}
        state="rightToLeft"
      >
        <ModalAssign
          openModal={showAssignModal}
          onAssign={onAssign}
          blockId={blockId}
        />
      </AnimationSlide>
    </div>
  );
}

export default TodoMoreMenu;
