import { useEffect, useRef, useState } from 'react';
import { Emoji } from 'emoji-picker-react';
import moment from 'moment';
import { useDrag, useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';
import {
  dashboardDeletedMoreMenu,
  dashboardProjectMoreMenu,
} from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';
import EmojiPicker from '../Elements/EmojiPicker';
import useOutsideAlerter from '../Elements/OutsideAlert';
import MenuMoveIn from '../Elements/MenuMoveIn';
import Avatar from '../Elements/Avatar';
import MembersMenu from '../Elements/MenuMembers';
import MenuMembersMore from '../Elements/MenuMembersMore';
import AnimationSlide from '../Elements/AnimationSlide';
import CustomBoardEmoji from '../../assets/png/board-custom-icon.png';
import CustomProjectEmoji from '../../assets/png/project-custom-icon.png';
import './styles/dashboardStyle.css';
import dataServices from '../../services/dataServices';
import InviteModal from '../Elements/ModalInvite';

function CardBoard({
  type,
  cardItem,
  id,
  index,
  selectedItems,
  updateSelectedItems,
  isFocus,
  setFocus,
  deleteData,
  sharedScreen,
  limited,
  updateItem,
  viewType,
  updateData,
  deleteForEverData,
  deletedScreen,
  unDeleteData,
  openBoard,
  duplicateCard,
  reOrder,
  changeOrder,
  RefreshLayer,
  favHandler,
  isLoading,
  canDrag = true,
  layerToShare,
  setToastInfo,
  setLayerToShare,
  openProject,
  changedTab,
}) {
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);
  const item = cardItem;
  const unRead = item.visited_at === null && changedTab === 'sharedWithMe';
  const [shownCardMenu, showCardMenu] = useState(0);
  const [focusedItem, focusItem] = useState(isFocus);
  const clickOutSide = useOutsideAlerter(wrapperRef);
  const [name, setName] = useState(item.title[0].text);
  const [isFav, setFav] = useState(item.favorite);
  const [emoji, setEmoji] = useState(item.emoji);
  const [emojiMenu, showEmojiMenu] = useState(false);
  const [isOverItem, setOverItem] = useState(null);
  const [modalMoveInShown, showModalMoveIn] = useState(false);
  const [membersMenuShown, showMembersMenu] = useState(false);
  const [sharingMenu, showSharingMenu] = useState(false);
  // const [isShownCheckBox, showCheckBox] = useState(false);
  const [inviteModalShown, showInviteModal] = useState(false);
  const [members, setMembers] = useState(item.members);
  const user = JSON.parse(localStorage.getItem('user'));
  const boardCustomEmoji =
    (!emoji && type === 'BOARD') || emoji === 'layer-board';
  const projectCustomEmoji =
    (!emoji && type === 'PROJECT') || emoji === 'layer-project';

  const menu = deletedScreen
    ? dashboardDeletedMoreMenu
    : limited
      ? dashboardProjectMoreMenu.slice(0, 1)
      : dashboardProjectMoreMenu;

  let p1T;
  let p1x;
  let p2;
  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop(droppedItem, monitor) {
      if (!wrapperRef.current || !canDrag) {
        return;
      }

      const dragIndex = droppedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;
      monitor.isOver({ shallow: true });
      p1T = wrapperRef.current.getBoundingClientRect().y;
      p1x = wrapperRef.current.clientHeight / 3;
      p2 = monitor.getClientOffset();

      const center =
        type === 'PROJECT' && p1T + p1x < p2.y && p1T + 2 * p1x > p2.y;
      const bottom = p1T + 2 * p1x < p2.y;
      const top = p1T + p1x > p2.y;

      if (
        (viewType === 'lineageView' && center) ||
        (viewType !== 'lineageView' && (center || top || bottom))
      ) {
        changeOrder(id, droppedItem.id, 0);
      }
      if (top && viewType === 'lineageView') {
        if (dragIndex === hoverIndex - 1) {
          setToastInfo({
            isLoading: false,
            text: 'Oops! This action has no change',
            timer: 5000,
            actionText: 'Okay',
            persist: false,
            action: null,
          });
        } else if (dragIndex < hoverIndex) {
          reOrder(dragIndex, hoverIndex - 1, id);
        } else {
          reOrder(dragIndex, hoverIndex, id);
        }
      }
      if (bottom && viewType === 'lineageView') {
        if (dragIndex < hoverIndex) {
          reOrder(dragIndex, hoverIndex, id);
        } else if (dragIndex === hoverIndex + 1) {
          setToastInfo({
            isLoading: false,
            text: 'Oops! This action has no change',
            timer: 5000,
            actionText: 'Okay',
            persist: false,
            action: null,
          });
        } else {
          reOrder(dragIndex, hoverIndex + 1, id);
        }
      }
    },
    hover(hoveredItem, monitor) {
      const dragIndex = hoveredItem.index;
      const hoverIndex = index;
      monitor.isOver({ shallow: true });
      p1T = wrapperRef.current.getBoundingClientRect().y;
      p1x = wrapperRef.current.clientHeight / 3;
      p2 = monitor.getClientOffset();
      const center =
        // viewType === "lineageView"
        //   ?
        // cardItem.layer_type === 'PROJECT' &&
        type === 'PROJECT' && p1T + p1x < p2.y && p1T + 2 * p1x > p2.y;
      // :
      // p1T < p2.y && p1T + 6 * p1x > p2.y;
      const bottom = p1T + 2 * p1x < p2.y;
      const top = p1T + p1x > p2.y;

      setOverItem(
        viewType === 'lineageView'
          ? center && dragIndex !== hoverIndex
            ? 'drag-center'
            : top
              ? 'drag-top'
              : bottom
                ? 'drag-bottom'
                : null
          : (center || top || bottom) && dragIndex !== hoverIndex
            ? 'drag-center'
            : null,
      );
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isDragging: !!monitor.isDragging,
    }),
  });

  const [, drag] = useDrag({
    item: { id, index, item },
    type: 'card',
    canDrag,
  });

  drag(drop(wrapperRef));

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      focusItem(false);
      setFocus(null);
      updateData(item.id, { title: [{ text: name }] });
    }
  };

  const deleteMember = (info) => {
    showSharingMenu(false);
    const data = {
      email: info.email,
      privilege: 'NO_ACCESS',
    };
    setToastInfo({
      isLoading: true,
      text: `Deleting is in progress...`,
      timer: 10000,
      actionText: 'Okay',
      persist: true,
      action: null,
    });
    const tempMembers = [...members];
    const remainingMember = tempMembers.filter((i) => i.email !== info.email);
    dataServices
      .inviteMember(item.id, data)
      .then(() => {
        setMembers(remainingMember);
        setToastInfo({
          isLoading: false,
          text: `Member deleted`,
          actionText: 'Okay',
          persist: false,
          action: null,
        });
      })
      .catch((err) => {
        setToastInfo({
          isLoading: false,
          text: `Oops! ${err.response.data?.detail ?? err.message}`,
          timer: 5000,
          actionText: 'Okay',
          persist: false,
          action: null,
        });
      });
  };

  const favoriteHandler = () => {
    const newUuid = uuidv4();
    if (!isFav) item.favorite = newUuid;
    favHandler(item, !isFav, item.favorite, item.favorite);
    if (!isLoading) setFav((old) => !old);
  };

  const changeEmoji = (emjId, emj) => {
    updateItem(emjId, { emoji: emj });
    setEmoji(emj);
    showEmojiMenu(false);
  };
  const editCardHandler = (i) => {
    switch (i.id) {
      case 1:
        if (type === 'BOARD') openBoard(item);
        else openProject(item.id);
        break;
      case 2:
        focusItem(true);
        showCardMenu(0);
        break;
      case 3:
        focusItem(false);
        showCardMenu(0);
        duplicateCard(item, type === 'BOARD' ? 2 : 1);
        break;
      case 4:
        showMembersMenu(true);
        showCardMenu(0);
        break;
      case 5:
        showModalMoveIn(true);
        showCardMenu(0);
        break;
      case 7:
        deleteData(item);
        showCardMenu(0);
        break;
      case 8:
        deleteForEverData(item);
        showCardMenu(0);
        break;
      case 9:
        unDeleteData(item);
        showCardMenu(0);
        break;

      default:
        break;
    }
  };

  const moveToProject = (i) => {
    showModalMoveIn(false);
    changeOrder(i, item.id);
  };

  useEffect(() => {
    if (focusedItem) inputRef?.current.focus();
  }, [focusedItem]);

  useEffect(() => {
    if (isFocus) inputRef?.current.select();
  }, []);

  useEffect(() => {
    if (clickOutSide && focusedItem) {
      focusItem(false);
      setFocus(null);
      if (item.title !== name) updateData(item.id, { title: [{ text: name }] });
    }
  }, [clickOutSide]);

  const openInviteModal = (layer) => {
    setLayerToShare(layer);
    showInviteModal(true);
  };

  const shareBoard = (props) => {
    const { setLoading, data, info } = props;
    const existMember = members.filter((i) => i.email === info.email);
    if (user.email === info.email) {
      setLoading(false);
      showInviteModal(false);
      setToastInfo({
        isLoading: false,
        text: 'Can not shared with yourself',
        actionText: 'Okay',
        action: null,
      });
      return;
    }
    if (existMember.length > 0) {
      setLoading(false);
      showInviteModal(false);
      setToastInfo({
        isLoading: false,
        text: 'This member has already been assigned',
        actionText: 'Okay',
        action: null,
      });
      return;
    }
    dataServices.inviteMember(layerToShare, data).then(() => {
      setMembers((prev) => [...prev, info]);
      setLoading(false);
      showInviteModal(false);
      setToastInfo({
        isLoading: false,
        text: 'Invitation sent',
        actionText: 'Okay',
        action: null,
      });
    });
  };
  if (viewType === 'lineageView') {
    return (
      <div
        ref={wrapperRef}
        className={`project-items ${isOver ? isOverItem : ''}  ${
          focusedItem ? 'project-items-selected' : ''
        } ${unRead ? 'project-items-unread' : ''}`}
        style={{ cursor: isOver ? 'grabbing' : 'grab' }}
      >
        <div className="card-board-lineageView-container">
          {!sharedScreen && (
            <div onClick={() => updateSelectedItems(item.id)}>
              {SvgSelector(
                selectedItems.includes(item.id)
                  ? 'checkBoxFill'
                  : 'checkBoxHover',
                'project-items-checkbox cursor-pointer',
              )}
            </div>
          )}
          <div
            className="cursor-pointer card-board-lineageView-icon"
            onClick={(e) => {
              e.stopPropagation();
              if (!limited && type === 'BOARD') showEmojiMenu(item.id);
            }}
          >
            <Emoji
              emojiUrl={
                boardCustomEmoji
                  ? CustomBoardEmoji
                  : projectCustomEmoji
                    ? CustomProjectEmoji
                    : null
              }
              unified={boardCustomEmoji || projectCustomEmoji ? '1f4d8' : emoji}
              size="24"
            />
          </div>
          <div
            className="project-item-name"
            onClick={() =>
              deletedScreen
                ? null
                : type === 'BOARD'
                  ? openBoard(item)
                  : openProject(item.id)
            }
          >
            <input
              ref={inputRef}
              className="font-14-med card-board-lineageView-title"
              value={name}
              onChange={(e) => setName(e.target.value)}
              // disabled={!focusedItem}
              readOnly={!focusedItem}
              onKeyDown={onKeyDown}
            />
          </div>
          {!deletedScreen && (
            <div className="project-item-count font-14-reg">
              {type === 'PROJECT' ? item?.board_count : item?.block_count}{' '}
              {type === 'PROJECT'
                ? item?.board_count > 1
                  ? 'boards'
                  : 'board'
                : item?.block_count > 1
                  ? 'blocks'
                  : 'block'}
            </div>
          )}
          <div className="project-item-time font-14-reg">
            {`Edited ${moment(item.modified_at).fromNow()}`}
          </div>
          <div
            className="project-item-add-member font-12-reg"
            onClick={() => openInviteModal(id)}
          >
            {!limited && (
              <div className="cursor-pointer flex-row">
                <div>
                  {item.members?.filter((i) => i.privilege !== 0).length || 0}{' '}
                  member
                  <span>
                    {item.members?.filter((i) => i.privilege !== 0)?.length > 1
                      ? 's'
                      : ''}
                  </span>
                </div>
                {SvgSelector('plus', 'project-item-plus')}
              </div>
            )}
          </div>
          <div className="project-item-members">
            {members
              ?.filter((i) => i.privilege !== 0)
              .map((i) => (
                <div key={i.id}>
                  <div className="cursor-pointer" key={i.id}>
                    <Avatar
                      style={{ margin: 2 }}
                      size={24}
                      name={i.email}
                      onClick={() => showSharingMenu(i.id)}
                    />
                  </div>
                  <AnimationSlide
                    show={sharingMenu === i.id}
                    openMenu={showSharingMenu}
                    state="topToDown"
                  >
                    <MembersMenu
                      openModal={showSharingMenu}
                      moveTo={moveToProject}
                      info={i}
                      deleteMember={deleteMember}
                    />
                  </AnimationSlide>
                </div>
              ))}
          </div>
          <div className="project-item-fav">
            {!limited && (
              <button
                type="button"
                className="flex-row cursor-pointer"
                onClick={favoriteHandler}
              >
                {SvgSelector(isFav ? 'favFill' : 'fav')}
              </button>
            )}
          </div>
          <button
            type="button"
            className="project-item-more cursor-pointer"
            onClick={() => {
              updateSelectedItems(null);
              showCardMenu(item.id);
            }}
          >
            {SvgSelector('moreVertical')}
          </button>
          <AnimationSlide
            state="topToDown"
            show={shownCardMenu === item.id}
            openMenu={showCardMenu}
          >
            <div className="project-card-menu">
              {menu.map((i) =>
                i.name === 'br' ? (
                  <div
                    className="card-board-menu-separator-container"
                    key={i.id}
                  >
                    <div className="card-board-menu-separator" />
                  </div>
                ) : (
                  <div
                    key={i.id}
                    className="header-menu-profile-items font-14-reg"
                    onClick={() => editCardHandler(i)}
                  >
                    {SvgSelector(i.img)}
                    <div className="header-menu-profile-text">{i.name}</div>
                    {(i.id === 4 || i.id === 5) && (
                      <div className="card-board-menu-chevron">
                        {SvgSelector('chevronRight')}
                      </div>
                    )}
                  </div>
                ),
              )}
            </div>
          </AnimationSlide>
          <AnimationSlide
            show={membersMenuShown}
            openMenu={showMembersMenu}
            state="topToDown"
          >
            <MenuMembersMore
              openModal={showMembersMenu}
              deleteMember={deleteMember}
              info={item}
            />
          </AnimationSlide>
          <AnimationSlide
            openMenu={showModalMoveIn}
            show={modalMoveInShown}
            state="topToDown"
          >
            <MenuMoveIn
              openModal={showModalMoveIn}
              moveTo={moveToProject}
              selfItem={item}
            />
          </AnimationSlide>
          <AnimationSlide
            show={inviteModalShown}
            openMenu={showInviteModal}
            state="rightToLeft"
          >
            <div className="card-board-lineageView-invite">
              <InviteModal
                openModal={showInviteModal}
                notify={setToastInfo}
                layerToShare={layerToShare}
                RefreshLayer={RefreshLayer}
                shareBoard={shareBoard}
              />
            </div>
          </AnimationSlide>
          <AnimationSlide
            show={emojiMenu === item.id}
            openMenu={showEmojiMenu}
            state="topToDown"
            menuClassName="card-board-emoji-menu"
          >
            <EmojiPicker selectEmoji={changeEmoji} argument={item.id} />
          </AnimationSlide>
        </div>
      </div>
    );
  }
  return (
    <div
      ref={wrapperRef}
      className={`project-items-grid ${
        focusedItem ? 'project-items-selected' : ''
      } ${isOver ? isOverItem : ''} `}
      onClick={() =>
        deletedScreen
          ? null
          : type === 'Board'
            ? openBoard(item)
            : openProject(item.id)
      }
    >
      <div
        className="flex-row flex-jc-sb card-board-gridView-title-container"
        onClick={() =>
          deletedScreen
            ? null
            : type === 'BOARD'
              ? openBoard(item)
              : openProject(item.id)
        }
      >
        <div
          className="cursor-pointer card-board-gridView-icon"
          onClick={(e) => {
            e.stopPropagation();
            if (item.self_privilege === 2) showEmojiMenu(item.id);
          }}
        >
          <Emoji
            emojiUrl={
              boardCustomEmoji
                ? CustomBoardEmoji
                : projectCustomEmoji
                  ? CustomProjectEmoji
                  : null
            }
            unified={boardCustomEmoji || projectCustomEmoji ? '1f4d8' : emoji}
            size="40"
          />
        </div>
        <div className="flex-row">
          {!limited && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                favoriteHandler();
              }}
              className="card-board-gridView-favorite"
            >
              {SvgSelector(isFav ? 'favFill' : 'fav')}
            </div>
          )}
          <div
            className="project-item-more-grid cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              updateSelectedItems(null);
              showCardMenu(item.id);
            }}
          >
            {SvgSelector('moreVertical')}
          </div>
        </div>
      </div>

      <input
        onClick={() =>
          type === 'BOARD' ? openBoard(item) : openProject(item.id)
        }
        ref={inputRef}
        className="font-14-med cursor-pointer card-board-gridView-title"
        value={name}
        onChange={(e) => setName(e.target.value)}
        readOnly={!focusedItem}
        onKeyDown={onKeyDown}
      />
      <div className="flex-row">
        {!deletedScreen && (
          <div className="project-item-info-grid font-12-reg">
            {type === 'PROJECT' ? item?.board_count : item?.block_count}{' '}
            {type === 'PROJECT'
              ? item?.board_count > 1
                ? 'boards, '
                : 'board, '
              : item?.block_count > 1
                ? 'blocks, '
                : 'block, '}
          </div>
        )}
        <div className="project-item-info-grid font-12-reg">
          {`\u00A0Edited ${moment(item.modified_at).fromNow()}`}
        </div>
      </div>
      <div className="flex-row flex-jc-sb">
        <div className="project-item-members card-board-gridView-members">
          {item?.members?.slice(0, 5)?.map((i) => {
            if (i.privilege === 0) return null;
            return (
              <>
                <div className="cursor-pointer">
                  <Avatar
                    key={i.id}
                    style={{ margin: 2 }}
                    size={24}
                    name={i.email}
                    onClick={(e) => {
                      e.stopPropagation();
                      showSharingMenu(i.id);
                    }}
                  />
                </div>
                <AnimationSlide
                  show={sharingMenu === i.id}
                  openMenu={showSharingMenu}
                  state="topToDown"
                >
                  <div className="member-menu-grid-container">
                    <MembersMenu
                      openModal={showSharingMenu}
                      moveTo={moveToProject}
                      info={i}
                      deleteMember={deleteMember}
                    />
                  </div>
                </AnimationSlide>
              </>
            );
          })}
        </div>
        {!limited && (
          <div
            className="project-item-add-member-grid flex-row flex-jc-sb font-12-reg cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              openInviteModal(id);
            }}
          >
            <div className="cursor-pointer flex-row">
              <div>
                {item.members?.filter((i) => i.privilege !== 0).length || 0}{' '}
                member
                <span>
                  {item.members?.filter((i) => i.privilege !== 0)?.length > 1
                    ? 's'
                    : ''}
                </span>
              </div>
              {SvgSelector('plus', 'project-item-plus')}
            </div>
          </div>
        )}
      </div>
      <AnimationSlide
        show={shownCardMenu === item.id}
        openMenu={showCardMenu}
        state="topToDown"
      >
        <div className="project-card-menu-grid">
          {menu.map((i) =>
            i.name === 'br' ? (
              <div className="card-board-menu-separator-container" key={i.id}>
                <div className="card-board-menu-separator" />
              </div>
            ) : (
              <div
                key={i.id}
                className="header-menu-profile-items font-14-reg"
                onClick={(e) => {
                  e.stopPropagation();
                  editCardHandler(i);
                }}
              >
                {SvgSelector(i.img)}
                <div className="header-menu-profile-text">{i.name}</div>
                {(i.id === 4 || i.id === 5) && (
                  <div className="card-board-menu-chevron">
                    {SvgSelector('chevronRight')}
                  </div>
                )}
              </div>
            ),
          )}
        </div>
      </AnimationSlide>
      <AnimationSlide
        show={membersMenuShown}
        openMenu={showMembersMenu}
        state="topToDown"
      >
        <div className="relocate-card-menu-grid">
          <MenuMembersMore
            openModal={showMembersMenu}
            deleteMember={deleteMember}
            info={item}
          />
        </div>
      </AnimationSlide>
      {modalMoveInShown && (
        <MenuMoveIn
          openModal={showModalMoveIn}
          moveTo={moveToProject}
          selfItem={item}
        />
      )}
      <AnimationSlide
        openMenu={showEmojiMenu}
        state="topToDown"
        show={emojiMenu === item.id}
      >
        <div className="card-board-emoji-menu">
          <EmojiPicker selectEmoji={changeEmoji} argument={item.id} />
        </div>
      </AnimationSlide>
    </div>
  );
}
export default CardBoard;
