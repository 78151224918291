// import { useCallback } from 'react';

export const undoHandler = (
  undoQueue,
  redoQueue,
  addToQueue,
  delChild,
  addChild,
  boardId,
  updateTree,
  value,
  dispatch,
) => {
  if (undoQueue.length === 0) return;
  const ele = undoQueue.pop();
  const { type, obj } = ele;
  redoQueue.push({ type, obj });
  if (type === 'create') {
    addToQueue('delete', obj, true);
    delChild(boardId, obj.id);
  } else if (type === 'delete') {
    addToQueue('create', obj, true);
    addChild(obj.id, obj, boardId, true);
  } else if (type === 'update') {
    updateTree(obj.changeItem, obj.oldValue, null, true, obj.id);
  } else if (type === 'copy') {
    addToQueue('delete', obj.data.layers[0], true);
    delChild(boardId, obj.data.layers[0].id);
  } else if (type === 'move') {
    const tempData = value.treeData;
    tempData[obj.oldParent.id].order = obj.oldParent.oldOrder;
    tempData[obj.newParent.id].order = obj.newParent.oldOrder;
    tempData[obj.id].parent = obj.oldParent.id;
    const oldIndex = obj.oldParent.oldOrder.findIndex((i) => i === obj.id);
    const oldPrevious =
      oldIndex === 0 ? null : obj.oldParent.oldOrder[oldIndex - 1];
    dispatch({ type: 'setTree', payload: tempData });
    addToQueue(
      'move',
      {
        id: obj.id,
        parent: oldPrevious ? null : obj.oldParent.id,
        previous: oldPrevious,
        oldParent: {
          id: obj.newParent.id,
          oldOrder: obj.newParent.newOrder,
          newOrder: obj.newParent.oldOrder,
        },
        newParent: {
          id: obj.oldParent.id,
          oldOrder: obj.oldParent.newOrder,
          newOrder: obj.oldParent.oldOrder,
        },
      },
      true,
    );
  }
};

export const redoHandler = (
  undoQueue,
  redoQueue,
  addToQueue,
  delChild,
  addChild,
  boardId,
  updateTree,
  value,
  dispatch,
) => {
  if (redoQueue.length === 0) return;
  const ele = redoQueue.pop();
  const { type, obj } = ele;
  if (type === 'delete') {
    addToQueue('delete', obj, true);
    delChild(boardId, obj.id);
  } else if (type === 'create') {
    addToQueue('create', obj, true);
    addChild(obj.id, obj, boardId);
  } else if (type === 'update') {
    updateTree(obj.changeItem, obj.newValue, null, true, obj.id);
  } else if (type === 'copy') {
    addToQueue('create', obj.data.layers[0], true);
    addChild(
      obj.data.layers[0].id,
      { ...obj.data.layers[0], idx: obj.idx },
      boardId,
      true,
    );
  } else if (type === 'move') {
    const tempData = value.treeData;
    tempData[obj.oldParent.id].order = obj.oldParent.newOrder;
    tempData[obj.newParent.id].order = obj.newParent.newOrder;
    tempData[obj.id].parent = obj.newParent.id;
    const oldIndex = obj.newParent.newOrder.findIndex((i) => i === obj.id);
    const oldPrevious =
      oldIndex === 0 ? null : obj.newParent.newOrder[oldIndex - 1];

    dispatch({ type: 'setTree', payload: tempData });
    addToQueue(
      'move',
      {
        id: obj.id,
        parent: oldPrevious ? null : obj.newParent.id,
        previous: oldPrevious,
        oldParent: {
          id: obj.newParent.id,
          oldOrder: obj.oldParent.newOrder,
          newOrder: obj.oldParent.oldOrder,
        },
        newParent: {
          id: obj.oldParent.id,
          oldOrder: obj.newParent.newOrder,
          newOrder: obj.newParent.oldOrder,
        },
      },
      true,
    );
  }
  undoQueue.push({ type, obj });
};
