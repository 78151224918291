import { CSSTransition } from 'react-transition-group';
import '../../styles/animationSlide.css';
import Menu from './Menu';

function AnimationSlide({ children, show, openMenu, state, menuClassName }) {
  return (
    <CSSTransition
      in={show}
      timeout={100}
      classNames={state}
      unmountOnExit
      appear
    >
      <Menu openMenu={openMenu} menuClassName={menuClassName}>
        {children}
      </Menu>
    </CSSTransition>
  );
}

export default AnimationSlide;
