import { defaultProps } from '@blocknote/core';
import { createReactBlockSpec } from '@blocknote/react';
import CheckListContainer from './CheckListContainer';

const TaskNoteCheckList = createReactBlockSpec(
  {
    type: 'checkList',
    propSchema: {
      textAlignment: defaultProps.textAlignment,
      textColor: defaultProps.textColor,
      type: defaultProps,
    },
    content: 'none',
  },
  {
    render: (props) => {
      return <CheckListContainer props={props} />;
    },
  },
);
export default TaskNoteCheckList;
