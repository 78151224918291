/* eslint-disable jsx-a11y/no-autofocus */
import '../../../../styles/checkList.scss';
import { v4 as uuidv4 } from 'uuid';
import { useContext, useState } from 'react';
import TitleCardCheckList from './TitleCardCheckList';
import TodoCheckListItem from './TodoCheckListItem';
import { DataContext } from '../../../../context/DataContext';

let timer;
export default function CheckListContainer({ props }) {
  const { addToQueue, treeData, userAccess } = useContext(DataContext);
  const fullAccess = userAccess === 'WRITE';
  const checkListItem = treeData[props.block.id];
  const [value, setValue] = useState(checkListItem?.title[0]?.text || '');
  const [focusedNode, setFocusedNode] = useState();
  const nodeOrder = treeData[props.block.id]?.order;
  const onKeyDownHandler = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      const newUUID = uuidv4();
      const newData = {
        id: newUUID,
        assignments: [],
        layer_type: 'BLOCK',
        block_type: 'TO_DO',
        title: [],
        previous: null,
        parent: props.block.id,
        board_id: checkListItem.board_id,
      };
      setFocusedNode(newUUID);

      nodeOrder.splice(0, 0, newUUID);
      treeData[newUUID] = newData;
      treeData[props.block.id].order = nodeOrder;
      addToQueue('upsert', newData);
    }
    if (e.ctrlKey && e.code === 'KeyZ') e.preventDefault();
  };
  const addNewNode = (order) => {
    const newUUID = uuidv4();
    const newData = {
      id: newUUID,
      layer_type: 'BLOCK',
      block_type: 'TO_DO',
      title: [],
      previous: nodeOrder[order],
      parent: checkListItem.id,
      assignments: [],
      board_id: checkListItem.board_id,
    };
    setFocusedNode(newUUID);

    nodeOrder.splice(order + 1, 0, newUUID);
    treeData[newUUID] = newData;
    treeData[checkListItem.id].order = nodeOrder;
    addToQueue('upsert', newData);
  };
  const updateServer = (id, params) => {
    treeData[id] = { ...checkListItem, ...params };
    addToQueue('upsert', {
      id,
      ...params,
    });
  };

  const debounceAction = (val) => {
    setValue(val);
    clearTimeout(timer);
    timer = setTimeout(
      () =>
        updateServer(checkListItem.id, {
          title: [{ text: val, type: 'text', style: {} }],
        }),
      500,
    );
  };

  // useEffect(() => {
  //   props.editor.domElement.addEventListener(
  //     'keydown',
  //     (e) => onKeyDownHandler(e),
  //     true,
  //   );
  //   props?.editor?.domElement?.focus();
  // }, []);

  return (
    <div
      style={{ width: '100%', paddingBottom: 10, outline: 'none' }}
      disabled={!fullAccess}
    >
      <div
        style={{ width: '100%', paddingBottom: 10 }}
        data-alert-type={props.block.props.type}
      >
        <div className="check-list-container">
          <TitleCardCheckList
            setOpenMenu={false}
            openMenu={false}
            props={props}
          >
            <input
              autoFocus
              disabled={!fullAccess}
              type="text"
              value={value}
              onChange={(e) => debounceAction(e.target.value)}
              onKeyDown={(e) => onKeyDownHandler(e)}
              className="nodes-list-task-name inline-content font-18-bold check-list-title-name"
            />
          </TitleCardCheckList>
        </div>

        {nodeOrder?.map((todo, index) => (
          <TodoCheckListItem
            item={todo}
            order={index}
            focusedNode={focusedNode}
            addNewNode={addNewNode}
          />
        ))}
      </div>
    </div>
  );
}
