import { useContext } from 'react';
import { WebsocketContext } from '../context/WebSocketContext';

const useSocket = () => {
  const { socket, isReady, send, isError } = useContext(WebsocketContext);

  return {
    socket,
    isReady,
    send,
    isError,
  };
};

export default useSocket;
