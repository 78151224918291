import { useContext, useEffect, useState } from 'react';
import { Emoji } from 'emoji-picker-react';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/menuMember.css';
import CustomBoardEmoji from '../../assets/png/board-custom-icon.png';
import { DataContext } from '../../context/DataContext';

function MenuSelectBoard({ selectBoard, filterId }) {
  const { updateGroupBy, isGroupByBoards, originData } =
    useContext(DataContext);

  const [searchContent, setSearchContent] = useState('');
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!searchContent || searchContent.length === 0) {
      setItems(originData);
      return;
    }
    let tempData = [...originData];
    tempData = tempData?.filter(
      (item) =>
        item.title[0]?.text
          ?.toLowerCase()
          .indexOf(searchContent.toLowerCase()) !== -1,
    );
    setItems(tempData);
  }, [searchContent, originData]);

  return (
    <div className="task-select-board-menu">
      <div className="task-select-board-menu-header">
        <div className="font-14-med">Select board</div>
        <div className="font-14-med" style={{ color: 'var(--gray_01)' }}>
          Clear
        </div>
      </div>
      <div className="modal-move-in-search-wrapper">
        <div className="mt-2">{SvgSelector('search', 'svgColor')}</div>
        <input
          className="font-16-med menu-member-search"
          value={searchContent}
          onChange={(e) => setSearchContent(e.target.value)}
          placeholder="Search"
        />
      </div>
      <div className="modal-menu-member-more-items-wrapper">
        {filterId === 2 && (
          <div className="group-by-section">
            <label htmlFor="group-by-field">
              {!isGroupByBoards ? (
                <input
                  type="checkbox"
                  id="group-by-field"
                  onChange={() => updateGroupBy()}
                />
              ) : (
                isGroupByBoards && (
                  <input
                    type="checkbox"
                    id="group-by-field"
                    checked={isGroupByBoards}
                    onChange={() => updateGroupBy()}
                  />
                )
              )}
              Group-by boards
            </label>
          </div>
        )}
        {items?.map((i) => {
          if (i.privilege === 0) return null;
          return (
            <div
              key={i.id}
              className="modal-member-in-items flex-row menu-member-items hover"
              onClick={() => {
                selectBoard(i.id);
              }}
            >
              <div className="flex-row mx-10">
                <Emoji
                  emojiUrl={i.emoji ? null : CustomBoardEmoji}
                  unified={i.emoji || '1f4d8'}
                  size="24"
                />
                <div className="mx-10">{i.title[0].text}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default MenuSelectBoard;
