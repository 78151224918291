/* eslint-disable import/prefer-default-export */
export const objects = {
  initialTree: {
    key: '0',
    layer_type: 3,
    title: 'Documents',
    parent: null,
  },
};

export const layerProps = {
  order: [],
  note: null,
  has_note: false,
  emoji: null,
  color: null,
  status: 'NO_STATUS',
  schedule: null,
  deleted: null,
  comments_count: 0,
};

export const blockTypes = {
  TEXT: 'paragraph',
  HEADER_1: 'heading',
  HEADER_2: 'heading',
  HEADER_3: 'heading',
  BULLETED_LIST: 'bulletListItem',
  NUM_LIST: 'numberedListItem',
  TOGGLE: 'toggle',
  CALLOUT: 'callout',
  CHECK_LIST: 'checkList',
  TO_DO: 'todoList',
  NODE: 'node',
};

export const blockHeadingLevels = (type) => {
  const level = Number(type.split('_').pop());
  if (level) return level;
  return null;
};

export const blockTypesReversed = {
  paragraph: 'TEXT',
  heading1: 'HEADER_1',
  heading2: 'HEADER_2',
  heading3: 'HEADER_3',
  bulletListItem: 'BULLETED_LIST',
  numberedListItem: 'NUM_LIST',
  toggle: 'TOGGLE',
  callout: 'CALLOUT',
  checkList: 'CHECK_LIST',
  todoList: 'TO_DO',
  node: 'NODE',
};
