import { useState } from 'react';
import { SvgSelector } from '../../../helper/svg-selector';
import '../../../styles/customFormattingToolbar.scss';

function AddLink({ setUrl }) {
  const [urlValue, setUrlValue] = useState('');
  return (
    <div className="text-editor-link-container box-shadow">
      <div className="text-editor-link-url-container">
        <input
          type="text"
          className="text-editor-link-url font-14-reg"
          placeholder="Type or paste URL"
          // eslint-disable-next-line
          value={urlValue}
          onChange={(e) => setUrlValue(e.target.value)}
        />
      </div>
      <div
        className="text-editor-link-confirm cursor-pointer"
        onClick={() => setUrl(urlValue)}
      >
        {SvgSelector('checkMark', 'text-editor-confirm-icon')}
      </div>
    </div>
  );
}

export default AddLink;
