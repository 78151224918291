export const filterStatus = (taskFilter, treeData, node) => {
  if (taskFilter.status.length === 0) return false;
  const checkStatus = taskFilter.status.includes(treeData[node]?.status);
  return !checkStatus;
};

export const filterTag = (taskFilter, treeData, node) => {
  if (taskFilter.tag.length === 0) return false;
  const checkTag = taskFilter.tag.includes(treeData[node]?.color);
  return !checkTag;
};

export const filterAssignment = (taskFilter, treeData, node) => {
  if (Object.values(taskFilter.assignments).length === 0) return false;
  if (treeData[node].assignments.length === 0) return true;
  const checkAssignments = treeData[node].assignments.some((i) =>
    taskFilter.assignments.userNames?.includes(i.id),
  );
  return !checkAssignments;
};

export const filterDeadLine = (taskFilter, treeData, node) => {
  if (!taskFilter.due_date) return false;
  if (treeData[node].due_time === null) return true;
  const higherTime = taskFilter.due_date?.due_timeIt[0];
  const lowerTime = taskFilter.due_date?.due_timeGt[0];
  const nodeTime = treeData[node].due_time;
  if (lowerTime <= nodeTime && nodeTime <= higherTime) return false;
  return true;
};
