import '../../styles/comments.css';
import { useCallback, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from './Button';
import { SvgSelector } from '../../helper/svg-selector';
import { DataContext } from '../../context/DataContext';
import useSocket from '../../hooks/useSocket';
import CommentSection from './CommentSection';
import dataServices from '../../services/dataServices';

export default function Comments({ selectedNode }) {
  const { dispatch, treeData, setRefresh, userAccess, addToQueue } =
    useContext(DataContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const title = treeData[selectedNode]?.title[0].text || 'No name';

  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);
  const [allComments, setAllComments] = useState(false);
  const { socket } = useSocket();
  const closeHandler = () => {
    dispatch({ type: 'showComment', payload: false });
  };

  const likeComment = (id, like, parentId) => {
    let tempComments = parentId
      ? comments.filter((i) => i.id === parentId)[0].reply_series
      : comments;
    tempComments = tempComments.map((comment) =>
      comment.id === id
        ? {
            ...comment,
            liked_by_you: like,
            likes_count: like
              ? comment.likes_count + 1
              : comment.likes_count - 1,
          }
        : comment,
    );
    if (parentId) {
      tempComments = comments.map((i) =>
        i.id === parentId ? { ...i, reply_series: tempComments } : i,
      );
    }
    setComments(tempComments);
    addToQueue('likeComment', {
      layer: selectedNode,
      comment: id,
      data: { like },
    });
  };
  const editComment = (id, newText, parentId) => {
    let tempComments = parentId
      ? comments.filter((i) => i.id === parentId)[0].reply_series
      : comments;
    tempComments = tempComments.map((comment) =>
      comment.id === id
        ? {
            ...comment,
            text: newText,
            modified_at: new Date().getTime(),
          }
        : comment,
    );
    if (parentId) {
      tempComments = comments.map((i) =>
        i.id === parentId ? { ...i, reply_series: tempComments } : i,
      );
    }
    setComments(tempComments);
    addToQueue('editComment', {
      layer: selectedNode,
      comment: id,
      data: {
        text: newText,
      },
    });
  };
  const deleteComment = (id, parentId) => {
    let tempComments = parentId
      ? comments.filter((i) => i.id === parentId)[0].reply_series
      : comments;
    tempComments = tempComments.map((comment) =>
      comment.id === id
        ? {
            ...comment,
            text: 'deleted message',
            deleted: new Date().getTime(),
          }
        : comment,
    );
    if (parentId) {
      tempComments = comments.map((i) =>
        i.id === parentId ? { ...i, reply_series: tempComments } : i,
      );
    }
    setComments(tempComments);
    addToQueue('deleteComment', {
      layer: selectedNode,
      comment: id,
    });
  };

  const addComment = () => {
    if (commentText === '') return;
    const newID = uuidv4();
    const newComment = {
      created_at: new Date().getTime(),
      id: newID,
      likes_count: 0,
      modified_at: new Date().getTime(),
      owner: user.email,
      owner_id: user.id,
      replied_user: null,
      reply_series: [],
      text: commentText,
    };
    setComments((old) => [...old, newComment]);
    setCommentText('');
    const tempData = treeData;
    if (tempData[selectedNode].comments_count) {
      tempData[selectedNode].comments_count += 1;
    } else {
      tempData[selectedNode].comments_count = 1;
    }
    dispatch({ type: 'setTree', payload: tempData });
    setRefresh((old) => old + 1);
    addToQueue('addComment', {
      pk: selectedNode,
      data: { id: newID, text: commentText },
    });
  };
  const replyComment = (id, newText, parentId) => {
    const commentIdx = comments.findIndex((i) => i.id === (parentId || id));
    const newID = uuidv4();
    const tempComments = [...comments];
    const newComment = {
      created_at: new Date().getTime(),
      deleted: null,
      id: newID,
      liked_by_you: false,
      likes_count: 0,
      modified_at: new Date().getTime(),
      owner: user.email,
      owner_id: user.id,
      replied_user: null,
      text: newText,
    };
    tempComments[commentIdx].reply_series =
      tempComments[commentIdx].reply_series || [];
    tempComments[commentIdx].reply_series = [
      ...tempComments[commentIdx].reply_series,
      newComment,
    ];
    setComments(tempComments);
    addToQueue('addComment', {
      pk: selectedNode,
      data: {
        id: newID,
        reply_to: id,
        text: newText,
      },
    });
  };

  const getComments = () => {
    dataServices.getComment(selectedNode).then((res) => setComments(res.data));
  };

  useEffect(() => {
    setComments([]);
    if (selectedNode !== -1 && treeData[selectedNode]?.comments_count)
      getComments();
  }, [selectedNode]);

  useEffect(() => {
    if (comments?.length > 0) {
      if (allComments) setFilteredComments(comments);
      else setFilteredComments(comments.slice(-1));
    } else setFilteredComments([]);
  }, [allComments, comments]);

  // socket
  const onMessage = useCallback(
    (message) => {
      const res = JSON.parse(message?.data);
      if (res.errors.length > 0) return;
      if (res.action === 'subscribed' && user?.id !== res.data.task.user) {
        if (res.data.task.action === 'Add Comment') {
          const receivedComment = res.data.task.changes.comment;
          if (!receivedComment.parent_id)
            // add a comment
            setComments((old) => [...old, res.data.task.changes.comment]);
          else {
            // add a reply
            let newComments = [...comments];
            const newComment = newComments.filter(
              (i) => i.id === receivedComment.parent_id,
            )[0];
            newComment.reply_series = newComment.reply_series || [];
            newComment.reply_series = [
              ...newComment.reply_series,
              receivedComment,
            ];
            newComments = newComments.map((cmnt) =>
              cmnt.id === receivedComment.parent_id ? newComment : cmnt,
            );
            setComments(newComments);
          }
        } else if (
          res.data.task.action === 'Delete Comment' ||
          res.data.task.action === 'Update Comment'
        ) {
          const receivedComment = res.data.task.changes.comment;
          let newComments = [...comments];
          if (!receivedComment.parent_id) {
            // delete a comment
            if (res.data.task.action === 'Delete Comment') {
              newComments = newComments.map((i) =>
                i.id === receivedComment.id
                  ? {
                      ...i,
                      deleted: receivedComment.deleted,
                      text: receivedComment.text,
                    }
                  : i,
              );
            } else {
              newComments = newComments.map((i) =>
                i.id === receivedComment.id
                  ? { ...i, text: receivedComment.text }
                  : i,
              );
            }
            setComments(newComments);
          } else {
            // delete a reply
            const newComment = newComments.filter(
              (i) => i.id === receivedComment.parent_id,
            )[0];
            if (res.data.task.action === 'Delete Comment') {
              newComment.reply_series = newComment.reply_series.map((i) =>
                i.id === receivedComment.id
                  ? {
                      ...i,
                      deleted: receivedComment.deleted,
                      text: receivedComment.text,
                    }
                  : i,
              );
            } else {
              newComment.reply_series = newComment.reply_series.map((i) =>
                i.id === receivedComment.id
                  ? { ...i, text: receivedComment.text }
                  : i,
              );
            }
            newComments = newComments.map((cmnt) =>
              cmnt.id === receivedComment.parent_id ? newComment : cmnt,
            );
            setComments(newComments);
          }
        } else if (
          res.data.task.action === 'Like Comment' ||
          res.data.task.action === 'Unlike Comment'
        ) {
          const receivedComment = res.data.task.changes.comment;
          let newComments = [...comments];
          if (!receivedComment.parent_id) {
            // like a comment
            newComments = newComments.map((i) =>
              i.id === receivedComment.id
                ? { ...i, likes_count: receivedComment.likes_count }
                : i,
            );
            setComments(newComments);
          } else {
            // like a reply
            const newComment = newComments.filter(
              (i) => i.id === receivedComment.parent_id,
            )[0];
            newComment.reply_series = newComment.reply_series.map((i) =>
              i.id === receivedComment.id
                ? { ...i, likes_count: receivedComment.likes_count }
                : i,
            );
            newComments = newComments.map((cmnt) =>
              cmnt.id === receivedComment.parent_id ? newComment : cmnt,
            );
            setComments(newComments);
          }
        }
      }
    },
    [comments],
  );

  useEffect(() => {
    socket.addEventListener('message', onMessage);

    return () => {
      socket.removeEventListener('message', onMessage);
    };
  }, [socket, onMessage]);

  return (
    <div className="comments-container box-shadow ">
      <div className="comments-title-container">
        {allComments ? (
          <div className="comments-close" onClick={() => setAllComments(false)}>
            {SvgSelector('arrowLeft')}
          </div>
        ) : (
          <div />
        )}
        <div className="font-14-bold comments-title">
          {`Comments (${comments?.length})`}
        </div>
        <div className="comments-close" onClick={closeHandler}>
          {SvgSelector('close')}
        </div>
      </div>
      <div className="card-board-menu-separator" />
      {!allComments && userAccess === 'WRITE' && (
        <div className="comments-main-topic-container">
          <div className="font-14-reg comments-title">{title}</div>
          <textarea
            className="comments-main-topic-textarea font-14-reg"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
          <div className="comments-main-topic-buttons-wrapper">
            <Button
              labelClass="font-12-bold"
              label="Cancel"
              textColor="black"
              color="gray_05"
              onClick={() => setCommentText('')}
              width="47%"
              height={30}
            />
            <Button
              labelClass="font-12-bold"
              label="OK"
              textColor="white"
              color="blue_strong"
              onClick={addComment}
              width="47%"
              height={30}
            />
          </div>
        </div>
      )}

      <div className="comments-comment-container">
        <div className="font-14-reg comments-title">{title}</div>
        {filteredComments?.map((item, index) => (
          <div key={item.id}>
            <CommentSection
              item={item}
              setComments={setComments}
              deleteComment={deleteComment}
              likeComment={likeComment}
              editComment={editComment}
              replyComment={replyComment}
              userAccess={userAccess}
            />
            {item.reply_series?.map((i) => (
              <CommentSection
                key={i.id}
                parentId={item.id}
                item={i}
                setComments={setComments}
                deleteComment={deleteComment}
                likeComment={likeComment}
                editComment={editComment}
                replyComment={replyComment}
                userAccess={userAccess}
              />
            ))}
            {!allComments ||
              (comments.length && index !== comments.length - 1 && (
                <div className="card-board-menu-separator mv-5" />
              ))}
          </div>
        ))}
      </div>

      {!allComments && (
        <Button
          labelClass="font-12-bold"
          label="Show all comments"
          style={{ display: 'flex', flexShrink: 0 }}
          buttonClass="comments-button-show-all"
          textColor="gray_02"
          color="gray_06"
          onClick={() => setAllComments(true)}
          width="100%"
          height={35}
        />
      )}
    </div>
  );
}
