// import { useState } from 'react';
import { useContext, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/taskBoard.scss';
import Avatar from './Avatar';
import { DataContext } from '../../context/DataContext';
import dataServices from '../../services/dataServices';

function MenuMemberList({ taskId, setOpenMenu }) {
  const [hoverItem, setHoverItem] = useState(null);
  const { treeData, dispatch } = useContext(DataContext);
  const task = treeData[taskId];

  const unAssignMember = (user) => {
    const assignParams = {
      data: {
        assignment: false,
        member: user.id,
      },
      pks: [taskId],
    };
    let temp = [];
    temp = [...treeData[taskId].assignments];
    const deleteMember = temp.filter((i) => user !== i);
    treeData[taskId].assignments = deleteMember;
    dispatch({ type: 'setTree', payload: treeData });
    dataServices.assignMultiTasks(assignParams);
  };
  return (
    <div>
      <div className="menu-member-list-container box-shadow">
        <div className="menu-member-list-header">
          <div
            className="font-14-med menu-member-header-add cursor-pointer"
            onClick={() => setOpenMenu('newMembers')}
          >
            Add members
            {SvgSelector('plus', 'menu-member-header-add-icon')}
          </div>
          <div className="cursor-pointer font-14-med menu-member-header-delete">
            Delete all
          </div>
        </div>
        {task.assignments?.map((i) => (
          <div
            key={i.id}
            className="hover modal-member-list-invite-container cursor-pointer"
            onClick={() => {}}
            onMouseEnter={() => setHoverItem(i.id)}
          >
            <div className="flex-row flex-jc-sb modal-member-list-invite">
              <div className="flex-row">
                <Avatar size={24} name={i.email} />
                <div className="mx-5">
                  {i.first_name && i.last_name ? (
                    <div className="font-14-reg">{`${i.first_name} ${i.last_name}`}</div>
                  ) : (
                    <div className="font-14-reg">{i.email}</div>
                  )}
                </div>
              </div>
              {hoverItem === i.id && (
                <div
                  className="cursor-pointer"
                  onClick={() => unAssignMember(i)}
                >
                  {SvgSelector('delete')}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MenuMemberList;
