import { useEffect, useRef, useState } from 'react';
import '../../../styles/customFormattingToolbar.scss';
import { useBlockNoteEditor } from '@blocknote/react';
import { SvgSelector } from '../../../helper/svg-selector';
import {
  textEditorTabs,
  actionBlocks,
  baseBlocks,
} from '../../../constants/arrays';

function ToolbarEditor({ makeNode, suggestMenuClickHandler, ...props }) {
  const scrollRef = useRef();
  const [selectedTab, setSelectedTab] = useState('Base');
  const editor = useBlockNoteEditor();
  const { block } = editor.getTextCursorPosition();

  const customBlockHandler = (customBlock) => ({
    title: '',
    onItemClick: () => {
      // Block that the text cursor is currently in.
      const currentBlock = editor.getTextCursorPosition().block;

      // New block we want to insert.
      const newBlock = {
        type: customBlock,
        // content: [
        //   { type: 'text', text: '', styles: {} },
        // ],
      };

      // Inserting the new block after the current one.
      if (currentBlock.content?.length > 0)
        editor.insertBlocks([newBlock], currentBlock, 'after');
      else editor.replaceBlocks([currentBlock], [newBlock]);
    },
    aliases: [],
    group: 'Other',
    subtext: '',
  });

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop } = scrollRef.current;
        if (scrollTop < 99) {
          setSelectedTab('Base');
        } else if (scrollTop > 100 && scrollTop < 330) {
          setSelectedTab('Media');
        } else if (scrollTop > 330) {
          setSelectedTab('Action');
        }
      }
    };

    scrollRef?.current?.addEventListener('scroll', handleScroll);

    return () => {
      scrollRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const addBlock = (i) => {
    if (makeNode && i.blockType === 'node')
      editor.updateBlock(block, {
        type: i.blockType,
        props: {
          level: i.level,
          type: { title: block.content },
        },
      });
    else {
      editor.updateBlock(block, {
        type: i.blockType,
        props: {
          level: i.level,
        },
      });
    }
  };

  const baseBlockHandler = (item) => {
    if (makeNode) {
      addBlock(item);
      return;
    }
    if ([1, 2].includes(item.id)) props.onItemClick(props.items[6]);
    else if (item.id === 3) props.onItemClick(props.items[0]);
    else if (item.id === 4) props.onItemClick(props.items[1]);
    else if (item.id === 5) props.onItemClick(props.items[2]);
    else if (item.id === 6) props.onItemClick(props.items[4]);
    else if (item.id === 7) props.onItemClick(props.items[3]);
  };
  const actionBlockHandler = (item) => {
    if (makeNode) {
      addBlock(item);
      return;
    }
    if (item.id === 1) customBlockHandler('checkList').onItemClick();
    else if (item.id === 2) customBlockHandler('node').onItemClick();
  };
  return (
    <div className="text-menu-container box-shadow">
      <div className="text-menu-tabs-container">
        <div className="text-menu-tabs-items">
          <div className="text-menu-tabs-name-container">
            {Object.values(textEditorTabs).map((i) => (
              <div
                className={`font-14-med text-menu-tabs-name cursor-pointer ${
                  i === selectedTab
                    ? 'text-menu-tabs-name-activate font-14-med'
                    : ''
                }`}
              >
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                  }}
                >
                  {i}
                </button>
              </div>
            ))}
          </div>
          <div className="font-12-reg text-menu-tabs-key">
            Press &quot;/&quot;
          </div>
        </div>
        <span className="text-menu-tabs-separator" />
      </div>
      <div className="text-menu-base-container" ref={scrollRef}>
        <span className="font-14-reg text-menu-title" id="Base">
          Base blocks
        </span>
        {baseBlocks.map((i) => (
          <div
            className="text-menu-base-item-container cursor-pointer"
            onClick={
              // () => insertHelloWorldItem(editor).onItemClick()
              // () => (i.blockType ? addBlock(i) : null)
              () => baseBlockHandler(i)
              // i.blockType
              //   ? editor.updateBlock(block, {
              //       type: i.blockType,
              //       props: {
              //         level: i.level,
              //       },
              //     })
              //   : null
            }
          >
            <div className="font-14-reg text-menu-base-feature">
              {SvgSelector(i.icon, 'toolbar-editor-icon')}
              {i.name}
            </div>
            <div className="text-menu-base-key font-12-reg">{i.key}</div>
          </div>
        ))}
        {/* <span className="font-14-reg text-menu-title" id="Media">
          Media blocks
        </span> */}
        {/* {mediaBlocks.map((i) => (
          <div className="text-menu-base-item-container cursor-pointer">
            <div className="font-14-reg text-menu-base-feature">
              {SvgSelector(i.icon, 'toolbar-editor-icon')}
              {i.name}
            </div>
            <div className="text-menu-base-key font-12-reg">{i.key}</div>
          </div>
        ))} */}
        <span className="font-14-reg text-menu-title" id="Action">
          Action blocks
        </span>
        {actionBlocks.map((i) => (
          <div
            className="text-menu-base-item-container cursor-pointer"
            onClick={
              () => actionBlockHandler(i)
              // (i.blockType ? addBlock(i) : null)
            }
          >
            <div className="font-14-reg text-menu-base-feature">
              {SvgSelector(i.icon, 'toolbar-editor-icon')}
              {i.name}
            </div>
            <div className="text-menu-base-key font-12-reg">{i.key}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ToolbarEditor;
