import { useContext, useEffect, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import Avatar from './Avatar';
import { showUserName } from '../../helper/functions';
import { DataContext } from '../../context/DataContext';
import dataServices from '../../services/dataServices';

export default function VerticalMenuAssignment({ layers }) {
  const { assignUserToTask, setTreeMultiSelect, boardId } =
    useContext(DataContext);
  const [searchContent, setSearchContent] = useState('');
  const [boardUsers, setBoardUsers] = useState([]);
  const [users, setUsers] = useState([]);

  const getBoardMembers = () => {
    dataServices.getMemberships(boardId).then((res) => {
      setBoardUsers(res.data);
      setUsers(res.data);
    });
  };
  useEffect(() => {
    if (!searchContent?.length > 0) {
      return;
    }
    dataServices
      .getLayerUsers({
        email__icontains: searchContent?.toLowerCase(),
      })
      .then((res) => {
        if (searchContent.length > 0) {
          setUsers(res.data.slice(0, 2) || []);
        } else {
          setUsers(boardUsers);
        }
      });
  }, [searchContent]);

  const assignTask = (user) => {
    assignUserToTask(layers, user);
    setTreeMultiSelect(false);
  };

  useEffect(() => {
    getBoardMembers();
  }, []);

  return (
    <div className="popupMenuAssignment vertical-menu-assign">
      <div>
        <div className="popupMenuAssignmentSearchBox">
          {SvgSelector('search', 'popupMenuAssignmentSearchBoxIcon')}
          <input
            className="font-14-med multi-vertical-menu-search"
            value={searchContent}
            onChange={(e) => setSearchContent(e.target.value)}
            placeholder="Search names"
          />
        </div>
      </div>
      <div className="multi-vertical-menu-items">
        <div className="font-14-med multi-vertical-menu-recommend">
          {searchContent.length > 0 ? 'Search Result' : 'Recommended Users'}
        </div>
        {users?.map((user) => (
          <div
            key={user.id}
            className="popupMenuAssignmentMember flex-row cursor-pointer"
          >
            <Avatar
              size={22}
              name={user.email}
              font="font-12-med"
              style={{ marginRight: 5 }}
              isRounded
            />
            <div
              className="modal-move-in-item-text font-14-bold"
              onClick={() => assignTask(user)}
            >
              {showUserName(user)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
