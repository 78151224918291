/* eslint-disable no-restricted-syntax */
import {
  useBlockNoteEditor,
  useEditorChange,
  useEditorSelectionChange,
} from '@blocknote/react';
import { useState } from 'react';
import {
  noteStylesMenuItems,
  colorPanelTextEditor,
} from '../../../constants/arrays';
import { SvgSelector } from '../../../helper/svg-selector';
import '../../../styles/customFormattingToolbar.scss';
import ToolbarMoreMenu from './ToolbarMoreMenu';
import AnimationSlide from '../../Elements/AnimationSlide';
import ToolbarEditor from './ToolbarEditor';
import ToolbarAddLink from './ToolbarAddLink';
import { colors } from '../../../constants';

function Toolbar() {
  const [openMenu, setOpenMenu] = useState('');

  /** Toolbar configuration */

  const editor = useBlockNoteEditor();
  const { block } = editor.getTextCursorPosition();

  const multiSelect = editor.getSelection()?.blocks?.length > 1;
  // Function to get the state of toolbar buttons (active/inactive).
  const getState = () => {
    const activeStyles = editor.getActiveStyles();

    return {
      bold: activeStyles.bold || false,
      italic: activeStyles.italic || false,
      underline: activeStyles.underline || false,
      strike: activeStyles.strike || false,

      textAlignment: block.props.textAlignment,

      textColor: activeStyles.textColor || 'default',
      backgroundColor: activeStyles.backgroundColor || 'default',
    };
  };

  // Callback to set text alignment.
  const setTextAlignment = (textAlignment) => {
    const selection = editor.getSelection();

    if (selection) {
      for (const b of selection.blocks) {
        editor.updateBlock(b, {
          props: { textAlignment },
        });
      }
    } else {
      editor.updateBlock(block, {
        props: { textAlignment },
      });
    }
  };

  // Keeps track of the state of toolbar buttons.
  const [state, setState] = useState(getState());

  // Updates toolbar state when the editor content or selection changes.
  useEditorChange(() => setState(getState()), editor);
  useEditorSelectionChange(() => setState(getState()), editor);

  const clickHandler = (item) => {
    switch (item.id) {
      case 1:
        break;
      case 2:
        editor.toggleStyles({ bold: !state.bold });
        break;
      case 3:
        editor.toggleStyles({ italic: !state.italic });
        break;
      case 4:
        editor.toggleStyles({ underline: !state.underline });
        break;
      case 5:
        editor.toggleStyles({ strike: !state.strike });
        break;
      case 6:
        setTextAlignment('left');
        break;
      case 7:
        setTextAlignment('center');
        break;
      case 8:
        setTextAlignment('right');
        break;
      case 9:
        setOpenMenu('colorMenu');
        break;

      default:
        console.log('item', item.id);
        break;
    }
  };

  const setUrl = (url) => {
    editor.createLink(url, 'google');
  };
  return (
    <div>
      <div className="text-editor-container box-shadow">
        {!multiSelect &&
          block.type !== 'checkList' &&
          block.type !== 'todoList' && (
            <button
              type="button"
              className="text-editor-text-cont cursor-pointer font-14-med"
              onClick={() => setOpenMenu('editMenu')}
            >
              {SvgSelector('type')}
              Text
              {SvgSelector('chevronDown', 'text-editor-icon')}
            </button>
          )}
        <span className="text-editor-separator" />
        <button
          type="button"
          className="text-editor-link-cont cursor-pointer font-14-med"
          onClick={() => setOpenMenu('linkMenu')}
        >
          {SvgSelector('link')}
          Link
        </button>
        <span className="text-editor-separator" />
        <div className="text-editor-items-container">
          {noteStylesMenuItems.slice(1, 9).map((i) => (
            <button
              type="button"
              className={`text-editor-item cursor-pointer ${
                state[i.status] === i.active ? 'active' : ''
              }`}
              onClick={() => clickHandler(i)}
              style={{ width: i.id === 9 ? '52px' : '36px' }}
            >
              {i.id === 9 ? (
                <div className="text-editor-item-color">
                  {SvgSelector(i.img, 'text-editor-item-icon')}
                  {SvgSelector('chevronDown', 'text-editor-item-color-icon')}
                </div>
              ) : (
                SvgSelector(i.img, 'text-editor-item-icon')
              )}
            </button>
          ))}
        </div>
        <span className="text-editor-separator" />
        <button
          type="button"
          className="text-editor-more-icon-container cursor-pointer"
          onClick={() => setOpenMenu('moreMenu')}
        >
          {SvgSelector('more', 'text-editor-more-icon')}
        </button>
      </div>
      <AnimationSlide
        openMenu={setOpenMenu}
        show={openMenu === 'moreMenu'}
        state="topToDown"
      >
        <div className="text-editor-more-position">
          <ToolbarMoreMenu setOpenMenu={setOpenMenu} openMenu={openMenu} />
        </div>
      </AnimationSlide>
      <AnimationSlide
        openMenu={setOpenMenu}
        show={openMenu === 'editMenu'}
        state="topToDown"
      >
        <div className="text-Menu-editor-position">
          <ToolbarEditor
            setOpenMenu={setOpenMenu}
            openMenu={openMenu}
            makeNode
          />
        </div>
      </AnimationSlide>
      <AnimationSlide
        openMenu={setOpenMenu}
        show={openMenu === 'linkMenu'}
        state="topToDown"
      >
        <div className="add-link-position">
          <ToolbarAddLink setUrl={setUrl} />
        </div>
      </AnimationSlide>
      <AnimationSlide
        openMenu={setOpenMenu}
        show={openMenu === 'colorMenu'}
        state="topToDown"
      >
        <div className="color-panel-position">
          <div className="popupMenuColorPanelToolbar">
            {colorPanelTextEditor.map((i) => (
              <div key={i.id} className="float-menu-openMenu-color">
                <button
                  type="button"
                  className="cursor-pointer float-menu-choice-color"
                  style={{
                    backgroundColor: colors[i.color],
                  }}
                  onClick={() => editor.addStyles({ textColor: i.setColor })}
                >
                  {/* {color === i.setColor && (
                    <div
                      className="float-menu-circle"
                      style={{
                        backgroundColor: colors.white,
                      }}
                    />
                  )} */}
                </button>
              </div>
            ))}
          </div>
        </div>
      </AnimationSlide>
    </div>
  );
}

export default Toolbar;
