import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import NotFound from './pages/NotFound';
import Layer from './pages/Layer';
import { DataProvider } from './context/DataContext';
import { WebsocketProvider } from './context/WebSocketContext';

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <WebsocketProvider>
        <DataProvider>
          <BrowserRouter>
            <Routes>
              <Route exact path="/:id" element={<Layer />} />
              <Route element={<NotFound />} path="*" />
            </Routes>
          </BrowserRouter>
        </DataProvider>
      </WebsocketProvider>
    </DndProvider>
  );
}

export default App;
