import { SvgSelector } from '../../helper/svg-selector';
import Avatar from '../Elements/Avatar';
import Button from '../Elements/Button';

function TeamTab() {
  const fakeData = [
    {
      id: 0,
      name: 'Mihael Godisit',
      time: '12 hour ago',
      email: 'mihaelgood@gmail.com',
    },
    {
      id: 1,
      name: 'Robert Sivolsky',
      time: '6 min ago',
      email: 'robertsivolsky@gmail.com',
    },
  ];
  return (
    <div className="team-container">
      <div className="team-header">
        <div className="font-16-bold">{`${fakeData?.length} members`}</div>
        <Button
          labelClass="font-14-bold"
          label="Send invite"
          textColor="white"
          color="blue_strong"
          width={127}
          onClick={null}
          image={SvgSelector('plus', 'chevron')}
        />
      </div>
      <table className="team-table">
        <tbody>
          {fakeData.map((item) => (
            <tr key={item.id} className="team-items">
              <td className="team-item-name">
                <Avatar size={24} font="font-12-med" />
                <div className="team-item-name-text font-14-reg">
                  {item.name}
                </div>
              </td>
              <td className="team-item-time font-14-reg">{item.time}</td>
              <td className="team-item-email font-14-reg">{item.email}</td>
              <td className="team-item-more">{SvgSelector('moreVertical')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default TeamTab;
