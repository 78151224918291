import { useCallback, useEffect, useState } from 'react';
import Menu from './Menu';
import useSocket from '../../hooks/useSocket';

function Note({ showNote, id }) {
  const { socket, send, isReady } = useSocket();
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isReady) {
      send(
        JSON.stringify({
          action: 'get_note',
          request_id: new Date().getTime(),
          pk: id,
        }),
      );
    }
  }, [isReady]);

  const onMessage = useCallback(
    (message) => {
      const res = JSON.parse(message?.data);
      if (res.action === 'get_note' && res.data?.id === id) {
        setLoading(false);
        setData(res.data?.note);
      }
    },
    [id],
  );

  useEffect(() => {
    socket.addEventListener('message', onMessage);

    return () => {
      socket.removeEventListener('message', onMessage);
    };
  }, [socket, onMessage]);

  return (
    <div className="internal-note-card-menu">
      <div className="internal-note-card-menu-wrapper">
        <Menu openMenu={showNote} numeral>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            data?.map((item) => (
              <div key={item.id} className="flex-row internal-note-container">
                {item.type === 'font-num' && (
                  <div className="font-num internal-note-num-item">
                    {item?.num}.
                  </div>
                )}
                {item.type === 'font-point' && (
                  <div className="bullet internal-note-bullet-item" />
                )}
                <div
                  contentEditable="false"
                  className={`editable internal-note-text ${item?.type} ${item?.align}`}
                  suppressContentEditableWarning
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </div>
            ))
          )}
        </Menu>
      </div>
    </div>
  );
}
export default Note;
