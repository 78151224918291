/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from 'react';
import { Emoji } from 'emoji-picker-react';
import { v4 as uuidv4 } from 'uuid';
import { SvgSelector } from '../../helper/svg-selector';
import Menu from './Menu';
import Button from './Button';
import { setTextLimit } from '../../helper/functions';
import TextInput from './TextInput';
import '../../styles/menuMember.css';
import dataServices from '../../services/dataServices';

// let orgProject = [];
function Folders({ items, selectId, rootId, setNav }) {
  const clickHandler = (item) => {
    selectId(item.id);
    const a = item?.ancestors?.map((i) => i.title) || [];
    if (item.title[0].text !== 'Root') a.push(item.title[0].text);
    setNav(a);
  };
  const SubFolder = useCallback(
    ({ item }) => {
      const [isOpen, open] = useState(false);
      const openHandler = (e) => {
        e.stopPropagation();
        open((old) => !old);
        clickHandler(items.find((i) => i.id === item));
      };
      if (!items.find((i) => i.id === item)) {
        return null;
      }
      return (
        <div className="menu-move-container">
          <button
            type="button"
            className="modal-move-in-items"
            onClick={(e) => openHandler(e)}
          >
            {isOpen ? (
              <button type="button">
                {SvgSelector('folderOpen', 'modal-move-in-folder-icon')}
              </button>
            ) : (
              <button type="button">
                {SvgSelector('folder', 'modal-move-in-folder-icon')}
              </button>
            )}
            <div className="modal-move-in-item-text font-14-reg">
              {items.find((i) => i.id === item)?.title[0].text}
            </div>
          </button>
          {isOpen &&
            items
              .find((i) => i.id === item)
              ?.order?.map((it) => <SubFolder item={it} key={it} />)}
        </div>
      );
    },
    [items],
  );
  const Folder = useCallback(
    ({ i }) => {
      const [isOpen, open] = useState(false);
      const openHandler = (e) => {
        e.stopPropagation();
        open((old) => !old);
        clickHandler(i);
      };
      return (
        <div>
          <button
            type="button"
            className="modal-move-in-items"
            onClick={(e) => openHandler(e)}
          >
            {isOpen ? (
              <button type="button">
                {SvgSelector('folderOpen', 'modal-move-in-folder-icon')}
              </button>
            ) : (
              <button type="button">
                {SvgSelector('folder', 'modal-move-in-folder-icon')}
              </button>
            )}
            <div className="modal-move-in-item-text font-14-reg">
              {i.title[0].text?.slice(0, 10)}
            </div>
          </button>
          {isOpen &&
            i.order?.length > 0 &&
            i.order?.map((it) => <SubFolder item={it} key={it} />)}
        </div>
      );
    },
    [items],
  );

  return (
    <div className="modal-move-in-items-wrapper">
      {items
        ?.filter((i) => i.parent === rootId)
        .map((i) => (
          <Folder i={i} key={i.id} />
        ))}
    </div>
  );
}

function MenuMoveIn({ openModal, moveTo, selfItem }) {
  const user = JSON.parse(localStorage.getItem('user'));
  const rootId = user?.root?.id;
  const self = selfItem || user?.root;
  const [nav, setNav] = useState(['Root']);
  const [selectedId, selectId] = useState(self.parent);
  const [items, setItems] = useState([]);
  const [createFolderPopup, setCreateFolderPopup] = useState(false);
  const [folderName, setFolderName] = useState('');

  const createFolderHandler = () => {
    if (!folderName.length > 0) return;
    setFolderName('');
    const newID = uuidv4();
    const newObj = {
      id: newID,
      layer_type: 'PROJECT',
      title: [
        {
          text: folderName,
        },
      ],
      parent: selectedId,
      comments_count: 0,
      favorite: false,
      index: 0,
    };
    dataServices.addLayers(newObj).then((res) => {
      setItems([...items, res.data]);
    });
    setCreateFolderPopup(false);
  };

  const getProjects = () => {
    dataServices
      .getLayerDescendants(rootId, { layer_type__in: 'PROJECT' })
      .then((res) => {
        if (self.parent !== rootId) {
          const firstParent =
            self.layer_type === 'PROJECT' ? self.id : self.parent;
          const find = res.data.find((i) => i.id === firstParent);
          let titles = find?.ancestors?.map((i) => i.title[0].text) || [];
          if (self.layer_type !== 'PROJECT') titles.push(find.title[0].text);
          titles = titles?.length > 0 && titles[0] ? titles : ['Root'];
          setNav(titles);
        }
        setItems([
          {
            id: rootId,
            title: [{ text: 'Root' }],
            parent: rootId,
            ancestors: [{ id: 0, title: 'Root' }],
          },
          ...res.data,
        ]);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  // useEffect(() => {
  //   if (!orgProject?.length > 0) return;
  //   const newLayers = JSON.parse(JSON.stringify(orgProject));
  //   let tempData = [...newLayers];
  //   tempData = tempData?.filter(
  //     (item) =>
  //       item.title.toLowerCase().indexOf(searchContent.toLowerCase()) !== -1,
  //   );
  //   setItems(tempData);
  // }, [searchContent]);

  if (createFolderPopup)
    return (
      <div
        className="modal-container"
        aria-hidden="true"
        onClick={(event) => {
          event.preventDefault();
          if (event.target === event.currentTarget) {
            openModal(0);
          }
        }}
      >
        <div className="modal-wrapper menu-move-create">
          <div className="modal-move-grid-header font-16-bold">
            Create folder
          </div>
          <div className="menu-move-create-text">
            <TextInput
              width="90%"
              title="Name"
              value={folderName}
              placeholder="Folder name"
              handleChange={(value) => setFolderName(value)}
            />
          </div>
          <div className="menu-move-create-button">
            <Button
              labelClass="font-14-bold"
              label="Cancel"
              textColor="black"
              color="gray_03"
              width="47%"
              onClick={() => setCreateFolderPopup(false)}
              height={36}
            />
            <Button
              labelClass="font-14-bold"
              label="Create"
              textColor="white"
              color="blue_strong"
              width="47%"
              height={36}
              onClick={createFolderHandler}
            />
          </div>
        </div>
      </div>
    );
  return (
    <div
      className="modal-container"
      aria-hidden="true"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          openModal(0);
        }
      }}
    >
      <div className="modal-wrapper menu-move-wrapper">
        <Menu openMenu={openModal}>
          <div className="modal-move-grid-header font-16-bold">Move</div>
          <div className="modal-move-grid-container">
            <div className="font-14-bold">Selected items:</div>
            <div className="modal-move-address-field font-14-med">
              <Emoji
                unified={
                  self.emoji ||
                  (self.layer_type === 'PROJECT' ? '1f5c2-fe0f' : '1f4d8')
                }
                size="18"
              />

              <div className="menu-move-text-limit">
                {setTextLimit(self.title[0].text, 30)}
              </div>
            </div>
            <div className="font-14-bold">Target:</div>
            <div className="modal-move-address-field font-14-med">
              {nav.map((i, index) => (
                <div className="flex-row">
                  <div
                    className="menu-move-title"
                    style={{
                      marginLeft: index === 0 ? 0 : 8,
                    }}
                  >
                    {i === 'default' ? 'Root' : i}
                  </div>
                  {index < nav.length - 1 && SvgSelector('chevronRight')}
                </div>
              ))}
            </div>
          </div>
          <div className="modal-move-folders-container">
            <div className="menu-move-folder-icon">
              <div className="flex-row">
                {SvgSelector('files', 'modal-move-in-files-icon')}
                <div className="font-14-bold menu-move-folder-text">
                  My folders
                </div>
              </div>
              {/* <div className="modal-move-in-search-wrapper">
                {SvgSelector('search','svgColor')}
                <input
                  className="font-16-reg"
                  value={searchContent}
                  onChange={(e) => setSearchContent(e.target.value)}
                  placeholder="Search"
                  style={{ backgroundColor: 'transparent' }}
                />
              </div> */}
            </div>
            <div>
              {items && (
                <Folders
                  items={items}
                  moveTo={moveTo}
                  rootId={rootId}
                  setNav={setNav}
                  selectId={selectId}
                />
              )}
            </div>
          </div>
          <div className="flex-row menu-move-create-folder-container">
            <div
              className="font-14-med cursor-pointer menu-move-create-folder-text"
              onClick={() => setCreateFolderPopup(true)}
            >
              Create folder
            </div>
            <div className="menu-move-create-folder-button">
              <Button
                labelClass="font-14-bold"
                label="Cancel"
                textColor="black"
                color="gray_03"
                width="47%"
                onClick={() => openModal(0)}
                height={36}
              />
              <Button
                labelClass="font-14-bold"
                label="Move"
                textColor="white"
                color="blue_strong"
                width="47%"
                height={36}
                onClick={() => moveTo(selectedId)}
              />
            </div>
          </div>
        </Menu>
      </div>
    </div>
  );
}
export default MenuMoveIn;
