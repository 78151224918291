import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/smartFilter.scss';

function SmartFilter({ dataObject, setTaskFilter, taskFilter }) {
  const filterList = (filterName, filterItem) => {
    const boardName = [];
    switch (filterName) {
      case 'board_id': {
        const name = dataObject?.filter((it) => filterItem.includes(it.id));
        name.flatMap((i) => boardName.push({ name: i.title[0].text }));
        return boardName;
      }
      case 'status':
        return filterItem;
      case 'tag':
        return filterItem;
      case 'assignments': {
        if (filterItem.userEmail) {
          const name = filterItem.display_userNames?.flatMap(
            (i) => i.split('@')[0],
          );
          return name;
        }
        return null;
      }
      case 'due_date': {
        const withOutYears = filterItem.display_date.map((i) => {
          const parts = i.split(' ');
          if (
            parts[0] === 'Tomorrow' ||
            parts[0] === 'Today' ||
            parts[0] === 'Yesterday'
          )
            return i;
          const month = parts[1];
          const day = parts[2];
          // eslint-disable-next-line
          return day + ' ' + month;
        });
        if (withOutYears[0] === withOutYears[1]) return withOutYears.splice(1);
        return withOutYears;
      }
      default:
        return null;
    }
  };
  const onClear = (filterName) => {
    if (filterName === 'due_date') {
      let temp = [];
      temp = { ...taskFilter };
      delete temp.due_date;
      setTaskFilter(temp);
      return;
    }
    setTaskFilter((prev) => ({ ...prev, [filterName]: [] }));
  };
  return (
    <>
      {Object.entries(taskFilter).map(([k, v]) => {
        if (v.length !== 0 && Object.keys(v).length !== 0) {
          return (
            <div
              className="smart-filter-container cursor-pointer"
              onClick={() => onClear(k)}
            >
              <div className="font-14-med smart-filter-name">
                {filterList(k, v)?.map((i, index) => (
                  <div className="font-14-med smart-filter-name">
                    {i.name || i.toLowerCase() || i.email.split('@')[0]}
                    {index !== filterList(k, v).length - 1 && ','}
                  </div>
                ))}
              </div>
              <div className="smart-filter-clear">
                {SvgSelector('close', 'smart-filter-icon')}
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
}

export default SmartFilter;
