/* eslint-disable jsx-a11y/no-autofocus */
import { useEffect, useRef, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';

function TextInput(props) {
  const inputRefWrapper = useRef(null);
  const inputRef = useRef(null);
  const isPass = props?.title?.toLowerCase().includes('password');
  const [focused, focus] = useState(false);
  const [shownPass, showPass] = useState(false);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && ref.current.contains(event.target)) {
          focus(true);
        } else {
          focus(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(inputRefWrapper);

  // useEffect(() => {
  //   const input = document.querySelector('input');
  //   input.focus();
  // }, []);
  return (
    <div style={{ width: props?.width }}>
      <div
        className={`text-input-title font-small-reg ${
          props?.error ? 'text-error' : ''
        }`}
      >
        {props?.title}
      </div>
      <div
        className="text-input"
        ref={inputRefWrapper}
        style={{ height: props?.height }}
      >
        <input
          ref={inputRef}
          className={`font-14-reg ${props?.error ? 'text-error' : ''}`}
          value={props?.value}
          autoComplete="new-password"
          autoFocus
          spellCheck="off"
          onKeyDown={(k) =>
            props?.keyPressed ? props?.keyPressed(k.code) : null
          }
          readOnly={props?.readOnly}
          placeholder={props?.placeholder}
          type={isPass && !shownPass ? 'password' : 'text'}
          onChange={(e) => props?.handleChange(e.target.value, props?.input)}
        />
        {focused && isPass && (
          <div
            className="cursor-pointer"
            style={{ height: 15 }}
            onClick={() => showPass((old) => !old)}
          >
            {SvgSelector(shownPass ? 'eyeClose' : 'eye')}
          </div>
        )}
      </div>
      {props?.error && (
        <div
          className={`text-input-message font-small-reg ${
            props?.error ? 'text-error' : ''
          }`}
        >
          Invalid password
        </div>
      )}
    </div>
  );
}

export default TextInput;
