import { useContext } from 'react';
import '../../styles/taskBoard.scss';
import AddTask from './AddTask';
import TaskBoardCard from './TaskBoardCard';
import { DataContext } from '../../context/DataContext';
import SmartFilter from './SmartFilter';
import TaskSection from './TaskSection';

function TaskBoardBody({
  newTask,
  updateTaskList,
  updateTask,
  reLocateTask,
  deleteTask,
  duplicateTask,
  changeStatus,
  dataObject,
  setCheckMembers,
  taskFilter,
  setTaskFilter,
  setUserName,
  userName,
  setDate,
  date,
}) {
  const { tasks, treeData, isGroupByBoards, originData } =
    useContext(DataContext);

  const sectionList = originData.map((i) => ({
    title: i.title[0].text,
    id: i.id,
  }));
  sectionList.push({ title: 'Unassigned Tasks', id: 'unAssignTasks' });

  const sectionData = (id) => {
    if (id === 'unAssignTasks') {
      return tasks.filter(
        (i) =>
          treeData[i].board_id === null || treeData[i].board_id === undefined,
      );
    }
    return tasks.filter((i) => id === treeData[i].board_id);
  };
  return (
    <div className="task-board-body-container">
      <div className="task-board-title-space">
        <div className="font-18-bold task-board-title-number">{`${tasks.length} task${tasks.length === 0 ? '' : 's'}`}</div>
        <div className="task-board-title-filter">
          <SmartFilter
            setTaskFilter={setTaskFilter}
            dataObject={dataObject}
            userName={userName}
            setUserName={setUserName}
            date={date}
            setDate={setDate}
            taskFilter={taskFilter}
          />
        </div>
      </div>
      <div className="task-card-container">
        <AddTask newTask={newTask} />
        {!isGroupByBoards
          ? tasks.map((i) => (
              <TaskBoardCard
                key={i}
                type="newTask"
                updateTaskList={updateTaskList}
                taskId={i.id}
                reLocateTask={reLocateTask}
                updateTask={updateTask}
                data={i}
                deleteTask={deleteTask}
                duplicateTask={duplicateTask}
                changeStatus={changeStatus}
                dataObject={dataObject}
                treeData={treeData}
                setCheckMembers={setCheckMembers}
              />
            ))
          : sectionList.map((i) => {
              if (sectionData(i.id).length !== 0)
                return (
                  <TaskSection
                    list={sectionData(i.id)}
                    updateTaskList={updateTaskList}
                    reLocateTask={reLocateTask}
                    updateTask={updateTask}
                    deleteTask={deleteTask}
                    duplicateTask={duplicateTask}
                    changeStatus={changeStatus}
                    dataObject={dataObject}
                    treeData={treeData}
                    setCheckMembers={setCheckMembers}
                    title={i.title}
                  />
                );
              return null;
            })}
      </div>
    </div>
  );
}

export default TaskBoardBody;
